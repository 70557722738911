import "./FormSelect.scss";
import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import CreatableSelect from "react-select/creatable";
import { FormFeedback, Label } from "reactstrap";

export const FormCreatableSelect = ({
  label,
  name,
  value,
  options,
  setValue,
  error,
  disabled,
  ...rest
}) => {
  return (
    <div className="mb-3" data-testid={`creatable-select-${name}`}>
      {(label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )) ||
        (name && (
          <Label htmlFor={name} className="form-label">
            {label || <FormattedString id={name} />}
          </Label>
        ))}
      <CreatableSelect
        name={name}
        options={options}
        value={options.find((v) => v === value) || null}
        className={classNames([
          "form-select",
          error && "is-invalid",
          disabled && "is-disabled",
        ])}
        isMulti={false}
        classNamePrefix="form-select"
        isDisabled={disabled}
        {...rest}
        padding="none"
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
