// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile .card-title {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  align-items: center;
  display: flex;
}
.tile .card-body {
  display: flex;
  flex-direction: column;
}
.tile__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tile__row__left {
  display: flex;
  gap: 9px;
  align-items: center;
}
.tile__row__left i {
  font-size: 16px;
}
.tile .card-content:empty {
  display: none;
}
.tile--padding-disabled .card-body {
  padding: 0;
}
.tile--padding-disabled .tile__row {
  padding-top: var(--vz-card-spacer-y);
  padding-left: var(--vz-card-spacer-x);
  padding-right: var(--vz-card-spacer-x);
}
.tile--padding-disabled td:first-child,
.tile--padding-disabled th:first-child {
  padding-left: var(--vz-card-spacer-x);
}
.tile--padding-disabled td:last-child,
.tile--padding-disabled th:last-child {
  padding-right: var(--vz-card-spacer-x);
}
.tile--error {
  border: solid var(--vz-form-invalid-border-color) 1px;
  margin-bottom: 0;
}
.tile__error-message {
  margin-bottom: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Tile/Tile.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,gCAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;AAAJ;AAEE;EACE,aAAA;EACA,sBAAA;AAAJ;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADJ;AAGI;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AADN;AAGM;EACE,eAAA;AADR;AAOI;EACE,aAAA;AALN;AAUI;EACE,UAAA;AARN;AAWI;EACE,oCAAA;EACA,qCAAA;EACA,sCAAA;AATN;AAYI;;EAEE,qCAAA;AAVN;AAaI;;EAEE,sCAAA;AAXN;AAeE;EACE,qDAAA;EACA,gBAAA;AAbJ;AAgBE;EACE,qBAAA;AAdJ","sourcesContent":[".tile {\n  .card-title {\n    margin-bottom: 0;\n    font-family: \"Inter\", sans-serif;\n    font-weight: 500;\n    align-items: center;\n    display: flex;\n  }\n  .card-body {\n    display: flex;\n    flex-direction: column;\n  }\n\n  &__row {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    &__left {\n      display: flex;\n      gap: 9px;\n      align-items: center;\n\n      i {\n        font-size: 16px;\n      }\n    }\n  }\n\n  .card-content {\n    &:empty {\n      display: none;\n    }\n  }\n\n  &--padding-disabled {\n    .card-body {\n      padding: 0;\n    }\n\n    .tile__row {\n      padding-top: var(--vz-card-spacer-y);\n      padding-left: var(--vz-card-spacer-x);\n      padding-right: var(--vz-card-spacer-x);\n    }\n\n    td:first-child,\n    th:first-child {\n      padding-left: var(--vz-card-spacer-x);\n    }\n\n    td:last-child,\n    th:last-child {\n      padding-right: var(--vz-card-spacer-x);\n    }\n  }\n\n  &--error {\n    border: solid var(--vz-form-invalid-border-color) 1px;\n    margin-bottom: 0;\n  }\n\n  &__error-message {\n    margin-bottom: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
