import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
  POST_PROGRAM_PACKET_TEMPLATE,
  EDIT_PROGRAM_PACKET_TEMPLATE,
  ARCHIVE_PROGRAM_PACKET_TEMPLATE,
  RESTORE_PROGRAM_PACKET_TEMPLATE,
} from "./actionType";
import {
  programPacketTemplatesApiSuccess,
  programPacketTemplatesApiError,
} from "./action";

//Include Both Helper File with needed methods
import {
  getProgramPacketTemplateList,
  postProgramPacketTemplate,
  editProgramPacketTemplate,
  archiveProgramPacketTemplate,
  restoreProgramPacketTemplate,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getProgramPacketTemplateListData({ payload: data }) {
  try {
    let response;
    response = yield call(getProgramPacketTemplateList, data);

    yield put(
      programPacketTemplatesApiSuccess(
        GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
        response,
      ),
    );
  } catch (error) {
    yield put(
      programPacketTemplatesApiError(
        GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
        error,
      ),
    );
  }
}

function* createProgramPacketTemplate({ payload: programPacketTemplate }) {
  try {
    const response = yield call(
      postProgramPacketTemplate,
      programPacketTemplate.data,
      programPacketTemplate.onSuccess,
    );
    yield put(
      programPacketTemplatesApiSuccess(POST_PROGRAM_PACKET_TEMPLATE, response),
    );
    toast_success("Program Packet Template added successfully");
  } catch (error) {
    yield put(
      programPacketTemplatesApiError(POST_PROGRAM_PACKET_TEMPLATE, error),
    );
    toast_error(error.data.message);
  }
}

function* editProgramPacketTemplateEntry({ payload: programPacketTemplate }) {
  try {
    const response = yield call(
      editProgramPacketTemplate,
      programPacketTemplate.id,
      programPacketTemplate.data,
      programPacketTemplate.onSuccess,
    );
    yield put(
      programPacketTemplatesApiSuccess(EDIT_PROGRAM_PACKET_TEMPLATE, response),
    );
    toast_success("Program Packet Template edited successfully");
  } catch (error) {
    yield put(
      programPacketTemplatesApiError(EDIT_PROGRAM_PACKET_TEMPLATE, error),
    );
    toast_error(error.data.message);
  }
}

function* archiveProgramPacketTemplateEntry({
  payload: programPacketTemplate,
}) {
  try {
    const response = yield call(
      archiveProgramPacketTemplate,
      programPacketTemplate.id,
      programPacketTemplate.onSuccess,
    );
    yield put(
      programPacketTemplatesApiSuccess(
        ARCHIVE_PROGRAM_PACKET_TEMPLATE,
        response,
      ),
    );
    toast_success("Program Packet Template removed");
  } catch (error) {
    yield put(
      programPacketTemplatesApiError(ARCHIVE_PROGRAM_PACKET_TEMPLATE, error),
    );
    toast_error(error.data.message);
  }
}

function* restoreProgramPacketTemplateEntry({
  payload: programPacketTemplate,
}) {
  try {
    const response = yield call(
      restoreProgramPacketTemplate,
      programPacketTemplate.id,
      programPacketTemplate.onSuccess,
    );
    yield put(
      programPacketTemplatesApiSuccess(
        RESTORE_PROGRAM_PACKET_TEMPLATE,
        response,
      ),
    );
    toast_success("Program Packet Template restored");
  } catch (error) {
    yield put(
      programPacketTemplatesApiError(RESTORE_PROGRAM_PACKET_TEMPLATE, error),
    );
    toast_error(error.data.message);
  }
}

export function* watchGetProgramPacketTemplateListData() {
  yield takeEvery(
    GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
    getProgramPacketTemplateListData,
  );
}

export function* watchPostProgramPacketTemplate() {
  yield takeEvery(POST_PROGRAM_PACKET_TEMPLATE, createProgramPacketTemplate);
}
export function* watchEditProgramPacketTemplate() {
  yield takeEvery(EDIT_PROGRAM_PACKET_TEMPLATE, editProgramPacketTemplateEntry);
}
export function* watchArchiveProgramPacketTemplate() {
  yield takeEvery(
    ARCHIVE_PROGRAM_PACKET_TEMPLATE,
    archiveProgramPacketTemplateEntry,
  );
}
export function* watchRestoreProgramPacketTemplate() {
  yield takeEvery(
    RESTORE_PROGRAM_PACKET_TEMPLATE,
    restoreProgramPacketTemplateEntry,
  );
}

function* programPacketTemplatesSaga() {
  yield all([
    fork(watchGetProgramPacketTemplateListData),
    fork(watchPostProgramPacketTemplate),
    fork(watchEditProgramPacketTemplate),
    fork(watchArchiveProgramPacketTemplate),
    fork(watchRestoreProgramPacketTemplate),
  ]);
}

export default programPacketTemplatesSaga;
