// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detail-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  letter-spacing: 0.2px;
  font-weight: 500;
  white-space: nowrap;
}
.detail-row:last-child {
  border-bottom: none;
}
.detail-row__name {
  color: var(--vz-primary-text-emphasis);
}
.detail-row:hover {
  background: rgba(var(--vz-body-color-rgb), 0.04);
}
.detail-row--hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DetailRow/DetailRow.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,gCAAA;EAEA,qBAAA;EACA,gBAAA;EACA,mBAAA;AAAF;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,sCAAA;AADJ;AAOE;EACE,gDAAA;AALJ;AAQE;EACE,aAAA;AANJ","sourcesContent":[".detail-row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 0;\n  border-bottom: 1px solid #e0e0e0;\n\n  letter-spacing: 0.2px;\n  font-weight: 500;\n  white-space: nowrap;\n\n  &:last-child {\n    border-bottom: none;\n  }\n\n  &__name {\n    color: var(--vz-primary-text-emphasis);\n  }\n\n  &__value {\n  }\n\n  &:hover {\n    background: rgba(var(--vz-body-color-rgb), 0.04);\n  }\n\n  &--hidden {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
