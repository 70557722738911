// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participant-name-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.participant-name-cell__item {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/ParticipantNameCell/ParticipantNameCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".participant-name-cell {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n  &__item {\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
