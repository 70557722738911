import { Nav, NavItem, NavLink } from "reactstrap";
import { CustomerDetailsContext } from "../CustomerDetails";
import { useContext } from "react";
import classNames from "classnames";
import { FormattedString } from "Components/Common/FormattedString";

export const CustomerTabSwitcher = () => {
  const { activeTab, setActiveTab } = useContext(CustomerDetailsContext);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="customer-details__nav">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <NavLink
            href="#overview"
            className={classNames(
              { active: activeTab === "overview" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("overview");
            }}
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="overview" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#participants"
            className={classNames(
              { active: activeTab === "participants" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("participants");
            }}
          >
            <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="participants" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};
