// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-hotel__mapurl {
  display: flex;
  align-items: center;
  gap: 2px;
}
.single-hotel__icon {
  font-size: 31px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/components/HotelsView/SingleHotel/SingleHotel.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;AAAJ;AAGE;EACE,eAAA;AADJ","sourcesContent":[".single-hotel {\n  &__mapurl {\n    display: flex;\n    align-items: center;\n    gap: 2px;\n  }\n\n  &__icon {\n    font-size: 31px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
