// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-method-preview .accordion-button {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DetailsBox/fields/PaymentMethodPreview/PaymentMethodPreview.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ","sourcesContent":[".payment-method-preview {\n  .accordion-button {\n    padding: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
