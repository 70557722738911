import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_MEETING_POINT_LIST_DATA,
  POST_MEETING_POINT,
  EDIT_MEETING_POINT,
  ARCHIVE_MEETING_POINT,
  RESTORE_MEETING_POINT,
} from "./actionType";

export const INIT_STATE = {
  meetingPointsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  isMeetingPointAdded: false,
  isMeetingPointEdited: false,
  isMeetingPointArchived: false,
  isMeetingPointRestored: false,
  listInitialized: false,
};

const MeetingPoints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_MEETING_POINT:
    case EDIT_MEETING_POINT:
    case ARCHIVE_MEETING_POINT:
    case RESTORE_MEETING_POINT:
      return {
        ...state,
        loading: true,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_MEETING_POINT_LIST_DATA:
          return {
            ...state,
            meetingPointsData: action.payload.data,
            isMeetingPointAdded: false,
            isMeetingPointEdited: false,
            isMeetingPointArchived: false,
            isMeetingPointRestored: false,
            listInitialized: true,
          };
        case POST_MEETING_POINT:
          return {
            ...state,
            loading: false,
            isMeetingPointAdded: true,
          };
        case EDIT_MEETING_POINT:
          return {
            ...state,
            loading: false,
            isMeetingPointEdited: true,
          };
        case ARCHIVE_MEETING_POINT:
          return {
            ...state,
            loading: false,
            isMeetingPointArchived: true,
          };
        case RESTORE_MEETING_POINT:
          return {
            ...state,
            loading: false,
            isMeetingPointRestored: true,
          };

        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_MEETING_POINT_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            isMeetingPointAdded: false,
            isMeetingPointEdited: false,
            isMeetingPointArchived: false,
            isMeetingPointRestored: false,
            loading: false,
          };
        case POST_MEETING_POINT:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isMeetingPointAdded: false,
          };
        case EDIT_MEETING_POINT:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
            isMeetingPointEdited: false,
          };
        case ARCHIVE_MEETING_POINT:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isMeetingPointArchived: false,
          };
        case RESTORE_MEETING_POINT:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
            isMeetingPointRestored: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
export default MeetingPoints;
