import React from "react";
import "./ActionButtons.scss";

import { AngloTooltip } from "../../Common/AngloTooltip/AngloTooltip";
import { getString } from "Components/Common/FormattedString";
import { Link, useLocation } from "react-router-dom";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";

const ActionButtons = ({
  onEdit,
  onDelete,
  onArchive,
  onShowDetails,
  onDuplicate,
  customShowDetailsLink,
  itemId,
  isRestorable = false,
  onRestore = null,
  permissionPrefix,
  isEditDisabled = false,
  isDeleteDisabled = false,
  isArchiveDisabled = false,
}) => {
  const editButtonId = `editButton-${itemId}`;
  const archiveButtonId = `archiveButton-${itemId}`;
  const deleteButtonId = `deleteButton-${itemId}`;
  const restoreButtonId = `restoreButton-${itemId}`;
  const detailsButtonId = `detailsButton-${itemId}`;
  const duplicateButtonId = `duplicateButton-${itemId}`;

  const location = useLocation();

  return (
    <div className="action-buttons">
      {(onShowDetails || customShowDetailsLink) && (
        <div className="col-auto">
          <AngloTooltip stringId="show_details">
            <Link
              id={itemId && detailsButtonId}
              type="button"
              className="btn btn-sm btn-soft-primary btn-icon"
              to={
                customShowDetailsLink ||
                `${location.pathname}/${itemId}${location.search}`
              }
              onClick={onShowDetails}
            >
              <i className="ri-eye-line"></i>
            </Link>
          </AngloTooltip>
        </div>
      )}
      {onEdit && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_UPDATE`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="edit">
              <button
                id={itemId && editButtonId}
                type="button"
                className="btn btn-sm btn-soft-warning btn-icon"
                onClick={onEdit}
                disabled={isEditDisabled}
              >
                <i className="ri-edit-2-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
      {onDuplicate && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_DUPLICATE`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="duplicate">
              <button
                id={itemId && duplicateButtonId}
                type="button"
                className="btn btn-sm btn-soft-info btn-icon"
                onClick={onDuplicate}
              >
                <i className="ri-file-copy-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
      {isRestorable ? (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_RESTORE`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="restore_from_archive">
              <button
                id={itemId && restoreButtonId}
                type="button"
                className="btn btn-sm btn-soft-success btn-icon"
                onClick={onRestore}
              >
                <i className="ri-inbox-unarchive-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      ) : (
        onArchive && (
          <WithPermission
            permissionName={permissionPrefix && `${permissionPrefix}_ARCHIVE`}
          >
            <div className="col-auto">
              <AngloTooltip text={getString("archive")}>
                <button
                  id={itemId && archiveButtonId}
                  type="button"
                  disabled={isArchiveDisabled}
                  className="btn btn-sm btn-soft-danger btn-icon"
                  onClick={onArchive}
                >
                  <i className="ri-inbox-archive-line"></i>
                </button>
              </AngloTooltip>
            </div>
          </WithPermission>
        )
      )}
      {onDelete && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_DELETE`}
        >
          <div className="col-auto">
            <AngloTooltip text={getString("delete")}>
              <button
                id={itemId && deleteButtonId}
                type="button"
                disabled={isDeleteDisabled}
                className="btn btn-sm btn-soft-danger btn-icon"
                onClick={onDelete}
              >
                <i className="ri-delete-bin-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
    </div>
  );
};

export default ActionButtons;
