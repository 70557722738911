export const mockProgram = {
  id: "20206959-12f7-470f-ad8d-271eaed4a617",
  name: "Test program",
  season: "Summer 2024",
  startDate: "2024-03-26T17:26:07+00:00",
  endDate: "2024-03-26T17:26:08+00:00",
  signedParticipantsCapacity: 25,
  participantsCapacity: 50,
  signedInternationalParticipantsCapacity: 14,
  internationalParticipantsCapacity: 25,
  totalCapacity: 75,
  participantsFraction: 48.1,
  internationalParticipantsFraction: 51.9,
  healthAndSafetyCoordinatorCapacity: 2,
  status: "visible",
  hotel: {
    id: "7f8ccd12-f8b3-417b-b835-b94b852be27c",
    name: "Hilton Hotel",
    address: "Grzybowska 63",
    zipCode: "00-844",
    city: "Warsaw",
    mapUrl:
      "https://www.google.com/maps/place/Hilton+Warsaw+City/@52.2335267,20.9833097,17z/data=!3m1!4b1!4m9!3m8!1s0x471ecc845940e739:0xd953a67505c61591!5m2!4m1!1i2!8m2!3d52.2335234!4d20.9858846!16s%2Fm%2F03qh41d?entry=ttu",
    contactInfo: "proszę dzwonić dzwonkiem",
    status: "visible",
  },
  programMeetingsPP: [
    {
      id: "1abef7c5-10fd-4dba-a14c-825cd95e946f",
      startHour: "12:30",
      endHour: "16:30",
      dateModifier: 0,
      hotel: {
        id: "7f8ccd12-f8b3-417b-b835-b94b852be27c",
        name: "test4",
        address: "test3",
        zipCode: "21-070",
        city: "test5",
        mapUrl: "test1zzzxczxczxczzzz",
        contactInfo: "test1",
        status: "visible",
      },
      meetingPoint: null,
      additionalPrice: 0.0,
      groupType: "participants",
      status: "visible",
    },
    {
      id: "2bcdef8a-22fe-46eb-8b9f-93245abf1234",
      startHour: "10:00",
      endHour: "12:00",
      dateModifier: 1,
      hotel: {
        id: "8g9hdd12-f8b3-417b-b835-b94b852be27c",
        name: "testHotel2",
        address: "testAddress2",
        zipCode: "22-080",
        city: "testCity2",
        mapUrl: "testMapUrl2",
        contactInfo: "testContact2",
        status: "visible",
      },
      meetingPoint: {
        id: "5f6g7h8j-9k0l-4n6b-3v5c-2x3z4v5b6n7m",
        address: "meetingAddress1",
        city: "meetingCity1",
        mapUrl: "meetingMapUrl1",
        status: "visible",
      },
      additionalPrice: 50.0,
      groupType: "international",
      status: "visible",
    },
    {
      id: "3cdghi7j-33gh-57kl-8mn9-10345xyz6789",
      startHour: "14:00",
      endHour: "18:00",
      dateModifier: 2,
      hotel: {
        id: "9i8uhd12-f8b3-417b-b835-b94b852be27c",
        name: "testHotel3",
        address: "testAddress3",
        zipCode: "23-090",
        city: "testCity3",
        mapUrl: "testMapUrl3",
        contactInfo: "testContact3",
        status: "visible",
      },
      meetingPoint: {
        id: "6k7l8m9n-0o1p-2q3r-4s5t-6u7v8w9x0y1z",
        address: "meetingAddress2",
        city: "meetingCity2",
        mapUrl: "meetingMapUrl2",
        status: "visible",
      },
      additionalPrice: 75.0,
      groupType: "speakers",
      status: "visible",
    },
  ],
  programMeetingsNS: [
    {
      id: "cd41954f-3bd3-4185-98f4-759fb9cc75e2",
      startHour: "12:30",
      endHour: "16:30",
      dateModifier: 0,
      hotel: {
        id: "7f8ccd12-f8b3-417b-b835-b94b852be27c",
        name: "test4",
        address: "test3",
        zipCode: "21-070",
        city: "test5",
        mapUrl: "test1zzzxczxczxczzzz",
        contactInfo: "test1",
        status: "visible",
      },
      meetingPoint: {
        id: "78742bce-a12b-4d1a-aa3a-42ca2f0494a7",
        address: "test3",
        city: "test5",
        mapUrl: "test5",
        status: "visible",
      },
      additionalPrice: 0.0,
      groupType: "native_speakers",
      status: "visible",
    },
  ],
  programPackets: [
    {
      id: 1,
      packageName: "Kids",
      costs: [3500],
      installmentsRate: 2,
    },
    {
      id: 2,
      packageName: "Junior 11-16",
      costs: [4000],
      installmentsRate: 3,
    },
    {
      id: 3,
      packageName: "Junior 14-18",
      costs: [3000, 1500],
      installmentsRate: 4,
    },
  ],
  participants: [
    {
      id: 1,
      packageName: "Kids",
      status: "in_progress",
      waitListed: 4,
      prebooking: 15,
      installments: 4,
      partlyPaid: 2,
      fullyPaid: 9,
      capacity: 50,
    },
    {
      id: 2,
      packageName: "Junior 11-16",
      status: "pending",
      waitListed: 6,
      prebooking: 12,
      installments: 7,
      partlyPaid: 3,
      fullyPaid: 2,
      capacity: 50,
    },
    {
      id: 2,
      packageName: "Junior 14-18",
      status: "completed",
      waitListed: 2,
      prebooking: 15,
      installments: 8,
      partlyPaid: 7,
      fullyPaid: 4,
      capacity: 50,
    },
  ],
  internationalParticipants: [
    {
      id: 1,
      isGroupHeader: true,
      packageName: "Kids",
      status: "in_progress",
      waitListed: 4,
      prebooking: 15,
      installments: 4,
      partlyPaid: 2,
      fullyPaid: 9,
      capacity: 50,
    },
    {
      id: 2,
      isGroupHeader: false,
      packageName: "IP",
      status: "in_progress",
      waitListed: 4,
      prebooking: 15,
      installments: 4,
      partlyPaid: 2,
      fullyPaid: 9,
      capacity: 50,
    },
    {
      id: 3,
      isGroupHeader: false,
      packageName: "ESL",
      status: "in_progress",
      waitListed: 4,
      prebooking: 15,
      installments: 4,
      partlyPaid: 2,
      fullyPaid: 9,
      capacity: 50,
    },
    {
      id: 4,
      isGroupHeader: true,
      packageName: "Junior 11-16",
      status: "pending",
      waitListed: 6,
      prebooking: 12,
      installments: 7,
      partlyPaid: 3,
      fullyPaid: 2,
      capacity: 50,
    },
  ],
  participantUpsells: [
    {
      id: "9e2049fe-ab39-41bd-a51e-5436964c9a9f",
      name: "VEGE",
      description: "this is just a test upsell, well associate it later",
      basePrice: 0,
      type: "diet",
      notPaid: 10,
      partlyPaid: 5,
      fullyPaid: 5,
      attachments: [],
      status: "visible",
    },
  ],
  internationalParticipantUpsells: [
    {
      id: "9e2049fe-ab39-41bd-a51e-5436964c9a9f",
      name: "VEGE",
      description: "this is just a test upsell, well associate it later",
      basePrice: 0,
      type: "diet",
      notPaid: 10,
      partlyPaid: 5,
      fullyPaid: 5,
      attachments: [],
      status: "visible",
    },
  ],
  healthAndSafetyCoordinators: [
    {
      id: "3bbf9b41-bdb6-4d63-aa5a-6fe8d9029df2",
      userId: "e03d7e17-9b4a-45d2-8aa9-3dad333d24b7",
      firstName: "ASDe",
      lastName: "asdasdasdasd",
      type: "health_and_safety",
      status: "visible",
    },
  ],
  ipTours: [
    {
      id: 1,
      hour: "10:00",
      date: "2021-01-01",
      city: "Warsaw",
      name: "Plac Defilad",
      mapUrl: "https://google.com/maps",
      localParticpants: 20,
      internationalParticipants: 10,
      coordinators: 0,
      capacity: 50,
    },
  ],
};
