import React, { useCallback, useState } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  duplicateProgram,
  getProgramListData,
} from "../../store/programs/action";
import { getString } from "Components/Common/FormattedString";
import ConfirmDuplicateBody from "Components/Entity/ConfirmDuplicateBody";

const DuplicateProgram = ({ itemId, visibility, toggle, onSuccess }) => {
  const dispatch = useDispatch();
  const [isDuplicating, setIsDuplicating] = useState(false);

  const duplicateProgramEntry = useCallback(() => {
    setIsDuplicating(true);
    dispatch(
      duplicateProgram(itemId, (r) => {
        dispatch(getProgramListData());
        toggle(false);
        setIsDuplicating(false);
        if (onSuccess) {
          onSuccess(r);
        }
      }),
    );
  }, [dispatch, itemId, toggle, onSuccess]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDuplicateBody
        entityName={getString("program")}
        toggle={toggle}
        duplicateAction={duplicateProgramEntry}
        isDuplicating={isDuplicating}
      />
    </Modal>
  );
};

export default DuplicateProgram;
