// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ip-participants-attachments-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.ip-participants-attachments-list .attachment-item {
  display: flex;
  align-items: center;
}
.ip-participants-attachments-list .attachment-index {
  margin-right: 8px;
  font-weight: bold;
}
.ip-participants-attachments-list .attachment-description {
  margin-right: 8px;
}
.ip-participants-attachments-list .single-attachment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpParticipants/components/IpParticipantsAttachmentsList/IpParticipantsAttachmentsList.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,mBAAA;AACJ;AAEE;EACE,iBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,iBAAA;AADJ;AAIE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAFJ","sourcesContent":[".ip-participants-attachments-list {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  .attachment-item {\n    display: flex;\n    align-items: center;\n  }\n\n  .attachment-index {\n    margin-right: 8px;\n    font-weight: bold;\n  }\n\n  .attachment-description {\n    margin-right: 8px;\n  }\n\n  .single-attachment {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
