import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const AttachmentDetails = ({ itemId, toggle, visible }) => {
  const { attachmentsData } = useSelector((rootState) => rootState.Attachments);
  const item = attachmentsData.find((attachment) => attachment.id === itemId);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="attachment-details-modal"
      title={getString("attachment_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
