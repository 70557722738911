import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_COORDINATOR_LIST_DATA,
  POST_COORDINATOR,
  EDIT_COORDINATOR,
  ARCHIVE_COORDINATOR,
  RESTORE_COORDINATOR,
} from "./actionType";

export const coordinatorsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const coordinatorsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCoordinatorListData = () => ({
  type: GET_COORDINATOR_LIST_DATA,
  payload: {},
});

export const postCoordinator = (data, onSuccess) => ({
  type: POST_COORDINATOR,
  payload: { data, onSuccess },
});
export const editCoordinator = (id, data, onSuccess) => ({
  type: EDIT_COORDINATOR,
  payload: { id, data, onSuccess },
});

export const archiveCoordinator = (id, onSuccess) => ({
  type: ARCHIVE_COORDINATOR,
  payload: { id, onSuccess },
});

export const restoreCoordinator = (id, onSuccess) => ({
  type: RESTORE_COORDINATOR,
  payload: { id, onSuccess },
});
