// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan-installments-view {
  display: flex;
  gap: 8px;
}
.plan-installments-view__dot {
  height: 32px;
  width: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: #878a99;
  color: white;
  justify-content: center;
  border-radius: 100%;
}
.plan-installments-view__dot--active {
  background: #299cdb;
}

.plan-installments-tooltip li {
  text-align: left;
}
.plan-installments-tooltip ul {
  padding-left: 1rem;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/PlanInstallmentsView/PlanInstallmentsView.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;AACJ;AACI;EACE,mBAAA;AACN;;AAKE;EACE,gBAAA;AAFJ;AAIE;EACE,kBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".plan-installments-view {\n  display: flex;\n  gap: 8px;\n\n  &__dot {\n    height: 32px;\n    width: 32px;\n    font-weight: bold;\n    display: flex;\n    align-items: center;\n    background: #878a99;\n    color: white;\n    justify-content: center;\n    border-radius: 100%;\n\n    &--active {\n      background: #299cdb;\n    }\n  }\n}\n\n.plan-installments-tooltip {\n  li {\n    text-align: left;\n  }\n  ul {\n    padding-left: 1rem;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
