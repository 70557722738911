import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import {
  blockIpParticipant,
  getIpParticipantListData,
} from "store/ipParticipants/action";
import ConfirmBlockBody from "Components/Entity/ConfirmBlockBody";

const BlockIpModal = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const blockIpEntry = useCallback(() => {
    dispatch(
      blockIpParticipant(itemId, () => {
        dispatch(getIpParticipantListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmBlockBody
        entityName={getString("ip_participant_sm")}
        toggle={toggle}
        blockAction={blockIpEntry}
      />
    </Modal>
  );
};

export default BlockIpModal;
