import classNames from "classnames";
import "./DetailRow.scss";

export const DetailRow = ({ name, value, hidden }) => {
  const detailRowClass = classNames("detail-row", {
    [`detail-row--${name.toLowerCase().split(" ").join("-")}`]: name,
    "detail-row--hidden": hidden,
  });

  return (
    <div className={detailRowClass}>
      <div className="detail-row__name">{name}</div>
      <div className="detail-row__value">{value}</div>
    </div>
  );
};
