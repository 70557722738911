import React, { useState } from "react";

import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import { UpsellAttachmentsList } from "pages/Upsells/UpsellAttachmentsList/UpsellAttachmentsList";
import { Card, CardBody } from "reactstrap";

import { toast_error, toast_success } from "helpers/toast_helper";

import { useDispatch } from "react-redux";
import { addUpsellAttachments } from "helpers/API/core-service/cs_backend_helper";
import { getUpsellListData } from "store/upsells/action";

const UpsellAttachments = ({ attachments, upsell, toggle, visibility }) => {
  const dispatch = useDispatch();

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const addAttachments = () => {
    const data = {
      attachments: selectedAttachments.map((attachment) => attachment.value),
    };

    addUpsellAttachments(upsell.id, data)
      .then(() => {
        dispatch(getUpsellListData());
        toast_success(getString("attachments_added"));
        toggle();
      })
      .catch((error) => {
        toast_error(error.message);
      });
  };

  return (
    <OffcanvasRight
      isOpen={visibility}
      toggle={toggle}
      title={upsell?.name + " " + getString("attachments_sm")}
      formId={"edit-upsell"}
      buttonLabel={getString("save")}
      validationRule={selectedAttachments.every(
        (attachment) => attachment.value !== undefined,
      )}
      onSubmit={(e) => {
        e.preventDefault();
        addAttachments();
      }}
    >
      <Card>
        <CardBody className="card-body">
          <UpsellAttachmentsList
            attachments={attachments}
            selectedAttachments={selectedAttachments}
            setSelectedAttachments={setSelectedAttachments}
            upsell={upsell}
          />
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};

export default UpsellAttachments;
