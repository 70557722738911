import { useState } from "react";

import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { toast_info } from "helpers/toast_helper";
import { Link } from "react-router-dom";
import ArchiveAttachment from "./ArchiveAttachment";
import { getLocation } from "helpers/utils";

export const SingleAttachment = ({ attachment, removable = true }) => {
  const [archiveAttachmentVisible, setArchiveAttachmentVisible] =
    useState(false);

  const participantId = getLocation(2) || getLocation(3);

  return (
    <div className="single-attachment">
      <div className="d-flex gap-2 align-items-center">
        <AngloTooltip stringId="copy_to_clipboard">
          <button
            type="button"
            className="btn btn-sm btn-secondary btn-icon"
            onClick={() => {
              navigator.clipboard.writeText(attachment.filePath);
              toast_info("Path copied to clipboard");
            }}
          >
            <i className="ri-file-copy-line"></i>
          </button>
        </AngloTooltip>
        <AngloTooltip stringId="download">
          <Link to={attachment.filePath}>
            <button type="button" className="btn btn-sm btn-secondary btn-icon">
              <i className="ri-download-line"></i>
            </button>
          </Link>
        </AngloTooltip>
        {removable && (
          <AngloTooltip stringId="delete_attachment">
            <button
              type="button"
              className="btn btn-sm btn-secondary btn-icon btn-danger"
              onClick={() => {
                setArchiveAttachmentVisible(true);
              }}
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </AngloTooltip>
        )}
      </div>

      {archiveAttachmentVisible && (
        <ArchiveAttachment
          visibility={archiveAttachmentVisible}
          toggle={() => setArchiveAttachmentVisible((p) => !p)}
          participantId={participantId}
          attachmentId={attachment.id}
        />
      )}
    </div>
  );
};
