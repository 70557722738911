import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { setAuthToken as setCSAuthToken } from "../../../helpers/API/core-service/cs_backend_helper";
import { setAuthToken as setASAuthToken } from "../../../helpers/API/account-service/as_backend_helper";
import { googleGrantAuth } from "../../../helpers/API/account-service/as_backend_helper";
import { fetchUserProfile } from "../profile/actions";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(googleGrantAuth, {
      email: user.email,
      token: user.token,
    });

    if (response) {
      localStorage.setItem("authUser", JSON.stringify(response));
      yield call(setCSAuthToken, response.accessToken);
      yield call(setASAuthToken, response.accessToken);
      yield put(fetchUserProfile());
      yield put(loginSuccess(response));
      history("/dashboard");
    } else {
      console.error("api error");
      yield put(apiError(response));
    }
  } catch (error) {
    console.error(error);
    yield put(apiError(error));
  }
}

export function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("activeBusinessUnit");
    localStorage.removeItem("userData");
    localStorage.removeItem("layoutModeType");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
