import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
  POST_PROGRAM_PACKET_TEMPLATE,
  EDIT_PROGRAM_PACKET_TEMPLATE,
  ARCHIVE_PROGRAM_PACKET_TEMPLATE,
  RESTORE_PROGRAM_PACKET_TEMPLATE,
} from "./actionType";

export const programPacketTemplatesApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const programPacketTemplatesApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProgramPacketTemplateListData = () => ({
  type: GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA,
  payload: {},
});

export const postProgramPacketTemplate = (data, onSuccess) => ({
  type: POST_PROGRAM_PACKET_TEMPLATE,
  payload: { data, onSuccess },
});

export const editProgramPacketTemplate = (id, data, onSuccess) => ({
  type: EDIT_PROGRAM_PACKET_TEMPLATE,
  payload: { id, data, onSuccess },
});

export const archiveProgramPacketTemplate = (id, onSuccess) => ({
  type: ARCHIVE_PROGRAM_PACKET_TEMPLATE,
  payload: { id, onSuccess },
});

export const restoreProgramPacketTemplate = (id, onSuccess) => ({
  type: RESTORE_PROGRAM_PACKET_TEMPLATE,
  payload: { id, onSuccess },
});
