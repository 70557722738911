export const DetailsBoxTabSelect = () => {
  return (
    <div className="details-box__tab-select">
      <div className="details-box__tab-select__btn details-box__tab-select__btn--active">
        Details
      </div>
      {/* <div className="details-box__tab-select__btn">Specification</div> */}
    </div>
  );
};
