import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const ipTypeOptions = [
  { value: "active", label: getStringSync("ip") },
  { value: "non_ns_ip", label: getStringSync("non_ns_ip") },
  { value: "ns", label: getStringSync("ns") },
  { value: "esl", label: getStringSync("esl") },
];

export const ipDocumentTypeOptions = [
  { value: "id", label: getStringSync("id") },
  { value: "passport", label: getStringSync("passport") },
  { value: "residence_card", label: getStringSync("residence_card") },
  { value: "dbs_check", label: getStringSync("dbs_check") },
  { value: "travel_details", label: getStringSync("travel_details") },
];

const createIpParticipantSchema = Yup.object({
  gender: Yup.string().required(getStringSync("enter_ipParticipant_gender")),
  firstName: Yup.string()
    .required(getStringSync("enter_ipParticipant_first_name"))
    .min(4, getStringSync("invalid_length"))
    .max(32, getStringSync("invalid_length")),
  lastName: Yup.string()
    .required(getStringSync("enter_ipParticipant_last_name"))
    .min(4, getStringSync("invalid_length"))
    .max(64, getStringSync("invalid_length")),
  email: Yup.string()
    .email(getStringSync("invalid_mail"))
    .required(getStringSync("mail_required"))
    .min(5, getStringSync("invalid_length"))
    .max(255, getStringSync("invalid_length")),
  phonePrefix: Yup.string()
    .required(getStringSync("enter_ipParticipant_phone_prefix"))
    .matches(/^\d+$/, getStringSync("invalid_phone_prefix")),
  phoneNumber: Yup.string()
    .required(getStringSync("enter_ipParticipant_phone_number"))
    .matches(/^\d+$/, getStringSync("invalid_phone_number")),
  dateOfBirth: Yup.date()
    .required(getStringSync("enter_ipParticipant_date_of_birth"))
    .max(new Date(), getStringSync("invalid_date_of_birth")),
  countryOfOrigin: Yup.string()
    .min(3, getStringSync("country_of_origin_min_length"))
    .required(getStringSync("enter_ip_participant_country_of_origin")),
  preferredDiet: Yup.string().required(
    getStringSync("enter_ipParticipant_preferred_diet"),
  ),
  type: Yup.string().required(getStringSync("enter_ipParticipant_type")),
});

export { createIpParticipantSchema, ipTypeOptions };
