import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import FormTextArea from "Components/Entity/FormTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { FormattedString } from "Components/Common/FormattedString";

const FormikTextArea = ({ name, value, handleSubmit, disabled, ...props }) => {
  const [initialValue, setInitialValue] = useState(value || "");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      [name]: initialValue,
    },
    validationSchema: Yup.object({
      [name]: Yup.string()
        .required("This field is required")
        .min(3, "Minimum length is 3 characters"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleSubmit(values[name]).finally(() => {
        setLoading(false);
      });
    },
  });

  useEffect(() => {
    setInitialValue(value || "");
    formik.setFieldValue(name, value || "", false);
    formik.setFieldTouched(name, false, false);
  }, [value, name]);

  const handleCancel = () => {
    formik.resetForm();
  };

  const isValueChanged = formik.values[name] !== initialValue;

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormTextArea
        name={name}
        value={formik.values[name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors[name]}
        disabled={disabled || loading}
        {...props}
      />
      {isValueChanged && (
        <div className="d-flex justify-content-center mt-3 gap-5">
          <LinkButton
            color="secondary"
            type="button"
            onClick={handleCancel}
            disabled={disabled || loading}
          >
            <i className="ri-close-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="cancel" />
          </LinkButton>
          <LinkButton
            color="primary"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={disabled || loading}
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <>
                <i
                  className={value ? "ri-edit-line" : "ri-add-line"}
                  style={{ marginRight: "4px" }}
                ></i>
                {value ? (
                  <FormattedString id="update" />
                ) : (
                  <FormattedString id="create" />
                )}
              </>
            )}
          </LinkButton>
        </div>
      )}
    </form>
  );
};

export default FormikTextArea;
