import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const PaymentMethodDetails = ({ itemId, toggle, visible }) => {
  const { paymentMethodsData } = useSelector(
    (rootState) => rootState.PaymentMethods,
  );

  const item = paymentMethodsData.find(
    (paymentMethod) => paymentMethod.id === itemId,
  );

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="payment-method-details-modal"
      title={getString("payment_method_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
