import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_APPLICATIONS_LIST_DATA } from "./actionType";
import { applicationsApiSuccess, applicationsApiError } from "./action";

import { getApplicationsList } from "../../helpers/API/core-service/cs_backend_helper";

function* getApplicationsListData({ payload: data }) {
  try {
    const { page, searchTerm } = data;
    const response = yield call(getApplicationsList, page, searchTerm);

    yield put(applicationsApiSuccess(GET_APPLICATIONS_LIST_DATA, response));
  } catch (error) {
    yield put(applicationsApiError(GET_APPLICATIONS_LIST_DATA, error));
  }
}

export function* watchGetApplicationsListData() {
  yield takeEvery(GET_APPLICATIONS_LIST_DATA, getApplicationsListData);
}

function* applicationsSaga() {
  yield all([fork(watchGetApplicationsListData)]);
}

export default applicationsSaga;
