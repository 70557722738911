import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  restoreProgramPacketTemplate,
  getProgramPacketTemplateListData,
} from "../../store/programPacketTemplates/action";
import ConfirmRestoreBody from "../../Components/Entity/ConfirmRestoreBody";
import { getString } from "Components/Common/FormattedString";

const RestoreProgramPacketTemplate = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const restoreProgramPacketTemplateEntry = useCallback(() => {
    dispatch(
      restoreProgramPacketTemplate(itemId, () => {
        dispatch(getProgramPacketTemplateListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmRestoreBody
        entityName={getString("program_packet_template_sm")}
        toggle={toggle}
        restoreAction={restoreProgramPacketTemplateEntry}
      />
    </Modal>
  );
};

export default RestoreProgramPacketTemplate;
