import React from "react";
import { Card, CardBody } from "reactstrap";
import EntityListHeader from "./EntityListHeader";

const EntityCardList = ({
  title,
  actionBtnLabel,
  actionBtn,
  actionBtnPermission,
  headerButtons,
  children,
}) => {
  return (
    <React.Fragment>
      <Card>
        <EntityListHeader
          title={title}
          actionBtnLabel={actionBtnLabel}
          actionBtn={actionBtn}
          actionBtnPermission={actionBtnPermission}
          headerButtons={headerButtons}
        />
        <CardBody>
          <div>{children}</div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EntityCardList;
