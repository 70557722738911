import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import React from "react";
import { CardHeader, Row } from "reactstrap";

const EntityListHeader = ({
  title,
  actionBtnLabel,
  actionBtn,
  actionBtnPermission,
  headerButtons,
}) => {
  return (
    <React.Fragment>
      <CardHeader className="card-header border-0">
        <Row className="align-items-center gy-3">
          <div className="col-sm">
            <h5 className="card-title mb-0">{title}</h5>
          </div>
          <div className="col-sm-auto">
            <div className="d-flex gap-3 align-items-center flex-wrap">
              {headerButtons}
              {actionBtn && (
                <WithPermission permissionName={actionBtnPermission}>
                  <button
                    type="button"
                    className="btn btn-soft-success"
                    onClick={() => actionBtn(true)}
                  >
                    <i className="ri-add-circle-line align-middle me-1"></i> Add{" "}
                    {actionBtnLabel}
                  </button>
                </WithPermission>
              )}
            </div>
          </div>
        </Row>
      </CardHeader>
    </React.Fragment>
  );
};

export default EntityListHeader;
