import React from "react";
import "./PackageCell.scss";
import classNames from "classnames";

const PackageCell = ({ packages }) => {
  return (
    <div className="package-cell">
      {packages && packages.length > 0
        ? packages.map((pkg, index) => {
            const className =
              "package-cell__item--" + pkg.toLowerCase().replace(/ /g, "_");
            return (
              <div
                key={index}
                className={classNames("package-cell__item", className)}
              >
                {pkg}
              </div>
            );
          })
        : "-"}
    </div>
  );
};

export default PackageCell;
