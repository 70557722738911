import React from "react";
import { Label, FormFeedback } from "reactstrap";

const FormTextArea = ({
  label,
  name,
  value,
  onChange,
  error,
  rows = 3,
  disabled,
}) => {
  return (
    <div className="mb-3">
      {label && (
        <Label htmlFor={name} className="form-label">
          {label}
        </Label>
      )}
      <textarea
        className="form-control"
        id={name}
        name={name}
        onChange={(e) => onChange(e, name)}
        value={value || ""}
        rows={rows}
        style={{ borderColor: error ? "red" : undefined }}
        disabled={disabled}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormTextArea;
