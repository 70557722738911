import "./OutlineButton.scss";
import { Button } from "reactstrap";
import classNames from "classnames";

export const OutlineButton = ({ children, className, ...rest }) => {
  return (
    <Button
      size="sm"
      color="link"
      className={classNames("outline-button", className)}
      {...rest}
    >
      {children}
    </Button>
  );
};
