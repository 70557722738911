import React, { useContext, useState } from "react";
import "./ParticipantsTab.scss";
import {
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { FormattedString, getString } from "Components/Common/FormattedString";
import TableContainer from "Components/Common/TableContainerReactTable";
import { useHasPermission } from "Components/Hooks/useHasPermission";

export const ParticipantsTab = () => {
  const { programData } = useContext(ProgramDetailsContext);
  const { participants } = programData;

  const [deleteIpModalVisible, setDeleteIpModalVisible] = useState(false);

  const hasProgramUpdatePermission = useHasPermission("PROGRAM_UPDATE");

  const columns = [
    {
      Header: <FormattedString id="firstName" />,
      accessor: "firstName",
    },
    {
      Header: <FormattedString id="lastName" />,
      accessor: "lastName",
    },
    {
      Header: <FormattedString id="phoneNumber" />,
      accessor: "phoneNumber",
      Cell: ({ row }) => (
        <a href={`tel:+${row.original.phonePrefix}${row.original.phoneNumber}`}>
          +{row.original.phonePrefix} {row.original.phoneNumber}
        </a>
      ),
    },
    {
      Header: <FormattedString id="email" />,
      accessor: "email",
      Cell: ({ value }) => <a href={`mailto:${value}`}>{value}</a>,
    },
    {
      Header: <FormattedString id="dateOfBirth" />,
      accessor: "dateOfBirth",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      Header: (
        <div className="text-end">
          <FormattedString id="action" />
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: ({ row }) => (
        <UncontrolledDropdown direction="down" className="text-end">
          <DropdownToggle
            tag="a"
            className="btn btn-light btn-icon"
            id="dropdownMenuLink15"
            role="button"
          >
            <i className="ri-more-2-fill"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <i className="ri-eye-fill me-2 align-middle text-muted" />
              <FormattedString id="view" />
            </DropdownItem>
            <DropdownItem>
              <i className="ri-download-2-fill me-2 align-middle text-muted" />
              <FormattedString id="download" />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() => setDeleteIpModalVisible(true)}
              disabled={!hasProgramUpdatePermission}
            >
              <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
              <FormattedString id="delete" />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  return (
    <TabPane tabId="participants" className="participants-tab">
      {participants.map((packet) => (
        <Card key={packet.programPacketId}>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">
                {packet.programPacketName}
              </h5>
            </div>

            <TableContainer
              columns={columns}
              data={packet.data}
              isPagination={true}
              isGlobalFilter={true}
              isBordered={false}
              customPageSize={10}
              className="custom-header-css table align-middle table-nowrap"
              tableClassName="table-centered align-middle table-nowrap mb-0"
              theadClassName="text-muted table-light"
              SearchPlaceholder={getString("participant_search_for")}
            />
          </CardBody>
        </Card>
      ))}
    </TabPane>
  );
};
