import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { GET_CUSTOMERS_LIST_DATA } from "./actionType";
import { customersApiSuccess, customersApiError } from "./action";

import { getCustomersList } from "../../helpers/API/core-service/cs_backend_helper";

function* getCustomersListData({ payload: data }) {
  try {
    const { page, searchTerm } = data;
    const response = yield call(getCustomersList, page, searchTerm);

    yield put(customersApiSuccess(GET_CUSTOMERS_LIST_DATA, response));
  } catch (error) {
    yield put(customersApiError(GET_CUSTOMERS_LIST_DATA, error));
  }
}

export function* watchGetCustomersListData() {
  yield takeEvery(GET_CUSTOMERS_LIST_DATA, getCustomersListData);
}

function* customersSaga() {
  yield all([fork(watchGetCustomersListData)]);
}

export default customersSaga;
