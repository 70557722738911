import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PROGRAM_LIST_DATA,
  POST_PROGRAM,
  EDIT_PROGRAM,
  DELETE_PROGRAM,
  DUPLICATE_PROGRAM,
} from "./actionType";

export const programsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const programsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getProgramListData = () => ({
  type: GET_PROGRAM_LIST_DATA,
  payload: {},
});

export const postProgram = (data, onSuccess) => ({
  type: POST_PROGRAM,
  payload: { data, onSuccess },
});

export const editProgram = (id, data, onSuccess) => ({
  type: EDIT_PROGRAM,
  payload: { id, data, onSuccess },
});

export const deleteProgram = (id, onSuccess) => ({
  type: DELETE_PROGRAM,
  payload: { id, onSuccess },
});

export const duplicateProgram = (id, onSuccess) => ({
  type: DUPLICATE_PROGRAM,
  payload: { id, onSuccess },
});
