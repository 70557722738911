import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  DELETE_USER,
  EDIT_USER,
  GET_BUSINESS_UNIT_LIST_DATA,
  GET_USER_LIST_DATA,
  POST_USER,
} from "./actionType";
import { usersApiSuccess, usersApiError } from "./action";

//Include Both Helper File with needed methods
import {
  deleteUser,
  editUser,
  getUserList,
  postUser,
} from "../../helpers/API/account-service/as_backend_helper";
import { getBusinessUnitList } from "../../helpers/API/core-service/cs_backend_helper";

function* getUserListData({ payload: data }) {
  try {
    let response;
    response = yield call(getUserList, data);

    yield put(usersApiSuccess(GET_USER_LIST_DATA, response));
  } catch (error) {
    yield put(usersApiError(GET_USER_LIST_DATA, error));
  }
}

function* getBusinessUnitListData({ payload: data }) {
  try {
    let response;
    response = yield call(getBusinessUnitList, data);

    yield put(usersApiSuccess(GET_BUSINESS_UNIT_LIST_DATA, response));
  } catch (error) {
    yield put(usersApiError(GET_BUSINESS_UNIT_LIST_DATA, error));
  }
}

function* createUser({ payload: user }) {
  try {
    const response = yield call(postUser, user.data, user.onSuccess);
    yield put(usersApiSuccess(POST_USER, response));
    toast_success("User added successfully");
  } catch (error) {
    yield put(usersApiError(POST_USER, error));
    toast_error("Failed to create user");
    toast_error(error.data.message);
  }
}

function* editUserEntry({ payload: user }) {
  try {
    const response = yield call(editUser, user.id, user.data, user.onSuccess);
    yield put(usersApiSuccess(EDIT_USER, response));
    toast_success("User edited successfully");
  } catch (error) {
    yield put(usersApiError(EDIT_USER, error));
    toast_error(error.data.message);
  }
}

function* deleteUserEntry({ payload: user }) {
  try {
    const response = yield call(deleteUser, user.id, user.onSuccess);
    yield put(usersApiSuccess(DELETE_USER, response));
    toast_success("User removed");
  } catch (error) {
    yield put(usersApiError(DELETE_USER, error));
    toast_error(error.data.message);
  }
}

export function* watchGetUserListData() {
  yield takeEvery(GET_USER_LIST_DATA, getUserListData);
}
export function* watchGetBusinessUnitListData() {
  yield takeEvery(GET_BUSINESS_UNIT_LIST_DATA, getBusinessUnitListData);
}
export function* watchPostUser() {
  yield takeEvery(POST_USER, createUser);
}
export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUserEntry);
}
export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserEntry);
}

function* usersSaga() {
  yield all([
    fork(watchGetBusinessUnitListData),
    fork(watchGetUserListData),
    fork(watchPostUser),
    fork(watchEditUser),
    fork(watchDeleteUser),
  ]);
}

export default usersSaga;
