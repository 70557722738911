import { FormattedString } from "Components/Common/FormattedString";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useContext } from "react";
import { Badge } from "reactstrap";

export const ApplicationUtms = () => {
  const {
    applicationData: { utmCampaign, utmContent, utmMedium, utmSource, utmTerm },
  } = useContext(ApplicationDetailsContext);

  const utms = { utmCampaign, utmContent, utmMedium, utmSource, utmTerm };
  const hasUtms = Object.values(utms).some((value) => value);

  return (
    <div className="application-utms">
      {hasUtms ? (
        Object.entries(utms).map(
          ([key, value]) =>
            value && (
              <Badge key={key} color="primary" className="m-1">
                {key}: {value}
              </Badge>
            ),
        )
      ) : (
        <div>
          <FormattedString id="no_utms_available" />
        </div>
      )}
    </div>
  );
};
