import { SingleProgramInstallmentDot } from "../SingleProgramInstallmentDot";
import "./ProgramInstallments.scss";

export const ProgramInstallments = ({
  installmentPlans,
  programPrices,
  programPacket,
}) => {
  const orderedInstallmentPlans = installmentPlans?.sort(
    (a, b) => a.installmentCount - b.installmentCount,
  );

  return (
    <div className="program-installments">
      {Array.from({ length: orderedInstallmentPlans?.length }, (_, i) => (
        <SingleProgramInstallmentDot
          key={i}
          index={i + 1}
          installmentPlan={orderedInstallmentPlans[i]}
          programPrices={programPrices}
          programPacket={programPacket}
        />
      ))}
    </div>
  );
};
