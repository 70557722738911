import React, { useContext, useEffect } from "react";

import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import FormNumberField from "Components/Entity/FormNumberField";
import { getString } from "Components/Common/FormattedString";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { addProgramPacket } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";

export const AddProgramPacketCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      ppCapacity: "",
      ipCapacity: "",
      programPacketTemplateId: "",
    },
    validationSchema: Yup.object({
      ppCapacity: Yup.string().required(getString("pp_capacity_required")),
      ipCapacity: Yup.string().required(getString("ip_capacity_required")),
      programPacketTemplateId: Yup.string().required(
        getString("program_packet_template_required"),
      ),
    }),
    onSubmit: (values) => {
      addProgramPacket(programData.id, values)
        .then(() => {
          toast_success(getString("program_packet_added"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message));
    },
  });

  const { programPacketTemplatesData } = useSelector(
    (rootState) => rootState.ProgramPacketTemplates,
  );
  const programPacketOptions = programPacketTemplatesData
    .filter((item) => item?.status !== "archived")
    .map((pp) => {
      return { value: pp.id, label: pp.label };
    });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgramPacketTemplateListData());
  }, []);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("program_packet_add")}
      formId="add-program-packet-form"
      validationRule={true}
      buttonLabel={getString("save")}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="add-program-packet-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormNumberField
              label={getString("ppCapacity")}
              name="ppCapacity"
              value={validation.values.ppCapacity}
              onChange={(e) => handleInputChange(validation, e, "ppCapacity")}
              error={validation.errors.ppCapacity}
              touched={validation.touched.ppCapacity}
            />
            <FormNumberField
              label={getString("ipCapacity")}
              name="ipCapacity"
              value={validation.values.ipCapacity}
              onChange={(e) => handleInputChange(validation, e, "ipCapacity")}
              error={validation.errors.ipCapacity}
              touched={validation.touched.ipCapacity}
            />
            <FormSelect
              key={validation.values.type}
              name="programPacketTemplateId"
              label={getString("programPacketTemplate")}
              value={validation.values.programPacketTemplateId}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "programPacketTemplateId",
                )
              }
              isMulti={false}
              options={programPacketOptions}
              error={validation.errors["programPacketTemplateId"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
