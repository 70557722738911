import React, { useEffect } from "react";
import { Card, CardBody, Form, FormFeedback } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { getInstallmentPlanListData } from "../../store/installmentPlans/action";
import { createInstallmentPlanSchema } from "../../models/installmentPlans";
import {
  handleInputChange,
  handleNumberInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import FormTextField from "Components/Entity/FormTextField";
import FormNumberField from "Components/Entity/FormNumberField";
import { SingleInstallment } from "Components/Entity/SingleInstallment/SingleInstallment";
import { postInstallmentPlanPromise } from "helpers/API/core-service/cs_backend_helper";
import { getPointOfSellListData } from "store/pointOfSell/action";
import { toast_error } from "helpers/toast_helper";
import { CustomFormFeedback } from "Components/Common/CustomFormFeedback/CustomFormFeedback";

const AddInstallmentPlan = ({ visibility, toggle }) => {
  const dispatch = useDispatch();

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: "",
      availabilityDays: "",
      installmentCount: "",
      installments: [],
    },
    validationSchema: createInstallmentPlanSchema,
    onSubmit: (values) => {
      dispatch(
        postInstallmentPlanPromise({
          name: values.name,
          availabilityDays: values.availabilityDays,
          installmentCount: values.installmentCount,
          installments: values.installments,
        })
          .then(() => {
            dispatch(getInstallmentPlanListData());
            toggle(false);
          })
          .catch((e) => {
            const errorGroups = {};
            const targetArray = [];

            if (e.data?.fields) {
              e.data.fields.forEach((item) => {
                const [installment, error] = item.name.split(".");
                const errorType = error.replace("[", "").replace("]", "");

                if (!errorGroups[installment]) {
                  errorGroups[installment] = [];
                }
                if (!errorGroups[installment][errorType]) {
                  errorGroups[installment][errorType] = [];
                }
                errorGroups[installment][errorType].push(item);
              });

              errorGroups.installments.forEach((installment, index) => {
                const obj = {};
                installment.forEach((error) => {
                  obj[error.name.split("].")[1]] = error.message;
                });
                targetArray.push(obj);
              });

              setValidationErrors(validation, {
                ...validation.errors,
                installments: targetArray,
              });
            }

            toast_error(e.data?.message || e?.message || e?.error || e);
          }),
      );
    },
  });

  useEffect(() => {
    dispatch(getPointOfSellListData());
    validation.setFieldValue("installmentCount", 2);
    match(2);
  }, []);

  // Form validation (api)
  useEffect(() => {
    if (validation.errors == null) {
      return;
    }
    setValidationErrors(validation, validation.errors);
  }, [validation.errors]);

  const renderInstallmentsToAdd = () => {
    const installments = [];
    for (let i = 0; i < parseInt(validation.values.installmentCount); i++) {
      installments.push(
        <SingleInstallment key={i} index={i} validation={validation} />,
      );
    }
    return installments;
  };

  const { installments, ...otherErrors } = validation.errors;
  const installmentErrors = Array.isArray(installments)
    ? installments.filter((item) => item && JSON.stringify(item) !== "{}")
    : [];
  const installmentError = Array.isArray(installments) ? null : installments;

  const match = (val) => {
    let arr = [];
    for (let index = 0; index < val && index < 6; index++) {
      if (validation.values.installments[index]) {
        arr.push(validation.values.installments[index]);
      } else {
        arr.push({
          dueDays: "",
          currency: "",
          type: "",
          pointOfSell: "",
        });
      }
    }
    validation.setFieldValue("installments", arr);
    validation.setFieldError("installments", []);
  };

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("installment_plans_add_new_installment_plan")}
        formId={"add-installment-plan"}
        validationRule={
          Object.keys(otherErrors).length === 0 &&
          installmentErrors.length === 0
        }
        buttonLabel={getString("add")}
      >
        <Form
          id="add-installment-plan"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("name")}
                name={"name"}
                value={validation.values["name"]}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors["name"]}
                touched={validation.touched["name"]}
              />
              <FormNumberField
                label={getString("availabilityDays")}
                name="availabilityDays"
                value={validation.values.availabilityDays}
                onChange={(e) =>
                  handleNumberInputChange(validation, e, "availabilityDays")
                }
                error={validation.errors.availabilityDays}
                touched={validation.touched.availabilityDays}
              />
              <FormNumberField
                label={getString("installmentCount")}
                name="installmentCount"
                value={validation.values.installmentCount}
                onChange={(e) => {
                  handleNumberInputChange(validation, e, "installmentCount");
                  match(e.target.value);
                }}
                error={validation.errors.installmentCount}
                touched={validation.touched.installmentCount}
              />
              {!validation.errors["installmentCount"] &&
                renderInstallmentsToAdd()}
              {installmentError && (
                <CustomFormFeedback>{installmentError}</CustomFormFeedback>
              )}
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddInstallmentPlan;
