import classNames from "classnames";
import { FormattedString } from "../FormattedString";
import "./NewValuePlaceholder.scss";

export const NewValuePlaceholder = ({
  addStringId = "add_new_value",
  disabled = false,
  onClick,
}) => {
  return (
    <button
      color="transparent"
      className={classNames([
        "new-value-placeholder",
        disabled && "new-value-placeholder--disabled",
      ])}
      disabled={disabled}
      onClick={onClick}
    >
      <span className="new-value-placeholder__icon">+</span>
      <FormattedString id={addStringId} />
    </button>
  );
};
