// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-id-cell {
  font-size: 13px;
  color: #4b38b3;
  font-weight: 500;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/CustomIdCell/CustomIdCell.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;EACA,gBAAA;EACA,eAAA;AACF","sourcesContent":[".custom-id-cell {\n  font-size: 13px;\n  color: #4b38b3;\n  font-weight: 500;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
