import { useFormik } from "formik";
import { definePricesForPacketInstallment } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import { handleInputChange } from "helpers/validation_helper";
import { pricesForPacketInstallmentSchema } from "models/pricesForPacketInstallments";
import { useContext } from "react";
import formatter from "sprintf-js";

import { Card, CardBody, Form, Label } from "reactstrap";
import { ProgramDetailsContext } from "../../ProgramDetails";
import FormNumberField from "Components/Entity/FormNumberField";
import { FormattedString, getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const InstallmentManagementCanvas = ({
  installmentPlan,
  visible,
  setVisible,
  programPacket,
  matchedPrice,
}) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      installmentPlanId: installmentPlan.id,
      prices: installmentPlan.installments.map((installment, index) => ({
        installmentId: installment.id,
        price:
          matchedPrice?.prices?.length > 0
            ? matchedPrice.prices[index].price
            : "",
      })),
    },
    validationSchema: pricesForPacketInstallmentSchema,
    onSubmit: (values) => {
      definePricesForPacketInstallment(programData.id, programPacket.id, values)
        .then(() => {
          toast_success(getString("prices_defined"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message));
    },
  });

  const { installments, ...details } = installmentPlan;

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={formatter.sprintf(
        getString("installment_prices_manage"),
        programPacket.name,
      )}
      formId="manage-installments"
      validationRule={true}
      buttonLabel={getString("save")}
    >
      <Card className="installment-management-canvas">
        <CardBody className="card-body">
          <div className="mb-3">
            <Label>
              <FormattedString id="installment_plan_details" />:
            </Label>
            <DetailsBox item={details} />
          </div>
          <Form
            id="manage-installments"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            {Array.from(
              { length: installmentPlan.installments.length },
              (_, i) => (
                <FormNumberField
                  key={i}
                  label={
                    <>
                      {`${getString("installment")} ${i + 1}`}{" "}
                      <span style={{ color: "#878a99", fontWeight: "400" }}>
                        {installments[i].currency}
                        {i !== 0 && ","}
                      </span>{" "}
                      {i !== 0 && (
                        <span style={{ color: "#878a99", fontWeight: "400" }}>
                          {installments[i].dueDays} {getString("days")}
                        </span>
                      )}
                    </>
                  }
                  name={`prices[${i}].price`}
                  value={validation.values.prices[i]?.price}
                  onChange={(e) =>
                    handleInputChange(validation, e, `prices[${i}].price`)
                  }
                  error={validation.errors.prices?.[i]?.price}
                  touched={validation.touched.prices?.[i]?.price}
                />
              ),
            )}
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
