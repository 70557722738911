import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getMeetingPointListData,
  editMeetingPoint,
} from "../../store/meetingPoints/action";
import { createMeetingPointSchema } from "../../models/meetingPoints";
import {
  handleInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";

const EditMeetingPoint = ({ meetingPoint, toggle, visibility }) => {
  const dispatch = useDispatch();

  const { formValidationErrors, loading } = useSelector(
    (rootState) => rootState.MeetingPoints,
  );

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      address: meetingPoint?.address || "",
      city: meetingPoint?.city || "",
      mapUrl: meetingPoint?.mapUrl || "",
    },
    validationSchema: createMeetingPointSchema,
    onSubmit: (values) => {
      dispatch(
        editMeetingPoint(meetingPoint.id, values, () => {
          dispatch(getMeetingPointListData());
          toggle(false);
        }),
      );
    },
  });

  const [prevValidationErrors, setPrevValidationErrors] = useState();

  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (
      prevValidationErrors !== null &&
      prevValidationErrors !== formValidationErrors
    ) {
      setValidationErrors(validation, formValidationErrors);
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("meeting_points_edit")}
        formId={"edit-meeting-point"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel="Save"
      >
        <Form
          id="edit-meeting-point"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              {["address", "city", "mapUrl"].map((field) => (
                <FormTextField
                  key={field}
                  label={getString(field)}
                  name={field}
                  value={validation.values[field]}
                  onChange={(e) => handleInputChange(validation, e, field)}
                  error={validation.errors[field]}
                  touched={validation.touched[field]}
                />
              ))}
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditMeetingPoint;
