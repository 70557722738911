import React from "react";
import { Input, Label, FormFeedback } from "reactstrap";

const FormTextField = ({
  label,
  name,
  value,
  onChange,
  error,
  type = "text",
}) => {
  return (
    <div className="mb-3">
      <Label htmlFor={name} className="form-label">
        {label}
      </Label>
      <Input
        type={type}
        className="form-control"
        id={name}
        name={name}
        onChange={(e) => onChange(e, name)}
        value={value || ""}
        invalid={!!error}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};

export default FormTextField;
