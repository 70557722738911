import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const createHotelSchema = Yup.object({
  name: Yup.string().required(getStringSync("enter_hotel")).min(5).max(255),
  address: Yup.string()
    .required(getStringSync("enter_hotel_address"))
    .min(5)
    .max(255),
  zipCode: Yup.string()
    .required(getStringSync("enter_zip_code"))
    .min(5)
    .max(10),
  city: Yup.string()
    .required(getStringSync("enter_city"))
    .min(2, getStringSync("invalid_city_name"))
    .max(255, getStringSync("invalid_city_name")),
  mapUrl: Yup.string()
    .required(getStringSync("enter_map_url"))
    .min(12, getStringSync("map_url_too_short"))
    .max(255),
  contactInfo: Yup.string()
    .required(getStringSync("enter_contact_info"))
    .min(5)
    .max(255),
});

export { createHotelSchema };
