import "./SingleHotel.scss";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { Link } from "react-router-dom";
import { DeleteHotelModal } from "../../../modals/DeleteHotelModal";
import { useContext, useState } from "react";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";

export const SingleHotel = ({ hotel }) => {
  const { canUpdateProgram } = useContext(ProgramDetailsContext);
  const [deleteHotelModalVisible, setDeleteHotelModalVisible] = useState(false);

  return (
    <div className="d-flex align-items-center pb-2 single-hotel">
      <div className="flex-shrink-0 me-2">
        <i className="mdi mdi-office-building text-muted single-hotel__icon"></i>
      </div>
      <div className="flex-grow-1">
        <div>
          <h5 className="fs-14 mb-1">
            <Link
              className="single-hotel__mapurl"
              to={hotel.mapUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {hotel.name}
            </Link>
          </h5>
          <div className="fs-13 text-muted mb-0">
            <div className="single-hotel__address">
              <div className="single-hotel__address-bar">{`${hotel.zipCode} ${hotel.city}, ${hotel.address}`}</div>
            </div>
          </div>
        </div>
      </div>

      {canUpdateProgram && (
        <ActionButtons
          onDelete={() => setDeleteHotelModalVisible(true)}
          itemId={hotel.id}
        />
      )}

      {deleteHotelModalVisible && (
        <DeleteHotelModal
          itemId={hotel.id}
          visible={deleteHotelModalVisible}
          setVisible={setDeleteHotelModalVisible}
        />
      )}
    </div>
  );
};
