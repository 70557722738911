import React, { createContext, useState, useContext } from "react";

const PopupContext = createContext();

export const usePopup = () => useContext(PopupContext);

export const PopupProvider = ({ children }) => {
  const [popup, setPopup] = useState({ visible: false, message: "" });

  const showPopup = (message) => {
    setPopup({ visible: true, message });
  };

  const hidePopup = () => {
    setPopup({ visible: false, message: "" });
  };

  return (
    <PopupContext.Provider value={{ popup, showPopup, hidePopup }}>
      {children}
    </PopupContext.Provider>
  );
};
