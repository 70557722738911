import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_USER_LIST_DATA,
  POST_USER,
  EDIT_USER,
  DELETE_USER,
  GET_BUSINESS_UNIT_LIST_DATA,
} from "./actionType";

// common success
export const usersApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const usersApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

// business units list
export const getBusinessUnitListData = () => ({
  type: GET_BUSINESS_UNIT_LIST_DATA,
  payload: {},
});

// users list
export const getUserListData = () => ({
  type: GET_USER_LIST_DATA,
  payload: {},
});

// user add
export const postUser = (data, onSuccess) => ({
  type: POST_USER,
  payload: { data, onSuccess },
});

// user edit
export const editUser = (id, data, onSuccess) => ({
  type: EDIT_USER,
  payload: { id, data, onSuccess },
});

// user delete
export const deleteUser = (id, onSuccess) => ({
  type: DELETE_USER,
  payload: { id, onSuccess },
});
