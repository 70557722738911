import axios from "axios";
import { apiErrorCodes, apiErrorMessages } from "../../config";
import { toast_error } from "../toast_helper";
import eventEmitter from "utils/eventEmitter";
import { getString } from "Components/Common/FormattedString";

// access token
class APIClient {
  instance;

  constructor(apiUrl) {
    this.instance = axios.create({
      baseURL: apiUrl,
    });

    // set interceptors
    this.instance?.interceptors.response.use(
      function (response) {
        return response.data ? response.data : response;
      },
      function (error) {
        let message;
        switch (error.response?.status) {
          case apiErrorCodes.FORM_VALIDATION:
            message = apiErrorMessages.FORM_VALIDATION;
            toast_error("[API] Form validation error.");
            break;
          case apiErrorCodes.UNAUTHORIZED:
          case apiErrorCodes.FORBIDDEN:
            message = apiErrorMessages.FORBIDDEN;
            toast_error("[API] Insufficient permissions.");
            window.location.href = "/access_denied";
            break;
          case apiErrorCodes.NOT_FOUND:
            message = apiErrorMessages.NOT_FOUND;
            window.location.href = "/not_found";
            break;
          case apiErrorCodes.TOKEN_EXPIRED:
            alert("xd");
            toast_error(getString("you_have_been_logged_out"));
            break;
          case apiErrorCodes.INTERNAL_SERVER_ERROR:
          default:
            if (error.response?.status >= 400 && error.response?.status < 500) {
              message = apiErrorMessages.CONFLICT;
              eventEmitter.emit(
                "showPopup",
                error.response?.status,
                error.response?.data?.message || message,
              );
            } else if (!error.response) {
              // Handle network errors
              message = "Network Error";
              toast_error("[API] Network error.");
              window.location.href = "/internal_server_error";
            } else if (error.response.status >= 500) {
              message = apiErrorMessages.INTERNAL_SERVER_ERROR;
              if (error.config?.method?.toLowerCase() === "get") {
                window.location.href = "/internal_server_error";
              } else {
                eventEmitter.emit(
                  "showPopup",
                  error.response?.status,
                  error.response?.data?.message || message,
                );
              }
            } else {
              message = error.message;
              toast_error(`[API] ${message}`);
            }
        }
        return Promise.reject({
          code: error.response?.status,
          message: message,
          data: error.response?.data,
        });
      },
    );

    // in case of reload, check if token exist to set Authorization header
    const user = getLoggedInUser();
    if (user) {
      this.setAuthorization(user.accessToken);
    }
  }

  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = this.instance.get(`${url}?${queryString}`, params);
    } else {
      response = this.instance.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return this.instance.post(url, data, {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
    });
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return this.instance.patch(url, data);
  };

  put = (url, data) => {
    return this.instance.put(url, data);
  };

  /**
   * Delete
   */
  delete = (url, config) => {
    return this.instance.delete(url, { ...config });
  };

  setAuthorization = (token) => {
    this.instance.defaults.headers["Authorization"] = token;
  };
}

const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

// is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

export { APIClient, getLoggedInUser, isUserAuthenticated };
