import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const UserDetails = ({ itemId, toggle, visible }) => {
  const { usersData } = useSelector((rootState) => rootState.Users);

  const item = usersData.find((user) => user.id === itemId);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="user-details-modal"
      title={getString("user_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
