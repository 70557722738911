import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_ATTACHMENT_LIST_DATA,
  POST_ATTACHMENT,
  EDIT_ATTACHMENT,
  ARCHIVE_ATTACHMENT,
  RESTORE_ATTACHMENT,
} from "./actionType";
import { attachmentsApiSuccess, attachmentsApiError } from "./action";

//Include Both Helper File with needed methods
import {
  getAttachmentList,
  postAttachment,
  editAttachment,
  archiveAttachment,
  restoreAttachment,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getAttachmentListData({ payload: data }) {
  try {
    let response;
    response = yield call(getAttachmentList, data);

    yield put(attachmentsApiSuccess(GET_ATTACHMENT_LIST_DATA, response));
  } catch (error) {
    yield put(attachmentsApiError(GET_ATTACHMENT_LIST_DATA, error));
  }
}

function* createAttachment({ payload: attachment }) {
  try {
    const response = yield call(
      postAttachment,
      attachment.data,
      attachment.onSuccess,
    );
    yield put(attachmentsApiSuccess(POST_ATTACHMENT, response));
    toast_success("Attachment added successfully");
  } catch (error) {
    yield put(attachmentsApiError(POST_ATTACHMENT, error));
    toast_error(error.data.message);
  }
}

function* editAttachmentEntry({ payload: attachment }) {
  try {
    const response = yield call(
      editAttachment,
      attachment.id,
      attachment.data,
      attachment.onSuccess,
    );
    yield put(attachmentsApiSuccess(EDIT_ATTACHMENT, response));
    toast_success("Attachment edited successfully");
  } catch (error) {
    yield put(attachmentsApiError(EDIT_ATTACHMENT, error));
    toast_error(error.data.message);
  }
}

function* archiveAttachmentEntry({ payload: attachment }) {
  try {
    const response = yield call(
      archiveAttachment,
      attachment.id,
      attachment.onSuccess,
    );
    yield put(attachmentsApiSuccess(ARCHIVE_ATTACHMENT, response));
    toast_success("Attachment removed");
  } catch (error) {
    yield put(attachmentsApiError(ARCHIVE_ATTACHMENT, error));
    toast_error(error.data.message);
  }
}

function* restoreAttachmentEntry({ payload: attachment }) {
  try {
    const response = yield call(
      restoreAttachment,
      attachment.id,
      attachment.onSuccess,
    );
    yield put(attachmentsApiSuccess(RESTORE_ATTACHMENT, response));
    toast_success("Attachment restored");
  } catch (error) {
    yield put(attachmentsApiError(RESTORE_ATTACHMENT, error));
    toast_error(error.data.message);
  }
}

export function* watchGetAttachmentListData() {
  yield takeEvery(GET_ATTACHMENT_LIST_DATA, getAttachmentListData);
}

export function* watchPostAttachment() {
  yield takeEvery(POST_ATTACHMENT, createAttachment);
}
export function* watchEditAttachment() {
  yield takeEvery(EDIT_ATTACHMENT, editAttachmentEntry);
}
export function* watchArchiveAttachment() {
  yield takeEvery(ARCHIVE_ATTACHMENT, archiveAttachmentEntry);
}
export function* watchRestoreAttachment() {
  yield takeEvery(RESTORE_ATTACHMENT, restoreAttachmentEntry);
}

function* attachmentsSaga() {
  yield all([
    fork(watchGetAttachmentListData),
    fork(watchPostAttachment),
    fork(watchEditAttachment),
    fork(watchArchiveAttachment),
    fork(watchRestoreAttachment),
  ]);
}

export default attachmentsSaga;
