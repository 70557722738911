import React from "react";
import "./GenderCell.scss";

const GenderCell = ({ genders }) => {
  return (
    <div className="gender-cell">
      {genders && genders.length > 0
        ? genders.map((gender, index) => {
            const genderClass =
              gender.toLowerCase() === "male" ? "male" : "female";
            const displayLetter = gender.charAt(0).toUpperCase();

            return (
              <div
                className={`gender-cell__item gender-cell__item--${genderClass}`}
                key={index}
              >
                {displayLetter}
              </div>
            );
          })
        : "-"}
    </div>
  );
};

export default GenderCell;
