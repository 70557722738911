import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { ProgramPacketTemplatesList } from "./ProgramPacketTemplatesList";
import AddProgramPacketTemplate from "./AddProgramPacketTemplate";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const ProgramPacketTemplates = () => {
  document.title = getString("program_packet_templates_page_title");
  const [programPacketTemplateAddVisible, setProgramPacketTemplateAddVisible] =
    useState(false);
  const toggleProgramPacketTemplateAdd = () => {
    setProgramPacketTemplateAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("program_packet_templates_list")}
                  actionBtnLabel={getString("program_packet_template_sm")}
                  actionBtn={toggleProgramPacketTemplateAdd}
                  actionBtnPermission={"PROGRAM_PACKET_TEMPLATE_CREATE"}
                >
                  <ProgramPacketTemplatesList />
                </EntityCardList>
              </div>
            </Col>
            {programPacketTemplateAddVisible && (
              <AddProgramPacketTemplate
                visibility={programPacketTemplateAddVisible}
                toggle={toggleProgramPacketTemplateAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProgramPacketTemplates;
