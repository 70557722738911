export const mockPaymentMethod = {
  id: "20206959-12f7-470f-ad8d-271eaed4a619",
  name: "Przelewy24",
  provider: "Przelewy24",
  businessUnit: {
    id: "519d1b48-ef1e-48cc-8e1d-8c562b47f45d",
    name: "Poland",
    email: "test@angloville.pl",
    website: "https://angloville.pl",
    currency: "PLN",
  },
};

export const mockPaymentMethod2 = {
  id: "20206959-12f7-470f-ad8d-271eaed4a612",
  name: "PayU",
  provider: "PayU",
  businessUnit: {
    id: "519d1b48-ef1e-48cc-8e1d-8c562b47f45d",
    name: "Poland",
    email: "test@angloville.pl",
    website: "https://angloville.pl",
    currency: "PLN",
  },
};
