import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const createAttachmentSchema = Yup.object({
  file: Yup.string().required(getStringSync("upload_file")),
  name: Yup.string()
    .min(4, "Minimum 4 characters required")
    .required(getStringSync("file_name_required")),
  businessUnitId: Yup.string().required(
    getStringSync("business_unit_required"),
  ),
});

const editAttachmentSchema = Yup.object({
  name: Yup.string()
    .min(4, "Minimum 4 characters required")
    .required(getStringSync("file_name_required")),
  businessUnitId: Yup.string().required(
    getStringSync("business_unit_required"),
  ),
});

export { createAttachmentSchema, editAttachmentSchema };
