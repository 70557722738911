import { FormattedString } from "Components/Common/FormattedString";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import { useContext } from "react";

export const BillingData = () => {
  const {
    customerData: { payerDetails },
  } = useContext(CustomerDetailsContext);

  const firstOrDefault = payerDetails[0] || {};

  if (!firstOrDefault) {
    return (
      <div className="billing-data">
        <FormattedString id="billing_data_not_available" />
      </div>
    );
  }

  return (
    <div className="billing-data">
      <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
        {firstOrDefault.companyName ? (
          <>
            <li className="fw-medium fs-14">
              {firstOrDefault.companyName || "N/A"}
            </li>
            <li>{firstOrDefault.taxNumber || "N/A"}</li>
          </>
        ) : (
          <>
            <li className="fw-medium fs-14">
              {firstOrDefault.firstName || "N/A"}{" "}
              {firstOrDefault.lastName || "N/A"}
            </li>
          </>
        )}
        <li>{firstOrDefault.address || "N/A"}</li>
        <li>
          {firstOrDefault.zipCode || "N/A"} - {firstOrDefault.city || "N/A"}
        </li>
      </ul>
    </div>
  );
};
