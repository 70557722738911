import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import CustomIdCell from "./cells/CustomIdCell/CustomIdCell";
import InstallmentsCell from "./cells/InstallmentsCell/InstallmentsCell";
import { format } from "date-fns";
// import "./ApplicationsList.scss";
import PackageCell from "./cells/PackageCell/PackageCell";
import GenderCell from "./cells/GenderCell/GenderCell";
import ParticipantNameCell from "./cells/ParticipantNameCell/ParticipantNameCell";
import ParticipantAgeCell from "./cells/ParticipantAgeCell/ParticipantAgeCell";
import InternalCommentCell from "./cells/InternalCommentCell/InternalCommentCell";
// import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { CustomerDetailsContext } from "pages/Customers/CustomerDetails/CustomerDetails";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";

export const CustomerApplications = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const initialSearchTerm = searchParams.get("searchTerm") || "";
  const [globalFilter, setGlobalFilter] = useState(initialSearchTerm);
  const isInitialRender = useRef(true);

  const {
    customerApplications,
    refreshCustomerApplications,
    customerApplicationsLoading,
  } = useContext(CustomerDetailsContext);

  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }

    const params = {};
    if (globalFilter) {
      params.searchTerm = globalFilter;
    }
    if (pageNumber > 1) {
      params.page = pageNumber.toString();
    }
    setSearchParams(params);
  }, [pageNumber, globalFilter]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: (row) => (
          <CustomIdCell
            customId={row.customId}
            to={`/pp-application/${row.id}`}
          />
        ),
      },
      {
        Header: getString("status"),
        accessor: "applicationStatus",
        Cell: ({ value }) => (
          <div className="text-nowrap">
            <FormattedString id={value} />
          </div>
        ),
      },
      {
        Header: getString("installments_short"),
        accessor: (d) => (
          <InstallmentsCell
            installmentsPaid={d.installmentsPaid}
            installmentsCount={d.installmentsCount}
          />
        ),
      },
      {
        Header: getString("program"),
        accessor: "programName",
      },
      {
        Header: getString("week"),
        accessor: "week",
      },
      {
        Header: getString("date"),
        accessor: (d) => (
          <div>
            <div>{format(new Date(d.dateStart), "yyyy-MM-dd")} - </div>
            <div>{format(new Date(d.dateEnd), "yyyy-MM-dd")}</div>
          </div>
        ),
      },
      {
        Header: getString("package"),
        accessor: (d) => (
          <PackageCell
            packages={d.participants?.map((p) => p.programPacketName)}
          />
        ),
      },
      {
        Header: getString("age"),
        accessor: (d) => (
          <ParticipantAgeCell ages={d.participants?.map((p) => p.age)} />
        ),
      },
      {
        Header: getString("gender"),
        accessor: (d) => (
          <GenderCell genders={d.participants?.map((p, index) => p.gender)} />
        ),
      },
      {
        Header: getString("participant_name"),
        accessor: (d) => (
          <ParticipantNameCell
            names={d.participants?.map((p) => p.firstName + " " + p.lastName)}
          />
        ),
      },
    ],
    [],
  );

  return customerApplications ? (
    <div className="applications-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={customerApplications.count}
        currentPage={customerApplications.currentPage}
        totalPages={customerApplications.totalPages}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        onPageChange={(page) => refreshCustomerApplications(page)}
        loading={customerApplicationsLoading}
        data={customerApplications.data || []}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("applications_search_for")}
      />
    </div>
  ) : (
    <PreloaderWrap />
  );
};
