import React from "react";
import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const UpsellDetails = ({ itemId, toggle, visible }) => {
  const { upsellsData } = useSelector((rootState) => rootState.Upsells);

  const item = upsellsData.find((upsell) => upsell.id === itemId);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="upsell-details-modal"
      title={getString("upsell_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
