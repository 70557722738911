import "./HotelView.scss";
import { SingleHotel } from "./SingleHotel/SingleHotel";

export const HotelView = ({ hotel }) => {
  return (
    <div className="hotel-view">
      <SingleHotel hotel={hotel} />
    </div>
  );
};
