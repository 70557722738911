import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_POINT_OF_SELL_LIST_DATA,
} from "./actionType";

export const pointOfSellApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const pointOfSellApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPointOfSellListData = () => ({
  type: GET_POINT_OF_SELL_LIST_DATA,
  payload: {},
});
