import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_APPLICATIONS_LIST_DATA,
  SET_CURRENT_PAGE,
} from "./actionType";

export const applicationsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const applicationsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getApplicationsListData = (page = 1, searchTerm = "") => ({
  type: GET_APPLICATIONS_LIST_DATA,
  payload: { page, searchTerm },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
