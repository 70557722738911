import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { CoordinatorsList } from "./CoordinatorsList";
import AddCoordinator from "./AddCoordinator";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const Coordinators = () => {
  document.title = getString("coordinators_page_title");
  const [coordinatorAddVisible, setCoordinatorAddVisible] = useState(false);
  const toggleCoordinatorAdd = () => {
    setCoordinatorAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("coordinators_list")}
                  actionBtnLabel={getString("coordinator_sm")}
                  actionBtn={toggleCoordinatorAdd}
                  actionBtnPermission={"COORDINATOR_CREATE"}
                >
                  <CoordinatorsList />
                </EntityCardList>
              </div>
            </Col>
            {coordinatorAddVisible && (
              <AddCoordinator
                visibility={coordinatorAddVisible}
                toggle={toggleCoordinatorAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Coordinators;
