import { useState } from "react";
import "./SingleAttachment.scss";

import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { toast_info } from "helpers/toast_helper";
import { Link } from "react-router-dom";
import ArchiveAttachment from "./ArchiveAttachment";

export const SingleAttachment = ({
  attachment,
  programPacketTemplate,
  removable = true,
}) => {
  const [archiveAttachmentVisible, setArchiveAttachmentVisible] =
    useState(false);

  return (
    <div className="single-attachment">
      <Link to={attachment.filePath}>
        <i className="las la-paperclip"></i> {attachment.name}
      </Link>
      <div className="d-flex gap-2 align-items-center">
        <AngloTooltip stringId="copy_to_clipboard">
          <button
            type="button"
            className="btn btn-sm btn-secondary btn-icon"
            onClick={() => {
              navigator.clipboard.writeText(attachment.filePath);
              toast_info("Path copied to clipboard");
            }}
          >
            <i className="ri-file-copy-line"></i>
          </button>
        </AngloTooltip>
        <AngloTooltip stringId="download">
          <Link to={attachment.filePath}>
            <button type="button" className="btn btn-sm btn-secondary btn-icon">
              <i className="ri-download-line"></i>
            </button>
          </Link>
        </AngloTooltip>
        {removable && (
          <AngloTooltip stringId="delete_attachment">
            <button
              type="button"
              className="btn btn-sm btn-secondary btn-icon btn-danger"
              onClick={() => {
                setArchiveAttachmentVisible(true);
              }}
            >
              <i className="ri-delete-bin-line"></i>
            </button>
          </AngloTooltip>
        )}
      </div>
      {archiveAttachmentVisible && (
        <ArchiveAttachment
          visibility={archiveAttachmentVisible}
          toggle={() => setArchiveAttachmentVisible((p) => !p)}
          programPacketTemplateId={programPacketTemplate.id}
          attachmentId={attachment.id}
        />
      )}
    </div>
  );
};
