import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_INSTALLMENT_PLAN_LIST_DATA,
  POST_INSTALLMENT_PLAN,
  DELETE_INSTALLMENT_PLAN,
} from "./actionType";

export const installmentPlansApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const installmentPlansApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getInstallmentPlanListData = () => ({
  type: GET_INSTALLMENT_PLAN_LIST_DATA,
  payload: {},
});

export const postInstallmentPlan = (data, onSuccess) => ({
  type: POST_INSTALLMENT_PLAN,
  payload: { data, onSuccess },
});

export const deleteInstallmentPlan = (id, onSuccess) => ({
  type: DELETE_INSTALLMENT_PLAN,
  payload: { id, onSuccess },
});
