import React, { useContext } from "react";
import { Modal } from "reactstrap";

import { getString } from "Components/Common/FormattedString";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";
import { detachUpsellFromProgram } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";

export const DeletePpUpsellModal = ({ itemId, visible, setVisible }) => {
  const toggle = () => {
    setVisible(!visible);
  };
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteUpsellEntry = () => {
    detachUpsellFromProgram(programData.id, "participants", itemId)
      .then(() => {
        toast_success(getString("upsell_detached"));
        toggle(false);
        refreshProgramData();
      })
      .catch((e) => toast_error(e.message));
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString("upsell_sm")}
        toggle={toggle}
        deleteAction={() => deleteUpsellEntry()}
      />
    </Modal>
  );
};
