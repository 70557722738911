import { rolePermissions } from "permissions";
import { useSelector } from "react-redux";

export const WithPermission = ({ children, permissionName }) => {
  const roles = useSelector((state) => state.Profile.user.roles);

  const hasPermission = roles?.some((role) => {
    const permissions = rolePermissions[role];
    return permissions === "*" || permissions?.includes(permissionName);
  });

  if (hasPermission || !permissionName) {
    return children;
  }
};
