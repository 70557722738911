// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upsell-equation {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  align-items: center;
  display: flex;
  gap: 4px;
}
.upsell-equation__val--notPaid {
  color: #ff0000;
}
.upsell-equation__val--partlyPaid {
  color: #eaaa00;
}
.upsell-equation__val--fullyPaid {
  color: #45cb85;
}
.upsell-equation__val--sum {
  color: #299cdb;
}
.upsell-equation--centered {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/UpsellEquation/UpsellEquation.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,gCAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;AACF;AAEI;EACE,cAAA;AAAN;AAEI;EACE,cAAA;AAAN;AAEI;EACE,cAAA;AAAN;AAEI;EACE,cAAA;AAAN;AAIE;EACE,uBAAA;AAFJ","sourcesContent":[".upsell-equation {\n  margin-bottom: 0;\n  font-family: \"Inter\", sans-serif;\n  align-items: center;\n  display: flex;\n  gap: 4px;\n\n  &__val {\n    &--notPaid {\n      color: #ff0000;\n    }\n    &--partlyPaid {\n      color: #eaaa00;\n    }\n    &--fullyPaid {\n      color: #45cb85;\n    }\n    &--sum {\n      color: #299cdb;\n    }\n  }\n\n  &--centered {\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
