// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simplest-table th,
.simplest-table td {
  white-space: normal;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/SimplestTable/SimplestTable.scss"],"names":[],"mappings":"AACE;;EAEE,mBAAA;AAAJ","sourcesContent":[".simplest-table {\n  th,\n  td {\n    white-space: normal;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
