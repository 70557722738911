// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.package-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-right: 32px;
}
.package-cell__item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
}
.package-cell__item--teen {
  background: #ff6f61;
  color: #ffffff;
}
.package-cell__item--adult {
  background: #6b5b95;
  color: #ffffff;
}
.package-cell__item--family {
  background: #88b04b;
  color: #ffffff;
}
.package-cell__item--senior {
  background: #ffb347;
  color: #ffffff;
}
.package-cell__item--junior {
  background: #45cb85;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/PackageCell/PackageCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EACA,kBAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACJ;AACI;EACE,mBAAA;EACA,cAAA;AACN;AAEI;EACE,mBAAA;EACA,cAAA;AAAN;AAGI;EACE,mBAAA;EACA,cAAA;AADN;AAII;EACE,mBAAA;EACA,cAAA;AAFN;AAKI;EACE,mBAAA;EACA,cAAA;AAHN","sourcesContent":[".package-cell {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n  margin-right: 32px;\n\n  &__item {\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 0 6px;\n\n    &--teen {\n      background: #ff6f61;\n      color: #ffffff;\n    }\n\n    &--adult {\n      background: #6b5b95;\n      color: #ffffff;\n    }\n\n    &--family {\n      background: #88b04b;\n      color: #ffffff;\n    }\n\n    &--senior {\n      background: #ffb347;\n      color: #ffffff;\n    }\n\n    &--junior {\n      background: #45cb85;\n      color: #ffffff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
