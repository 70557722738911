import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { getHotelListData, editHotel } from "../../store/hotels/action";
import { createHotelSchema } from "../../models/hotels";
import {
  handleInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";

const EditHotel = ({ hotel, toggle, visibility }) => {
  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.Hotels;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    isHotelEdited: state.isHotelEdited,
    error: state.error,
    loading: state.loading,
    formValidationErrors: state.formValidationErrors,
  }));
  const { loading, formValidationErrors } = useSelector(selectLayoutProperties);

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: hotel?.name || "",
      address: hotel?.address || "",
      zipCode: hotel?.zipCode || "",
      city: hotel?.city || "",
      mapUrl: hotel?.mapUrl || "",
      contactInfo: hotel?.contactInfo || "",
    },
    validationSchema: createHotelSchema,
    onSubmit: (values) => {
      dispatch(
        editHotel(hotel.id, values, () => {
          dispatch(getHotelListData());
          toggle(null, false);
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("hotels_edit") + " " + hotel?.name || ""}
        formId={"edit-hotel"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-hotel"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              {[
                "name",
                "address",
                "zipCode",
                "city",
                "mapUrl",
                "contactInfo",
              ].map((field) => (
                <FormTextField
                  key={field}
                  label={getString(field)}
                  name={field}
                  value={validation.values[field]}
                  onChange={(e) => handleInputChange(validation, e, field)}
                  error={validation.errors[field]}
                  touched={validation.touched[field]}
                />
              ))}
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditHotel;
