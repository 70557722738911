import { PROFILE_ERROR, PROFILE_SUCCESS, FETCH_PROFILE } from "./actionTypes";

export const fetchUserProfile = (user) => {
  return {
    type: FETCH_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = (actionType, data) => {
  return {
    type: PROFILE_SUCCESS,
    payload: { actionType, data },
  };
};

export const profileError = (actionType, data) => {
  return {
    type: PROFILE_ERROR,
    payload: { actionType, data },
  };
};
