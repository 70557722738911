import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_UPSELL_LIST_DATA,
  POST_UPSELL,
  EDIT_UPSELL,
  ARCHIVE_UPSELL,
  RESTORE_UPSELL,
} from "./actionType";

export const upsellsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const upsellsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getUpsellListData = () => ({
  type: GET_UPSELL_LIST_DATA,
  payload: {},
});

export const postUpsell = (data, onSuccess) => ({
  type: POST_UPSELL,
  payload: { data, onSuccess },
});
export const editUpsell = (id, data, onSuccess) => ({
  type: EDIT_UPSELL,
  payload: { id, data, onSuccess },
});

export const archiveUpsell = (id, onSuccess) => ({
  type: ARCHIVE_UPSELL,
  payload: { id, onSuccess },
});

export const restoreUpsell = (id, onSuccess) => ({
  type: RESTORE_UPSELL,
  payload: { id, onSuccess },
});
