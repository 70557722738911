import React, { useContext, useEffect, useState } from "react";
import "./AddInstallmentPlanCanvas.scss";

import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { addInstallmentPlan } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { getInstallmentPlanListData } from "store/installmentPlans/action";
import { toast_error, toast_success } from "helpers/toast_helper";
import { SimplestTable } from "Components/Common/SimplestTable/SimplestTable";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { DeleteInstallmentPlanModal } from "./DeleteInstallmentPlanModal";

export const AddInstallmentPlanCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      installmentPlanId: "",
    },
    validationSchema: Yup.object({
      installmentPlanId: Yup.string().required(
        getString("select_installment_plan"),
      ),
    }),
    onSubmit: (values) => {
      addInstallmentPlan(programData.id, values)
        .then(() => {
          toast_success(getString("installment_plan_added"));
          if (shouldClose === true) setVisible(false);
          else setShouldClose(true);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message));
    },
  });

  const { installmentPlansData } = useSelector(
    (rootState) => rootState.InstallmentPlans,
  );
  const [itemToDelete, setItemToDelete] = useState();
  const [shouldClose, setShouldClose] = useState(true);
  const [
    deleteInstallmentPlanModalVisible,
    setDeleteInstallmentPlanModalVisible,
  ] = useState(false);

  const installmentPlanOptions = installmentPlansData
    .filter((item) => item?.status !== "archived")
    .filter((ipDataEntry) => {
      return !programData.installmentPlans.some(
        (installmentPlan) => installmentPlan.id === ipDataEntry.id,
      );
    })
    .map((ip) => {
      return { value: ip.id, label: ip.name };
    });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInstallmentPlanListData());
  }, []);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("installment_plans_manage")}
      formId="add-installment-plan"
      validationRule={true}
      buttonLabel={getString("save")}
    >
      <Card className="add-installment-plan">
        <CardBody className="card-body">
          <Form
            id="add-installment-plan"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <div className="add-installment-plan__input-wrap">
              <FormSelect
                key={installmentPlanOptions.length}
                name="installmentPlanId"
                label={getString("installment_plan_add")}
                value={validation.values.installmentPlanId}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "installmentPlanId",
                  )
                }
                isMulti={false}
                options={installmentPlanOptions}
                error={validation.errors["installmentPlanId"]}
              />
              <Button
                color="primary"
                onClick={() => {
                  setShouldClose(false);
                  validation.handleSubmit();
                }}
              >
                <FormattedString id="add_plan" />
              </Button>
            </div>
          </Form>
          <SimplestTable
            data={programData.installmentPlans}
            columns={[
              {
                header: getString("name"),
                render: (item) => getString(item.name),
              },
              {
                header: getString("count"),
                render: (item) => item.installmentCount,
              },
              {
                header: getString("availability_days"),
                render: (item) => item.availabilityDays,
              },
              {
                header: (
                  <div
                    style={{
                      textAlign: "right",
                    }}
                  >
                    <FormattedString id="actions" />
                  </div>
                ),
                render: (item) => (
                  <ActionButtons
                    onDelete={() => {
                      setItemToDelete(item.id);
                      setDeleteInstallmentPlanModalVisible(true);
                    }}
                    itemId={item.id}
                  />
                ),
              },
            ]}
          />
        </CardBody>
      </Card>
      {deleteInstallmentPlanModalVisible && (
        <DeleteInstallmentPlanModal
          visibility={deleteInstallmentPlanModalVisible}
          itemId={itemToDelete}
          toggle={() => setDeleteInstallmentPlanModalVisible(false)}
        />
      )}
    </OffcanvasRight>
  );
};
