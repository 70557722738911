import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  restoreMeetingPoint,
  getMeetingPointListData,
} from "../../store/meetingPoints/action";
import ConfirmRestoreBody from "../../Components/Entity/ConfirmRestoreBody";
import { getString } from "Components/Common/FormattedString";

const RestoreMeetingPoint = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();

  const restoreMeetingPointEntry = useCallback(() => {
    dispatch(
      restoreMeetingPoint(itemId, () => {
        dispatch(getMeetingPointListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmRestoreBody
        entityName={getString("meeting_point_sm")}
        toggle={toggle}
        restoreAction={restoreMeetingPointEntry}
      />
    </Modal>
  );
};

export default RestoreMeetingPoint;
