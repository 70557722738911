import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import { ProgramsList } from "./ListPrograms";
import AddProgram from "./AddProgram";

const Programs = () => {
  document.title = getString("programs_page_title");
  const [programAddVisible, setProgramAddVisible] = useState(false);
  const toggleProgramAdd = () => {
    setProgramAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("programs_list")}
                  actionBtnPermission={"PROGRAM_CREATE"}
                  actionBtnLabel={getString("program_sm")}
                  actionBtn={toggleProgramAdd}
                >
                  <ProgramsList />
                </EntityCardList>
              </div>
            </Col>
            {programAddVisible && (
              <AddProgram
                visibility={programAddVisible}
                toggle={toggleProgramAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Programs;
