import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { HotelsList } from "./ListHotels";
import AddHotel from "./AddHotel";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const Hotels = () => {
  document.title = getString("hotels_page_title");
  const [hotelAddVisible, setHotelAddVisible] = useState(false);
  const toggleHotelAdd = () => {
    setHotelAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("hotels_list")}
                  actionBtnLabel={getString("hotel_sm")}
                  actionBtn={toggleHotelAdd}
                  actionBtnPermission={"HOTEL_CREATE"}
                >
                  <HotelsList />
                </EntityCardList>
              </div>
            </Col>
            {hotelAddVisible && (
              <AddHotel visibility={hotelAddVisible} toggle={toggleHotelAdd} />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Hotels;
