// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internal-comment-cell__view {
  display: flex;
  gap: 4px;
  align-items: center;
}
.internal-comment-cell__input {
  flex: 1 1;
}
.internal-comment-cell__text {
  max-width: 200px;
}
.internal-comment-cell__button--add {
  align-items: center;
  display: flex;
  gap: 4px;
}
.internal-comment-cell__button--edit {
  opacity: 0;
  transition: opacity 0.1s;
}
.internal-comment-cell:hover .internal-comment-cell__button--edit {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/pages/Customers/CustomerDetails/partials/components/CustomerApplications/cells/InternalCommentCell/InternalCommentCell.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AAAJ;AAGE;EACE,SAAA;AADJ;AAIE;EACE,gBAAA;AAFJ;AAKE;EACE,mBAAA;EACA,aAAA;EACA,QAAA;AAHJ;AAME;EACE,UAAA;EACA,wBAAA;AAJJ;AAQI;EACE,UAAA;AANN","sourcesContent":[".internal-comment-cell {\n  &__view {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n  }\n\n  &__input {\n    flex: 1;\n  }\n\n  &__text {\n    max-width: 200px;\n  }\n\n  &__button--add {\n    align-items: center;\n    display: flex;\n    gap: 4px;\n  }\n\n  &__button--edit {\n    opacity: 0;\n    transition: opacity 0.1s;\n  }\n\n  &:hover {\n    .internal-comment-cell__button--edit {\n      opacity: 1;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
