import React, { useEffect, useMemo, useState } from "react";

import {
  currentLocation,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getInstallmentPlanListData } from "../../store/installmentPlans/action";

import TableContainer from "../../Components/Common/TableContainerReactTable";
import DeleteInstallmentPlan from "./DeleteInstallmentPlan";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { InstallmentPlanDetails } from "./InstallmentPlanDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { getPointOfSellListData } from "store/pointOfSell/action";
import { PlanInstallmentsView } from "Components/Common/PlanInstallmentsView/PlanInstallmentsView";

const InstallmentPlansList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { installmentPlansData, listInitialized } = useSelector(
    (rootState) => rootState.InstallmentPlans,
  );

  useEffect(() => {
    dispatch(getInstallmentPlanListData());
    dispatch(getPointOfSellListData());
  }, []);

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const toggleDeleteModal = (id) => {
    setModalItemId(id);
    setDeleteModalVisible((p) => !p);
  };

  // details modal
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        Cell: (cellProps) => (
          <div style={{ fontWeight: "500" }}>{cellProps.value}</div>
        ),
        accessor: (cellProps) => cellProps.name,
      },
      {
        Header: getString("availabilityDays"),
        accessor: "availabilityDays",
      },
      {
        Header: getString("installmentCount"),
        accessor: "installmentCount",
      },
      {
        Header: getString("installments"),
        accessor: (cellProps) =>
          `${cellProps.address}, ${cellProps.zipCode} ${cellProps.city}`,
        Cell: (cellProps) => (
          <PlanInstallmentsView
            installmentCount={cellProps.row.original.installmentCount}
            installments={cellProps.row.original.installments}
          />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onDelete={() => toggleDeleteModal(cellProps.id)}
            onShowDetails={(e) => {
              e.preventDefault();
              toggleDetailsModal(true, cellProps.id);
            }}
            itemId={cellProps.id}
            permissionPrefix="INSTALLMENT_PLAN"
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <>
      <TableContainer
        columns={columns || []}
        data={installmentPlansData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("installment_plans_search_for")}
      />
      {deleteModalVisible && (
        <DeleteInstallmentPlan
          itemId={itemId}
          visibility={deleteModalVisible}
          toggle={toggleDeleteModal}
        />
      )}
      {detailsModalVisible && (
        <InstallmentPlanDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};
export { InstallmentPlansList };
