import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const createMeetingPointSchema = Yup.object({
  address: Yup.string()
    .required(getStringSync("enter_hotel_address"))
    .min(5)
    .max(255),
  city: Yup.string()
    .required(getStringSync("enter_city"))
    .min(2, getStringSync("invalid_city_name"))
    .max(255, getStringSync("invalid_city_name")),
  mapUrl: Yup.string()
    .required(getStringSync("enter_map_url"))
    .min(5, getStringSync("map_url_too_short"))
    .max(255),
});

export { createMeetingPointSchema };
