import React, { useCallback } from "react";

import { useDispatch } from "react-redux";
import { restoreHotel, getHotelListData } from "../../store/hotels/action";

import { Modal } from "reactstrap";
import ConfirmRestoreBody from "../../Components/Entity/ConfirmRestoreBody";
import { getString } from "Components/Common/FormattedString";

const RestoreHotel = ({ itemId, toggle, visibility }) => {
  const dispatch = useDispatch();

  const restoreHotelEntry = useCallback(() => {
    dispatch(
      restoreHotel(itemId, () => {
        dispatch(getHotelListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmRestoreBody
        entityName={getString("hotel_sm")}
        toggle={toggle}
        restoreAction={restoreHotelEntry}
      />
    </Modal>
  );
};

export default RestoreHotel;
