import React from "react";
import "./App.scss";

import "./assets/scss/themes.scss";

import Route from "./Routes";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import strings from "resources/strings";

i18n.use(initReactI18next).init({
  resources: strings,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return <Route />;
}
export default App;
