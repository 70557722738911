import React from "react";
import "./InstallmentsCell.scss";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";

const InstallmentsCell = ({ installmentsPaid, installmentsCount }) => {
  const ratio = installmentsPaid / installmentsCount;
  let className = "installments-cell";

  if (ratio === 1) {
    className += " installments-cell--full";
  } else if (ratio >= 0.5) {
    className += " installments-cell--half";
  } else {
    className += " installments-cell--low";
  }

  return (
    <div className={className}>
      <AngloTooltip stringId="installmentsPaid">
        {installmentsPaid}
      </AngloTooltip>
      /
      <AngloTooltip stringId="installmentsCount">
        {installmentsCount}
      </AngloTooltip>
    </div>
  );
};

export default InstallmentsCell;
