import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import classNames from "classnames";
import { hasShiftedDatePassed, isObjectEmpty } from "helpers/utils";

import { useContext, useState } from "react";
import { ProgramInstallmentsPlanTooltipContent } from "./ProgramInstallmentsPlanTooltipContent";
import { InstallmentManagementCanvas } from "../modals/InstallmentManagementCanvas";
import { FormattedString } from "Components/Common/FormattedString";
import { ProgramDetailsContext } from "../../ProgramDetails";

export const SingleProgramInstallmentDot = ({
  installmentPlan,
  programPrices,
  programPacket,
}) => {
  const { programData, canUpdateProgram } = useContext(ProgramDetailsContext);

  const [
    installmentManagementCanvasVisible,
    setInstallmentManagementCanvasVisible,
  ] = useState(false);

  const matchedPrice = programPrices.find(
    (price) =>
      price.programPacketId === programPacket.id &&
      price.installmentPlanId === installmentPlan.id,
  );

  const filled = !isObjectEmpty(matchedPrice);

  const outdated = hasShiftedDatePassed(
    programData.startDate,
    installmentPlan.availabilityDays,
  );

  return (
    <>
      <AngloTooltip
        text={
          filled ? (
            <ProgramInstallmentsPlanTooltipContent
              installmentPlan={installmentPlan}
              programPrices={programPrices}
              matchedPrice={matchedPrice}
            />
          ) : (
            <FormattedString id="click_to_set_installment_prices" />
          )
        }
        className="plan-installments-tooltip"
      >
        <div
          className={classNames([
            "program-installments__dot",
            filled && "program-installments__dot--filled",
            outdated && "program-installments__dot--outdated",
            !canUpdateProgram && "program-installments__dot--not-clickable",
          ])}
          onClick={() =>
            canUpdateProgram && setInstallmentManagementCanvasVisible(true)
          }
        >
          {installmentPlan.installmentCount}
        </div>
      </AngloTooltip>
      {installmentManagementCanvasVisible && (
        <InstallmentManagementCanvas
          visible={installmentManagementCanvasVisible}
          setVisible={setInstallmentManagementCanvasVisible}
          installmentPlan={installmentPlan}
          programPacket={programPacket}
          matchedPrice={matchedPrice}
        />
      )}
    </>
  );
};
