// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installments-cell {
  background: #ff8a8a;
  color: #cb4545;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.installments-cell--full {
  background: #c1ffc1;
  color: #45cb45;
}
.installments-cell--half {
  background: #ffeb8a;
  color: #cb9a45;
}
.installments-cell--low {
  background: #ff8a8a;
  color: #cb4545;
}`, "",{"version":3,"sources":["webpack://./src/pages/Customers/CustomerDetails/partials/components/CustomerApplications/cells/InstallmentsCell/InstallmentsCell.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AACE;EACE,mBAAA;EACA,cAAA;AACJ;AAEE;EACE,mBAAA;EACA,cAAA;AAAJ;AAGE;EACE,mBAAA;EACA,cAAA;AADJ","sourcesContent":[".installments-cell {\n  background: #ff8a8a;\n  color: #cb4545;\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  &--full {\n    background: #c1ffc1;\n    color: #45cb45;\n  }\n\n  &--half {\n    background: #ffeb8a;\n    color: #cb9a45;\n  }\n\n  &--low {\n    background: #ff8a8a;\n    color: #cb4545;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
