// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-action-buttons {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}
.custom-action-buttons i {
  height: 15px;
}
.custom-action-buttons .btn-soft-warning {
  color: #ff9305;
  background: rgba(255, 147, 5, 0.15);
}
.custom-action-buttons .btn-soft-warning:hover {
  color: white;
  background: #ff9305;
}
.custom-action-buttons:empty {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/IpParticipants/components/CustomActionButtons/CustomActionButtons.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,yBAAA;EACA,mBAAA;AACF;AACE;EACE,YAAA;AACJ;AAEE;EACE,cAAA;EACA,mCAAA;AAAJ;AAEI;EACE,YAAA;EACA,mBAAA;AAAN;AAIE;EACE,aAAA;AAFJ","sourcesContent":[".custom-action-buttons {\n  display: flex;\n  gap: 8px;\n  justify-content: flex-end;\n  align-items: center;\n\n  i {\n    height: 15px;\n  }\n\n  .btn-soft-warning {\n    color: #ff9305;\n    background: rgba(255, 147, 5, 0.15);\n\n    &:hover {\n      color: white;\n      background: #ff9305;\n    }\n  }\n\n  &:empty {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
