import { FormattedString } from "Components/Common/FormattedString";
import "./ProgramShort.scss";
import { format } from "date-fns";
import { pl } from "date-fns/locale";

const ProgramShort = ({
  hotelName,
  meetingPoint,
  programStartDate,
  programEndDate,
}) => {
  const startDate = programStartDate
    ? format(new Date(programStartDate), "dd.MM.yyyy", { locale: pl })
    : null;

  const endDate = programStartDate
    ? format(new Date(programEndDate), "dd.MM.yyyy", { locale: pl })
    : null;

  return (
    <div className="program-short">
      <div className="program-short__hotel">{hotelName || "N/A"}</div>
      <div className="program-short__dates">
        <FormattedString id="from" />{" "}
        <span className="program-short__dates--bold">{startDate || "N/A"}</span>{" "}
        <FormattedString id="to" />{" "}
        <span className="program-short__dates--bold">{endDate || "N/A"}</span>
      </div>
      <div className="program-short__meeting">
        <span className="program-short__meeting--from">
          <FormattedString id="transport" />:{" "}
        </span>
        {meetingPoint?.city || "N/A"}
      </div>
    </div>
  );
};

export default ProgramShort;
