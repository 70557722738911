import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useProfile } from "../Components/Hooks/UserHooks";
import { fetchUserProfile, logoutUser, resetLoginFlag } from "../store/actions";
import { rolePermissions } from "permissions";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";

const AuthProtected = ({ permission, location, children }) => {
  const isUserLogout = useSelector((state) => state.Login.isUserLogout);
  const { userProfile, loading, token } = useProfile();
  const roles = useSelector((state) => state.Profile.user.roles);

  const hasPermission = roles?.some((role) => {
    if (!permission) return true;
    const permissions = rolePermissions[role];
    return permissions === "*" || permissions?.includes(permission);
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if (!userProfile && loading && !token) {
      dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  const navigateToLogin = (fromLocation) => {
    dispatch(resetLoginFlag());
    return (
      <Navigate to={{ pathname: "/login", state: { from: fromLocation } }} />
    );
  };

  if (isUserLogout || (!userProfile && loading && !token)) {
    return navigateToLogin(location);
  }

  if (hasPermission === false)
    return <Navigate to={{ pathname: "/access_denied" }} />;

  // If roles are empty, render a spinner
  if (!roles || roles.length === 0) {
    return <PreloaderWrap h100 />;
  }

  return children;
};

const AccessRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

export { AuthProtected, AccessRoute };
