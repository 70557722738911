import { useSelector } from "react-redux";
import { rolePermissions } from "permissions";

export const useHasPermission = (permissionName) => {
  const roles = useSelector((state) => state.Profile.user.roles);

  return roles?.some((role) => {
    const permissions = rolePermissions[role];
    return permissions === "*" || permissions?.includes(permissionName);
  });
};

export const hasPermission = (roles, permissionName) => {
  return roles?.some((role) => {
    const permissions = rolePermissions[role];
    return permissions === "*" || permissions?.includes(permissionName);
  });
};
