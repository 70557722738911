import React, { useCallback } from "react";
import {
  deleteIpParticipant,
  getIpParticipantListData,
} from "../../store/ipParticipants/action";
import { useDispatch } from "react-redux";

import { Modal } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import ConfirmDeleteBody from "Components/Entity/ConfirmDeleteBody";

const DeleteIpParticipant = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();

  const deleteIpParticipantEntry = useCallback(() => {
    dispatch(
      deleteIpParticipant(itemId, () => {
        dispatch(getIpParticipantListData());
        toggle();
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDeleteBody
        entityName={getString("ipParticipant_sm")}
        toggle={toggle}
        deleteAction={deleteIpParticipantEntry}
      />
    </Modal>
  );
};

export default DeleteIpParticipant;
