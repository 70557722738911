import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { MeetingPointsList } from "./MeetingPointsList";
import AddMeetingPoint from "./AddMeetingPoint";
import { getString } from "Components/Common/FormattedString";

const MeetingPoints = () => {
  document.title = getString("meeting_points_page_title");
  const [meetingPointAddVisible, setMeetingPointAddVisible] = useState(false);
  const toggleMeetingPointAdd = () => {
    setMeetingPointAddVisible((p) => !p);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("meeting_points_list")}
                  actionBtnLabel={getString("meeting_point_sm")}
                  actionBtn={toggleMeetingPointAdd}
                  actionBtnPermission="MEETING_POINT_CREATE"
                >
                  <MeetingPointsList />
                </EntityCardList>
              </div>
            </Col>
            {meetingPointAddVisible && (
              <AddMeetingPoint
                visibility={meetingPointAddVisible}
                toggle={toggleMeetingPointAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default MeetingPoints;
