import { FormattedString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useContext } from "react";
import { Link } from "react-router-dom";

export const CustomerDetails = () => {
  const {
    applicationData,
    applicationData: {
      customerEmail,
      customerFirstName,
      customerLastName,
      customerPhoneNumber,
      customerPhonePrefix,
    },
  } = useContext(ApplicationDetailsContext);

  return (
    <div className="customer-details">
      <ul className="list-unstyled mb-0 vstack gap-3">
        <li>
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="ri-user-line me-2 align-middle text-muted fs-16"></i>
            </div>
            <div className="flex-grow-1">
              <h6 className="fs-14 mb-1">
                <Link to={`/customer/${applicationData.customerId}`}>
                  {customerFirstName || "N/A"} {customerLastName || "N/A"}
                </Link>
              </h6>
              <p className="text-muted mb-0">
                <FormattedString id="customer" />
              </p>
            </div>
          </div>
        </li>
        <li>
          <i className="ri-mail-line me-2 align-middle text-muted fs-16"></i>
          <a
            href={`mailto:${customerEmail}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {customerEmail || "N/A"}
          </a>
        </li>
        <li>
          <i className="ri-phone-line me-2 align-middle text-muted fs-16"></i>
          <a href={`tel:+${customerPhonePrefix}${customerPhoneNumber}`}>
            +{customerPhonePrefix || "N/A"}{" "}
            {splitNumbers(customerPhoneNumber) || "N/A"}
          </a>
        </li>
      </ul>
    </div>
  );
};
