import { mockPaymentMethod } from "tests/store/paymentMethods/mockData";
import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PAYMENT_METHOD_LIST_DATA,
} from "./actionType";

export const INIT_STATE = {
  paymentMethodsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  listInitialized: false,
};

const PaymentMethods = (state = INIT_STATE, action) => {
  switch (action.type) {
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_PAYMENT_METHOD_LIST_DATA:
          return {
            ...state,
            paymentMethodsData: action.payload.data,
            // paymentMethodsData: [mockPaymentMethod],
            listInitialized: true,
          };

        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_PAYMENT_METHOD_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};
export default PaymentMethods;
