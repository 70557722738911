import React, { useContext, useEffect } from "react";

import * as Yup from "yup";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { getUpsellListData } from "store/upsells/action";
import { assignUpsellToProgram } from "helpers/API/core-service/cs_backend_helper";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";

export const AssignPpUpsellCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      id: "",
    },
    validationSchema: Yup.object({
      id: Yup.string().required(getString("upsell_required")),
    }),
    onSubmit: (values) => {
      assignUpsellToProgram(programData.id, {
        upsellId: values.id,
        participantGroup: "participants",
      })
        .then(() => {
          toast_success(getString("upsell_assigned"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message));
    },
  });

  const { upsellsData } = useSelector((rootState) => rootState.Upsells);
  const upsellsOptions = upsellsData.map((u) => {
    return { value: u.id, label: u.name };
  });

  const participantUpsellIds = programData.participantUpsells.map(
    (upsell) => upsell.id,
  );

  const filteredUpsellsOptions = upsellsOptions
    .filter((item) => item?.status !== "archived")
    .filter((option) => !participantUpsellIds.includes(option.value));

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUpsellListData());
  }, []);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(false)}
      title={getString("pp_upsell_assign")}
      formId="assign-upsell-form"
      validationRule={true}
      buttonLabel={getString("save")}
    >
      <Card>
        <CardBody className="card-body">
          <Form
            id="assign-upsell-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
            action="#"
          >
            <FormSelect
              name="id"
              label={getString("upsell_pp_selected")}
              value={validation.values.id}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "id")
              }
              isMulti={false}
              options={filteredUpsellsOptions}
              error={validation.errors["id"]}
            />
          </Form>
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};
