import i18next from "i18next";
import strings from "resources/strings";

export const FormattedString = ({ id, defaultValue }) => {
  return i18next.t(id, { defaultValue });
};

export const getString = (id, defaultValue, props = {}) => {
  return i18next.t(id, { defaultValue, ...props });
};

export const getStringSync = (id, defaultValue) => {
  return strings.en.translation[id] || defaultValue || id;
};
