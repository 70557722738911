import { getString } from "Components/Common/FormattedString";
import ConfirmDeleteBody from "Components/Entity/ConfirmDeleteBody";
import { deleteEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_success } from "helpers/toast_helper";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useContext, useState } from "react";
import { Modal } from "reactstrap";

export const DeleteEntityNoteModal = ({ noteId, visibility, toggle }) => {
  const { refreshApplicationData } = useContext(ApplicationDetailsContext);
  const [loading, setLoading] = useState(false);

  const deleteEntityNoteEntry = () => {
    setLoading(true);
    deleteEntityNote(noteId)
      .then(() => {
        toast_success(getString("entity_note_deleted"));
        toggle(false);
        refreshApplicationData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDeleteBody
        entityName={getString("entity_note")}
        toggle={toggle}
        deleteAction={deleteEntityNoteEntry}
        loading={loading}
      />
    </Modal>
  );
};
