import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_MEETING_POINT_LIST_DATA,
  POST_MEETING_POINT,
  EDIT_MEETING_POINT,
  ARCHIVE_MEETING_POINT,
  RESTORE_MEETING_POINT,
} from "./actionType";

export const meetingPointsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const meetingPointsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getMeetingPointListData = () => ({
  type: GET_MEETING_POINT_LIST_DATA,
  payload: {},
});

export const postMeetingPoint = (data, onSuccess) => ({
  type: POST_MEETING_POINT,
  payload: { data, onSuccess },
});
export const editMeetingPoint = (id, data, onSuccess) => ({
  type: EDIT_MEETING_POINT,
  payload: { id, data, onSuccess },
});

export const archiveMeetingPoint = (id, onSuccess) => ({
  type: ARCHIVE_MEETING_POINT,
  payload: { id, onSuccess },
});

export const restoreMeetingPoint = (id, onSuccess) => ({
  type: RESTORE_MEETING_POINT,
  payload: { id, onSuccess },
});
