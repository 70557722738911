import { FormattedString } from "Components/Common/FormattedString";
import classNames from "classnames";
import { FormFeedback, Label } from "reactstrap";

export const FormFilePicker = ({
  label,
  name,
  value,
  options,
  error,
  onFileChange,
  ...rest
}) => {
  return (
    <div className="mb-3" data-testid={`select-${name}`}>
      <Label htmlFor={name} className="form-label">
        {label || <FormattedString id={name} />}
      </Label>
      <input
        id={name}
        type="file"
        className={classNames(["form-control", error && "is-invalid"])}
        onChange={onFileChange}
        name={name}
        {...rest}
      />
      {error && <FormFeedback type="invalid">{error}</FormFeedback>}
    </div>
  );
};
