// Layout
export * from "./layouts/action";
// Authentication module
export * from "./auth/register/actions";
export * from "./auth/login/actions";
export * from "./auth/profile/actions";
// Dashboard Analytics
export * from "./dashboardCRM/action";

// hotels
export * from "./hotels/action";

// hotels
export * from "./users/action";
