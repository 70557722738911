import "./LinkButton.scss";
import classNames from "classnames";

export const LinkButton = ({ children, onClick, className, disabled }) => {
  return (
    <span
      className={classNames("link-button", className, {
        "link-button--disabled": disabled,
      })}
      onClick={!disabled ? onClick : undefined}
    >
      {children}
    </span>
  );
};
