// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.participants-tab .gender-column {
  font-weight: bold;
}
.participants-tab .gender-column.male {
  color: #00796b;
}
.participants-tab .gender-column.female {
  color: #d81b60;
}
.participants-tab .preferred-diet-column {
  font-weight: bold;
}
.participants-tab .preferred-diet-column.vegetarian {
  color: #388e3c;
}
.participants-tab .preferred-diet-column.vegan {
  color: #689f38;
}
.participants-tab .preferred-diet-column.non-vegetarian {
  background-color: #ffebee;
  color: #d32f2f;
}
.participants-tab .preferred-diet-column.gluten-free {
  background-color: #fff3e0;
  color: #f57c00;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/Tabs/ParticipantsTab.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,iBAAA;AAHJ;AAME;EACE,cAAA;AAJJ;AAOE;EACE,cAAA;AALJ;AAQE;EACE,yBAAA;EACA,cAAA;AANJ;AASE;EACE,yBAAA;EACA,cAAA;AAPJ","sourcesContent":[".participants-tab {\n  .gender-column {\n    font-weight: bold;\n  }\n\n  .gender-column.male {\n    color: #00796b;\n  }\n\n  .gender-column.female {\n    color: #d81b60;\n  }\n\n  .preferred-diet-column {\n    font-weight: bold;\n  }\n\n  .preferred-diet-column.vegetarian {\n    color: #388e3c;\n  }\n\n  .preferred-diet-column.vegan {\n    color: #689f38;\n  }\n\n  .preferred-diet-column.non-vegetarian {\n    background-color: #ffebee;\n    color: #d32f2f;\n  }\n\n  .preferred-diet-column.gluten-free {\n    background-color: #fff3e0;\n    color: #f57c00;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
