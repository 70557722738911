// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coordinators-view__icon {
  font-size: 31px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/components/CoordinatorsView/CoordinatorsView.scss"],"names":[],"mappings":"AACE;EACE,eAAA;AAAJ","sourcesContent":[".coordinators-view {\n  &__icon {\n    font-size: 31px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
