import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const PointOfSellDetails = ({ itemId, toggle, visible }) => {
  const { pointOfSellData } = useSelector((rootState) => rootState.PointOfSell);

  const item = pointOfSellData.find((pointOfSell) => pointOfSell.id === itemId);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="point-of-sell-details-modal"
      title={getString("point_of_sell_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
