export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const API_ERROR = "LOGIN_API_ERROR";
export const GRANT_GOOGLE_AUTH = "GRANT_GOOGLE_AUTH";

export const RESET_LOGIN_FLAG = "RESET_LOGIN_FLAG";
