import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserListData,
  editUser,
  getBusinessUnitListData,
} from "../../store/users/action";
import { createUserSchema, userRoles } from "../../models/users";
import {
  handleInputChange,
  handleMultiSelectChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { FormSelect } from "../../Components/Entity/FormSelect";
import { getString } from "Components/Common/FormattedString";

const EditUser = ({ user, toggle, visibility }) => {
  const dispatch = useDispatch();
  const [businessUnitsOptions, setBusinessUnitsOptions] = useState([]);

  const selectLayoutState = (state) => state.Users;
  const selectLayoutProperties = createSelector(selectLayoutState, (state) => ({
    businessUnitsData: state.businessUnitsData,
    isUserEdited: state.isUserEdited,
    error: state.error,
    loading: state.loading,
    formValidationErrors: state.formValidationErrors,
  }));
  const { businessUnitsData, loading, formValidationErrors } = useSelector(
    selectLayoutProperties,
  );

  useEffect(() => {
    dispatch(getBusinessUnitListData());
  }, []);

  useEffect(() => {
    setBusinessUnitsOptions(
      businessUnitsData.map((item) => ({
        value: item.id,
        label: item.name,
      })),
    );
  }, [businessUnitsData]);

  const filteredUserRole =
    (user?.roles || []).filter((role) => role !== "ROLE_CRM")[0] || null;

  const filteredBusinessUnits = user.businessUnits.map((unit) => {
    return { value: unit.id, label: unit.name };
  });

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      role: filteredUserRole,
      businessUnits: filteredBusinessUnits,
    },
    validationSchema: createUserSchema,
    onSubmit: (values) => {
      values.businessUnits = values.businessUnits.map((b) => b.value);
      dispatch(
        editUser(user.id, values, () => {
          dispatch(getUserListData());
          toggle(null, false);
        }),
      );
    },
  });

  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={`${getString("users_edit")} ${user?.firstName + " " + user?.lastName || ""}`}
        formId="edit-user"
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-user"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              {["firstName", "lastName", "email"].map((field) => (
                <FormTextField
                  key={field}
                  label={getString(field)}
                  name={field}
                  value={validation.values[field]}
                  onChange={(e) => handleInputChange(validation, e, field)}
                  error={validation.errors[field]}
                  touched={validation.touched[field]}
                />
              ))}
              <FormSelect
                name="role"
                label={getString("role")}
                value={validation.values.role}
                defaultValue={userRoles.find(
                  (r) => r.value === validation.values.role,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "role")
                }
                isMulti={false}
                options={userRoles}
                error={validation.errors["role"]}
              />
              <FormSelect
                name="businessUnits"
                label={getString("business_unit")}
                value={validation.values.businessUnits}
                defaultValue={validation.values.businessUnits}
                onChange={(selectedOption) =>
                  handleMultiSelectChange(
                    validation,
                    selectedOption,
                    "businessUnits",
                  )
                }
                isMulti={true}
                options={businessUnitsOptions}
                error={validation.errors["businessUnits"]}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditUser;
