// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.international-participants-table thead {
  border-top: solid #d3d3d3 1px;
  border-bottom: solid #d3d3d3 1px;
}
.international-participants-table__row:not(.international-participants-table__row--group-header) td:first-child {
  padding-left: calc(28px + var(--vz-card-spacer-x)) !important;
}
.international-participants-table td {
  border: 0;
}
.international-participants-table th {
  font-weight: 500;
}
.international-participants-table td {
  color: #212529;
  font-size: 13px;
}
.international-participants-table .international-participants-table__row--group-header {
  border-color: unset;
  border-style: none;
  border-top: solid var(--vz-border-color) 1px;
  padding-top: 2px;
}
.international-participants-table__type--non_ns_ip {
  color: #ff0000;
}
.international-participants-table__type--ns {
  color: #eaaa00;
}
.international-participants-table__type--esl {
  color: #45cb85;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/components/InternationalParticipantsTable/InternationalParticipantsTable.scss"],"names":[],"mappings":"AACE;EACE,6BAAA;EACA,gCAAA;AAAJ;AAGI;EACE,6DAAA;AADN;AAKE;EACE,SAAA;AAHJ;AAME;EACE,gBAAA;AAJJ;AAOE;EACE,cAAA;EACA,eAAA;AALJ;AAQE;EACE,mBAAA;EACA,kBAAA;EACA,4CAAA;EACA,gBAAA;AANJ;AAUI;EACE,cAAA;AARN;AAUI;EACE,cAAA;AARN;AAUI;EACE,cAAA;AARN","sourcesContent":[".international-participants-table {\n  thead {\n    border-top: solid #d3d3d3 1px;\n    border-bottom: solid #d3d3d3 1px;\n  }\n  &__row:not(.international-participants-table__row--group-header) {\n    td:first-child {\n      padding-left: calc(28px + var(--vz-card-spacer-x)) !important;\n    }\n  }\n\n  td {\n    border: 0;\n  }\n\n  th {\n    font-weight: 500;\n  }\n\n  td {\n    color: #212529;\n    font-size: 13px;\n  }\n\n  .international-participants-table__row--group-header {\n    border-color: unset;\n    border-style: none;\n    border-top: solid var(--vz-border-color) 1px;\n    padding-top: 2px;\n  }\n\n  &__type {\n    &--non_ns_ip {\n      color: #ff0000;\n    }\n    &--ns {\n      color: #eaaa00;\n    }\n    &--esl {\n      color: #45cb85;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
