import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

import {
  DELETE_IP_PARTICIPANT,
  EDIT_IP_PARTICIPANT,
  GET_BUSINESS_UNIT_LIST_DATA,
  GET_IP_PARTICIPANT_LIST_DATA,
  POST_IP_PARTICIPANT,
  BLOCK_IP_PARTICIPANT,
  POST_IP_ATTACHMENT,
} from "./actionType";
import { ipParticipantsApiSuccess, ipParticipantsApiError } from "./action";

import {
  deleteIpParticipant,
  editIpParticipant,
  getIpParticipantList,
  postIpParticipant,
  blockIpParticipant,
  postIpAttachment,
} from "../../helpers/API/core-service/cs_backend_helper";
import { getBusinessUnitList } from "../../helpers/API/core-service/cs_backend_helper";

function* getIpParticipantListData({ payload: data }) {
  try {
    let response;
    response = yield call(getIpParticipantList, data);

    yield put(ipParticipantsApiSuccess(GET_IP_PARTICIPANT_LIST_DATA, response));
  } catch (error) {
    yield put(ipParticipantsApiError(GET_IP_PARTICIPANT_LIST_DATA, error));
  }
}

function* getBusinessUnitListData({ payload: data }) {
  try {
    let response;
    response = yield call(getBusinessUnitList, data);

    yield put(ipParticipantsApiSuccess(GET_BUSINESS_UNIT_LIST_DATA, response));
  } catch (error) {
    yield put(ipParticipantsApiError(GET_BUSINESS_UNIT_LIST_DATA, error));
  }
}

function* createIpParticipant({ payload: ipParticipant }) {
  try {
    const response = yield call(
      postIpParticipant,
      ipParticipant.data,
      ipParticipant.onSuccess,
    );
    yield put(ipParticipantsApiSuccess(POST_IP_PARTICIPANT, response));
    toast_success("IpParticipant added successfully");
  } catch (error) {
    yield put(ipParticipantsApiError(POST_IP_PARTICIPANT, error));
    toast_error("Failed to create ipParticipant");
    toast_error(error.data.message);
  }
}

function* editIpParticipantEntry({ payload: ipParticipant }) {
  try {
    const response = yield call(
      editIpParticipant,
      ipParticipant.id,
      ipParticipant.data,
      ipParticipant.onSuccess,
    );
    yield put(ipParticipantsApiSuccess(EDIT_IP_PARTICIPANT, response));
    toast_success("IpParticipant edited successfully");
  } catch (error) {
    yield put(ipParticipantsApiError(EDIT_IP_PARTICIPANT, error));
    toast_error(error.data.message);
  }
}

function* deleteIpParticipantEntry({ payload: ipParticipant }) {
  try {
    const response = yield call(
      deleteIpParticipant,
      ipParticipant.id,
      ipParticipant.onSuccess,
    );
    yield put(ipParticipantsApiSuccess(DELETE_IP_PARTICIPANT, response));
    toast_success("IpParticipant removed");
  } catch (error) {
    yield put(ipParticipantsApiError(DELETE_IP_PARTICIPANT, error));
    toast_error(error.data.message);
  }
}

function* blockIpParticipantEntry({ payload: ipParticipant }) {
  try {
    const response = yield call(
      blockIpParticipant,
      ipParticipant.id,
      ipParticipant.onSuccess,
    );
    yield put(ipParticipantsApiSuccess(BLOCK_IP_PARTICIPANT, response));
    toast_success("IpParticipant blocked successfully");
  } catch (error) {
    yield put(ipParticipantsApiError(BLOCK_IP_PARTICIPANT, error));
    toast_error(error.data.message);
  }
}

// New function to handle POST_IP_ATTACHMENT
function* postIpAttachmentEntry({ payload: attachment }) {
  try {
    const response = yield call(
      postIpAttachment,
      attachment.id,
      attachment.data,
      attachment.onSuccess,
    );
    yield put(ipParticipantsApiSuccess(POST_IP_ATTACHMENT, response));
    toast_success("Attachment posted successfully");
  } catch (error) {
    console.error(error);
    yield put(ipParticipantsApiError(POST_IP_ATTACHMENT, error));
    toast_error("Failed to post attachment");
    if (error.data && error.data.message) {
      toast_error(error.data.message);
    }
  }
}

// New watcher for POST_IP_ATTACHMENT
export function* watchPostIpAttachment() {
  yield takeEvery(POST_IP_ATTACHMENT, postIpAttachmentEntry);
}

export function* watchGetIpParticipantListData() {
  yield takeEvery(GET_IP_PARTICIPANT_LIST_DATA, getIpParticipantListData);
}
export function* watchGetBusinessUnitListData() {
  yield takeEvery(GET_BUSINESS_UNIT_LIST_DATA, getBusinessUnitListData);
}
export function* watchPostIpParticipant() {
  yield takeEvery(POST_IP_PARTICIPANT, createIpParticipant);
}
export function* watchEditIpParticipant() {
  yield takeEvery(EDIT_IP_PARTICIPANT, editIpParticipantEntry);
}
export function* watchDeleteIpParticipant() {
  yield takeEvery(DELETE_IP_PARTICIPANT, deleteIpParticipantEntry);
}
export function* watchBlockIpParticipant() {
  yield takeEvery(BLOCK_IP_PARTICIPANT, blockIpParticipantEntry);
}

function* ipParticipantsSaga() {
  yield all([
    fork(watchGetBusinessUnitListData),
    fork(watchGetIpParticipantListData),
    fork(watchPostIpParticipant),
    fork(watchEditIpParticipant),
    fork(watchDeleteIpParticipant),
    fork(watchBlockIpParticipant),
    fork(watchPostIpAttachment),
  ]);
}

export default ipParticipantsSaga;
