const strings = {
  en: {
    translation: {
      native_speakers: "Native Speakers",
      polish_people: "Polish People",
      coordinator: "Coordinator",
      health_and_safety: "Health and Safety",
      SUPER_ADMIN: "Super Admin",

      // roles
      ROLE_SUPER_ADMIN: "Super Admin",
      ROLE_ADMIN: "Admin",
      ROLE_SALES: "Sales",
      ROLE_PRODUCT: "Product",

      // global vals
      selected_user: "Selected user",
      type: "Type",
      actions: "Actions",
      additional_price: "Additional Price",
      date_modifier: "Date Modifier",
      group_type: "Group Type",
      add: "Add",
      duplicate: "Duplicate",
      edit: "Edit",
      save: "Save",
      cancel: "Cancel",
      delete: "Delete",
      remove: "Remove",
      archive: "Archive",
      restore: "Restore",
      show_details: "Show details",
      hour: "Hour",
      role: "Role",
      business_unit: "Business unit",
      copy_to_clipboard: "Copy to clipboard",
      download: "Download",
      open_in_new_tab: "Open in new tab",
      add_new_value: "Add new value",
      reassign: "Reassign",
      all_business_units: "All Business Units",
      overview: "Overview",
      log: "Log",
      close: "Close",
      view: "View",
      create: "Create",

      // tooltips
      restore_from_archive: "Restore from archive",

      // login page
      login_page_title: "Sign in | Angloville CRM",
      login_welcome_back: "Welcome Back!",
      login_sign_in_to_continue: "Sign in to continue to Angloville CRM.",
      login_google: "Sign in with Google",

      // sidebar menu names
      menu: "Menu",
      "pp-applications": "PP Applications",
      "ip-applications": "IP Applications",
      coordinators: "Coordinators",
      "product-management": "Product Management",
      programs: "Programs",
      hotels: "Venues",
      "program-packet-templates": "Program Packet Templates",
      "meeting-points": "Meeting Points",
      meetings: "Meetings",
      upsells: "Upsells",
      settings: "Settings",
      users: "Users",
      payments: "Payments",
      "payment-methods": "Payment Methods",
      "point-of-sell": "Points of Sell",
      "installment-plans": "Installment Plans",
      applications: "Applications",
      "ip-participants": "IP Participants",

      // backend-like table labels
      name: "Name",
      address: "Address",
      zipCode: "Zip Code",
      city: "City",
      mapUrl: "Map URL",
      contactInfo: "Contact Info",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      filePath: "File Path",
      roles: "Roles",
      businessUnits: "Business Units",
      startHour: "Start Hour",
      endHour: "End Hour",
      dateModifier: "Date Modifier",
      meetingPoint: "Meeting Point",
      additionalPrice: "Additional Price",
      groupType: "Group Type",
      provider: "Provider",
      businessUnit: "Business Unit",
      description: "Description",
      basePrice: "Base Price",
      attachments: "Attachments",
      paymentMethod: "Payment Method",
      label: "Label",
      ppMinAge: "PP Min Age",
      ppMaxAge: "PP Max Age",
      ipMinAge: "IP Min Age",
      ipMaxAge: "IP Max Age",
      availabilityDays: "Availability Days",
      installmentCount: "Installment Count",
      dueDays: "Due Days",
      currency: "Currency",
      pointOfSell: "Point of Sell",
      educational: "Educational",
      touristic: "Touristic",
      ppCapacity: "PP Capacity",
      ipCapacity: "IP Capacity",
      programPacketTemplate: "Program Packet Template",
      installmentPlan: "Installment Plan",
      healthAndSafetyCoordinators: "Health and Safety Coordinators",
      meetingPP: "Participants Meeting",
      meetingNS: "Native Speakers Meeting",
      programPackets: "Program Packets",
      participantUpsells: "PP Upsells",
      internationalParticipantUpsells: "IP Upsells",
      startDate: "Start Date",
      endDate: "End Date",
      healthAndSafetyCapacity: "Health and Safety Capacity",
      price: "Price",
      installmentPosition: "Installment Position",
      targetAudience: "Target Audience",
      junior: "Junior",
      kids: "Kids",
      adult: "Adult",
      family: "Family",
      company_or_school: "Company or School",
      season: "Season",
      programMeetingsNS: "NS Team Meeting Points",
      programMeetingsPP: "Participant Meeting Points",
      customId: "Internal ID",

      // delete modal
      delete_modal_title: "Confirm deleting this",
      delete_modal_warning:
        "Deleting is irreversible, and the deleted item will be lost permanently.",
      delete_modal_reminder: "Keep in mind that deleting is permanent!",

      // detach modal
      detach_modal_title: "Confirm removing this",
      detach_modal_title_2: "from a program?",
      detach_modal_warning:
        "Removing values from a program is reversible and generally safe.",
      detach_modal_reminder:
        "Keep in mind that you can always add the values back!",

      // archive modal
      archive_modal_title: "Confirm archiving this",
      archive_modal_warning:
        "Archiving is reversible, and the deleted item will be moved to the archive. You can safely restore it from the archive.",
      archive_modal_reminder:
        "Keep in mind that archiving may impact related configurations!",

      // restore modal
      restore_modal_title: "Confirm restoring this",
      restore_modal_warning:
        "Restoring brings the item back from the archive, making it available on other pages.",

      // duplicate modal
      duplicate_modal_title: "Confirm duplicating this",
      duplicate_modal_warning:
        "Duplicating will create a new item with the same data.",

      // hotels
      hotels_page_title: "Venues management | Angloville CRM",
      hotel_sm: "venue",
      hotels_add_new_hotel: "Add New Venue",
      hotels_edit: "Edit venue",
      hotels_list: "Venues list",
      hotels_search_for: "Search for venue...",
      hotel: "Venue",
      hotel_details: "Venue Details",
      hotel_select: "Select Venue",

      // coordinators
      coordinators_page_title: "Coordinators management | Angloville CRM",
      coordinator_sm: "coordinator",
      coordinators_add_new_coordinator: "Add New Coordinator",
      coordinators_search_for: "Search for coordinator...",
      coordinators_edit: "Edit coordinator",
      coordinators_list: "Coordinators list",
      coordinator_details: "Coordinator Details",
      coordinator_add: "Add Coordinator",
      health_and_safety_sm: "health and safety",

      // meeting points
      meeting_points_page_title: "Meeting Points management | Angloville CRM",
      meeting_points_list: "Meeting Points list",
      meeting_point: "Meeting Point",
      meeting_point_sm: "meeting point",
      meeting_points_add_new_meeting_point: "Add new meeting point",
      meeting_points_edit: "Edit meeting point",
      meeting_points_search_for: "Search for meeting point...",
      meeting_point_details: "Meeting Point Details",
      meeting_point_add: "Add Meeting Point",
      meeting_point_selected: "Selected Meeting Point",

      // meetings
      meetings_page_title: "Meetings management | Angloville CRM",
      meeting_sm: "meeting",
      meetings_list: "Meetings list",
      meetings_add_new_meeting: "Add new meeting",
      meetings_edit: "Edit meeting",
      meetings_search_for: "Search for meeting...",
      meeting_details: "Meeting Details",
      meeting_selected: "Selected Meeting",
      participants_meeting_assign: "Assign Participants Meeting",
      ns_meeting_assign: "Assign NS Meeting",
      add_participants_meeting_point: "Add Participants Meeting Point",
      edit_participants_meeting_point: "Edit Participants Meeting Point",
      add_native_speakers_meeting_point: "Add Native Speakers Meeting Point",
      edit_native_speakers_meeting_point: "Edit Native Speakers Meeting Point",
      native_speakers_meeting: "Native Speakers Meeting",
      participants_meeting: "Participants Meeting",

      // users
      users_page_title: "Users management | Angloville CRM",
      user_sm: "user",
      users_list: "Users list",
      users_add_new_user: "Add new user",
      users_edit: "Edit user",
      users_card_title: "CRM Users management",
      users_cannot_delete_yourself: "You cannot delete your own account.",
      users_search_for: "Search for user...",
      user_details: "User Details",
      user_selected: "Selected User",

      // programs
      programs_page_title: "Programs management | Angloville CRM",
      program_sm: "program",
      programs_list: "Programs list",
      programs_add_new_program: "Add new program",
      programs_edit: "Edit program",
      programs_search_for: "Search for program...",
      program_details: "Program Details",
      editProgram: "Edit program",
      ip_assign: "Assign IP",
      program_packet_selected: "Selected Program Packet",
      ip_selected: "Selected IP",

      // program status
      in_preparation: "In preparation",
      on_sale: "On sale",
      ongoing: "Ongoing",
      settlement: "Settlement",
      cancelled: "Cancelled",
      archived: "Archived",

      // application status
      partly_paid: "Partly paid",
      pending: "Pending",
      pre_booking: "Pre-booking",
      fully_paid: "Fully paid",

      // attachments
      attachments_page_title: "Attachments management | Angloville CRM",
      attachment_sm: "attachment",
      attachments_add_new_attachment: "Add New Attachment",
      attachments_search_for: "Search for attachment...",
      attachments_edit: "Edit attachment",
      attachments_list: "Attachments list",
      attachment_details: "Attachment Details",
      file_name: "File Name",
      upload_file: "Upload File",
      attachments_sm: "attachments",
      attachments_added: "Attachments added",
      delete_attachment: "Delete attachment",
      load_more: "Load more",

      // payment_methods
      payment_methods_page_title: "Payment Methods management | Angloville CRM",
      payment_methods_search_for: "Search for payment method...",
      payment_methods_list: "Payment Methods list",
      payment_method_details: "Payment Method Details",

      // upsells
      upsells_page_title: "Upsells management | Angloville CRM",
      upsells_search_for: "Search for upsell...",
      upsell_details: "Upsell Details",
      upsells_list: "Upsells list",
      upsell_sm: "upsell",
      upsells_edit: "Edit upsell",
      upsells_add_new_upsell: "Add new upsell",
      upsell_pp_selected: "Selected PP Upsell",
      upsell_ip_selected: "Selected IP Upsell",

      // applications
      applications_page_title: "Applications management | Angloville CRM",
      applications_search_for: "Search for application...",
      applications_list: "Recent applications",
      application_details: "Application Details",
      activityLog: "Activity Log",
      application_note: "Application Note",
      customer_note: "Customer Note",
      client_comment: "Client Comment",
      client_note: "Client Note",
      customer_details: "Customer Details",
      billing_data: "Billing Data",
      automated_emails: "Automated Emails",
      editApplication: "Edit Application",
      number: "Number",
      dueDate: "Due Date",
      paid: "Paid",
      future: "Future",
      overdue: "Overdue",
      note: "Note",
      entity_note: "Entity Note",
      no_activities_available: "No activities available",
      mail_preview: "Mail Preview",
      no_mail_preview_available: "Mail preview is not available",
      no_utms_available: "No UTM parameters available",
      billing_data_not_available: "Billing data not available",
      add_comment: "Add comment",
      generate_report: "Generate report",
      add_new_note: "Add new note",
      selected_name: "Selected Name",
      installments_short: "Inst.",
      customer_name: "Customer Name",
      program: "Program",
      week: "Week",
      date: "Date",
      package: "Package",
      age: "Age",
      participant_name: "Participant Name",
      internal_comment: "Internal Comment",
      utm_parameters: "UTM Parameters",
      recent_activity: "Recent Activity",
      new_note: "New Note",
      special_needs: "Special Needs",
      accommodation: "Accommodation",
      no_payment: "No payment",
      "application.updated": "Application updated",
      "application.email_sent": "Email sent",

      // customers
      customers_page_title: "Clients management | Angloville CRM",
      customer: "Client",
      customers: "Clients",
      customers_list: "Clients list",
      ap_count: "Applications",
      pp_count: "Participants",
      last_program: "Last Program",
      recent_applications: "Recent Applications",
      first_name: "First Name",
      last_name: "Last Name",
      last_packet: "Last Packet",
      participant_note: "Participant Note",

      // points of sell
      point_of_sell_page_title: "Points of Sell management | Angloville CRM",
      point_of_sell_search_for: "Search for point of sell...",
      point_of_sell_list: "Points of Sell list",
      point_of_sell_details: "Point of Sell Details",

      // program packets
      program_packet_add: "Add Packet",
      program_packet_sm: "program packet",
      program_packet: "Program Packet",

      // program packet templates
      program_packet_templates_page_title:
        "Program Packet Templates management | Angloville CRM",
      program_packet_templates_list: "Program Packet Templates list",
      program_packet_templates_search_for:
        "Search for program packet template...",
      program_packet_template_details: "Program Packet Template Details",
      program_packet_template_sm: "program packet template",
      program_packet_templates_add_new_program_packet_template:
        "Add new program packet template",

      // program details
      program_completion_progress: "Program Completion Progress",
      hotel_assign: "Assign Venue",
      coordinator_assign: "Assign Coordinator",
      polish_team_meeting: "Polish Team Meeting",
      ns_team_meeting: "NS Team Meeting",
      meeting_points: "Meeting Points",
      program_packets: "Program Packets",
      packet: "Packet",
      total_price: "Total Price",
      participants: "Participants",
      participants_assign: "Assign Participants",
      native_speakers_assign: "Assign Native Speakers",
      status: "Status",
      progress: "Progress",
      pp_upsells: "PP Upsells",
      pp_upsell_assign: "Assign PP Upsell",
      ip_upsells: "IP Upsells",
      ip_upsell_assign: "Assign IP Upsell",
      discounts: "Discounts",
      discount_name: "Discount Name",
      date_range: "Date Range",
      discount_value: "Discount Value",
      international_participants: "International Participants",
      ip_tour: "IP Tour",
      program_start_hour: "Program Start Hour",
      program_date_modified: "Program Date Modified",
      programs_edit_program: "Edit Program",
      edit_basic_program_data: "Edit basic program data",

      // installment plans
      installment_plans_page_title:
        "Installment Plans management | Angloville CRM",
      installment_plans_search_for: "Search for installment plan...",
      installments: "Installments",
      installment_plan_sm: "installment plan",
      installment_plans_add_new_installment_plan: "Add new installment plan",
      installments_manage: "Manage Installments",
      installment_plans_manage: "Manage Installment Plans",
      installment_prices_manage: "Manage Installment Prices for %s",
      installment_plan_add: "Add Installment Plan",
      define_installment_prices: "Define Installment Prices",
      click_to_set_installment_prices: "Click to set installment prices",
      installment_plans_list: "Installment Plans list",
      add_plan: "Add Plan",
      installmentsPaid: "Installments Paid",
      installmentsCount: "Installments Count",

      // international participants types
      ip: "IP",
      non_ns_ip: "NNS",
      ns: "NS",
      esl: "ESL",

      // international participants
      ip_participants_page_title: "IP Participants management | Angloville CRM",
      ip_participants_search_for: "Search for IP participant...",
      ip_participants_card_title: "IP Participants management",
      ip_participant: "IP Participant",
      ip_participants_add_new: "Add new IP participant",
      ip_participant_details: "IP Participant Details",
      ip_participants_edit: "Edit IP participant",
      block: "Block",
      unblock: "Unblock",
      gender: "Gender",
      male: "Male",
      female: "Female",
      country_of_origin: "Country of Origin",
      phone_number: "Phone Number",
      date_of_birth: "Date of Birth",
      preferred_diet: "Preferred Diet",
      customerId: "Customer ID",
      countryOfOrigin: "Country of Origin",
      phonePrefix: "Phone Prefix",
      phoneNumber: "Phone Number",
      dateOfBirth: "Date of Birth",
      documents: "Documents",
      id: "ID",
      passport: "Passport",
      residence_card: "Residence Card",
      dbs_check: "DBS Check",
      travel_details: "Travel Details",
      total_sum: "Total Sum",
      total_capacity: "Total Capacity",
      vegetarian: "Vegetarian",
      regular: "Regular",
      assignIpParticipant: "Assign IP Participant",
      internationalParticipants: "International Participants",
      action: "Action",
      participant_search_for: "Search for participant...",
      international_participant_search_for:
        "Search for international participant...",
      af_participants_data: "AF Participants Data",
      af_summary: "AF Summary",
      af_billing_data: "AF Billing Data",
      af_participant_upsells: "AF Participant Upsells",
      last_login: "Last Login",
      diet: "Diet",
      insurance: "Insurance",
      sum: "Sum",

      // pp applications
      add_internal_comment: "Add Internal Comment",
      internal_comment_content: "Internal Comment Content",

      // error popup
      error_details: "Error Details",
      error_popup_paragraph: `If you believe this is an error, please contact IT support or your system administrator. They will be able to assist you in resolving this issue. Thank you for your understanding and patience.`,

      // validation messages
      select_user: "Please select user",
      select_type: "Please select type",
      select_group_type: "Please select group type",
      select_meeting_point: "Please select meeting point",
      select_option: "Please select an option",
      select_at_least_one_option: "Please select at least one option",
      select_hotel: "Please select venue",
      enter_hotel: "Please enter venue name",
      enter_hotel_address: "Please enter venue address",
      enter_zip_code: "Please enter zip code",
      enter_city: "Please enter city",
      enter_additional_price: "Please enter additional price",
      invalid_city_name: "Invalid city name",
      enter_map_url: "Please enter map url",
      map_url_too_short: "Map URL is too short",
      enter_contact_info: "Please enter contact info",
      enter_valid_hour: "Please enter valid hour",
      additional_price_too_small:
        "Additional price must be greater or equal to 0",
      enter_user_first_name: "Please enter user first name",
      invalid_length: "Invalid length",
      invalid_mail: "Invalid email address",
      mail_required: "Please enter user email",
      name_required: "Please enter name",
      availability_days_required: "Please enter availability days",
      due_days_required: "Please enter due days",
      currency_required: "Please enter currency",
      type_required: "Please enter type",
      point_of_sell_required: "Please enter point of sell",
      installment_count_required: "Please enter installment count",
      max_installment_count_message: "Max installment count is 10",
      installment_plan_details: "Installment Plan Details",
      select_installment_plan: "Please select the installment plan",
      pp_capacity_required: "Please enter PP capacity",
      ip_capacity_required: "Please enter IP capacity",
      program_packet_template_required: "Please select program packet template",
      upsell_required: "Please select the upsell",
      meeting_required: "Please select the meeting",
      hotel_required: "Please select the venue",
      enter_upsell_type: "Please enter the upsell type",
      enter_upsell_name: "Please enter the upsell name",
      enter_upsell_price: "Please enter the upsell price",
      enter_upsell_installment_position:
        "Please enter the installment position",
      label_required: "Please enter label",
      pp_min_age_required: "Please enter PP min age",
      pp_max_age_required: "Please enter PP max age",
      ip_min_age_required: "Please enter IP min age",
      ip_max_age_required: "Please enter IP max age",
      target_audience_required: "Please enter target audience",
      business_unit_required: "Please enter business unit",
      file_name_required: "Please enter file name",
      select_installment_position: "Please select installment position",
      participants_meeting_updated: "Participants Meeting updated successfully",
      native_speakers_meeting_updated:
        "Native Speakers Meeting updated successfully",
      min_installment_count_message: "Min installment count is 2",
      min_availability_days_message: "Min availability days is 1",
      min_due_days_message: "Min due days is 1",
      min_currency_message: "Min currency length is 3",
      price_must_be_integer_or_proper_float:
        "Price must be an integer or have exactly one decimal place",
      due_days_order_message: "Due days must be in descending order",
      businessUnitIdRequired: "Business Unit ID is required",
      nameMinLength: "Name must be at least {min} characters",
      nameRequired: "Name is required",
      endDateRequired: "End Date is required",
      startDateRequired: "Start Date is required",
      healthAndSafetyCapacityRequired: "Health and Safety Capacity is required",
      healthAndSafetyCapacityPositive:
        "Health and Safety Capacity must be a positive number",
      healthAndSafetyCapacityInteger:
        "Health and Safety Capacity must be an integer",
      seasonMinLength: "Season must be at least {min} characters",
      seasonRequired: "Season is required",
      program_packet_required: "Program Packet is required",
      ip_required: "IP is required",
      required_field: "This field is required",
      name_selection_is_required: "Name selection is required",
      note_cannot_be_empty: "Note cannot be empty",

      // success messages
      installment_plan_added: "Installment plan added successfully",
      program_packet_added: "Program packet added successfully",
      hotel_assigned: "Venue assigned successfully",
      coordinator_assigned: "Coordinator assigned successfully",
      coordinator_detached: "Coordinator detached successfully",
      health_and_safety_assigned: "Health and safety assigned successfully",
      installment_plan_detached: "Installment plan detached successfully",
      meeting_detached: "Meeting detached successfully",
      upsell_assigned: "Upsell assigned successfully",
      upsell_detached: "Upsell detached successfully",
      health_and_safety_detached: "Health and safety detached successfully",
      program_packet_deleted: "Program packet deleted successfully",
      prices_defined: "Prices defined successfully",
      ns_team_meeting_deleted: "NS Team Meeting deleted successfully",
      polish_team_meeting_deleted: "Polish Team Meeting deleted successfully",
      program_hotel_removed: "Venue removed from program successfully",
      meeting_assigned: "Meeting assigned successfully",
      attachment_added: "Attachment added successfully",
      attachment_removed: "Attachment removed successfully",
      participants_meeting_created: "Participants Meeting created successfully",
      participants_meeting_deleted: "Participants Meeting deleted successfully",
      native_speakers_meeting_created:
        "Native Speakers Meeting created successfully",
      native_speakers_meeting_deleted:
        "Native Speakers Meeting deleted successfully",
      program_status_updated: "Program status updated successfully",
      comment_added: "Comment added successfully",

      // error messages
      program_status_update_failed: "Failed to update program status",

      // access
      you_dont_have_access: "You don't have access to this resource",
      assign_hotel_to_program_first: "Assign a venue to the program first",
    },
  },
  // Add more languages here
};

export default strings;
