import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttachmentListData,
  postAttachment,
} from "../../store/attachments/action";
import { createAttachmentSchema } from "../../models/attachments";
import {
  handleFileChange,
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import { FormFilePicker } from "Components/Entity/FormFilePicker";
import { convertFileToBase64 } from "helpers/files_helper";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";

const AddAttachment = ({ visibility, toggle }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { user, activeBusinessUnit } = useSelector(
    (rootState) => rootState.Profile,
  );
  const businessUnitOptions = [{ value: "all", label: "All" }].concat(
    (user.businessUnits || []).map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      file: null,
      name: "",
      businessUnitId:
        activeBusinessUnit.id === "all" ? "" : activeBusinessUnit.id,
    },
    validationSchema: createAttachmentSchema,
    onSubmit: (values) => {
      setLoading(true);
      convertFileToBase64(values.file)
        .then((base64) => {
          dispatch(
            postAttachment(
              {
                fileBase64: base64,
                name: values.name,
                businessUnitId:
                  values.businessUnitId === "all"
                    ? undefined
                    : values.businessUnitId,
              },
              () => {
                dispatch(getAttachmentListData());
                toggle(false);
                setLoading(false);
              },
            ),
          );
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
  });

  // Form validation (api)
  useEffect(() => {
    if (validation.errors == null) {
      return;
    }
    setValidationErrors(validation, validation.errors);
  }, [validation.errors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("attachments_add_new_attachment")}
        formId={"add-attachment"}
        validationRule={validation.isValid}
        buttonLabel={getString("add")}
        loading={loading}
      >
        <Form
          id="add-attachment"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              {activeBusinessUnit.id === "all" && (
                <FormSelect
                  name="businessUnitId"
                  label={getString("business_unit")}
                  defaultValue={businessUnitOptions.find(
                    (b) => b.value === validation.values.businessUnitId,
                  )}
                  value={validation.values.businessUnitId}
                  onChange={(selectedOption) =>
                    handleSelectChange(
                      validation,
                      selectedOption,
                      "businessUnitId",
                    )
                  }
                  isMulti={false}
                  options={businessUnitOptions}
                  error={validation.errors["businessUnitId"]}
                />
              )}
              <FormTextField
                label={getString("file_name")}
                name={"name"}
                value={validation.values["name"]}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors["name"]}
                touched={validation.touched["name"]}
              />
              <FormFilePicker
                name="file"
                label={getString("upload_file")}
                error={validation.errors["file"]}
                onFileChange={(e) => {
                  handleFileChange(validation, e, "file");
                }}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddAttachment;
