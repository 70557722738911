import { useContext } from "react";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { formatNumber } from "helpers/utils";

export const PacketTotalPrice = ({ programPacket }) => {
  const { programData } = useContext(ProgramDetailsContext);

  const getCurrencyTotals = () => {
    const currencyTotals = {};
    const matchingPrices = programData.programPrices.filter(
      (price) => price.programPacketId === programPacket.id,
    );

    const matchingInstallmentPlans = matchingPrices.map((price) => {
      return {
        ...price,
        installmentPlan: programData.installmentPlans.find(
          (installmentPlan) => installmentPlan.id === price.installmentPlanId,
        ),
      };
    });

    const firstMatchinInstallmentPlan = matchingInstallmentPlans.find(
      (installmentPlan) => {
        return installmentPlan?.installmentPlan;
      },
    );

    firstMatchinInstallmentPlan?.prices.forEach((price) => {
      const installment =
        firstMatchinInstallmentPlan.installmentPlan?.installments.find(
          (installment) => installment.id === price.installmentId,
        );

      if (installment) {
        const currency = installment?.currency;

        if (!currencyTotals[currency]) {
          currencyTotals[currency] = 0;
        }

        currencyTotals[currency] += price.price;
      }
    });

    return currencyTotals;
  };

  return (
    <div className="packet-total-price">
      {Object.keys(getCurrencyTotals()).length === 0 && "-"}
      {Object.entries(getCurrencyTotals()).map(([currency, total]) => (
        <div key={currency}>
          <strong>{formatNumber(total)}</strong> {currency}
        </div>
      ))}
    </div>
  );
};
