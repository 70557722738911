// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-installments {
  display: flex;
  gap: 8px;
}
.program-installments__dot {
  height: 32px;
  width: 32px;
  font-weight: bold;
  display: flex;
  align-items: center;
  background: var(--vz-red);
  color: white;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}
.program-installments__dot--filled {
  background: #299cdb;
}
.program-installments__dot--outdated {
  background: #878a99;
  color: white;
}
.program-installments__dot--not-clickable {
  cursor: default;
}

.plan-installments-tooltip li {
  text-align: left;
}
.plan-installments-tooltip ul {
  padding-left: 1rem;
  margin-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/components/ProgramInstallments/ProgramInstallments.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AACE;EACE,YAAA;EACA,WAAA;EACA,iBAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AACJ;AACI;EACE,mBAAA;AACN;AACI;EACE,mBAAA;EACA,YAAA;AACN;AACI;EACE,eAAA;AACN;;AAKE;EACE,gBAAA;AAFJ;AAIE;EACE,kBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".program-installments {\n  display: flex;\n  gap: 8px;\n\n  &__dot {\n    height: 32px;\n    width: 32px;\n    font-weight: bold;\n    display: flex;\n    align-items: center;\n    background: var(--vz-red);\n    color: white;\n    justify-content: center;\n    border-radius: 100%;\n    cursor: pointer;\n\n    &--filled {\n      background: #299cdb;\n    }\n    &--outdated {\n      background: #878a99;\n      color: white;\n    }\n    &--not-clickable {\n      cursor: default;\n    }\n  }\n}\n\n.plan-installments-tooltip {\n  li {\n    text-align: left;\n  }\n  ul {\n    padding-left: 1rem;\n    margin-bottom: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
