// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-adder {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.attachment-adder__select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.attachment-adder__select .ri-delete-bin-line {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/Upsells/UpsellAttachmentsList/partials/AttachmentAdder/AttachmentAdder.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACI;EACE,eAAA;AACN","sourcesContent":[".attachment-adder {\n  display: flex;\n  flex-direction: column;\n  gap: 12px;\n\n  &__select {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 12px;\n\n    .ri-delete-bin-line {\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
