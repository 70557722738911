import classNames from "classnames";
import "./Preloader.scss";
import Loader from "../Loader";

export const PreloaderWrap = ({ h100 = false, height }) => {
  const style = height ? { height: `${height}px` } : {};

  return (
    <div
      className={classNames(["preloader-wrap", h100 && "preloader-wrap--h100"])}
      style={style}
    >
      <Loader />
    </div>
  );
};
