import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import { useState } from "react";
import { DetailsBox } from "../../DetailsBox";

export const HotelPreview = ({ hotel }) => {
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion open={open || "0"} toggle={toggle} className="hotel-preview">
      <AccordionItem>
        <AccordionHeader targetId="1">
          <span>
            {hotel.name}, {hotel.city}
          </span>
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <DetailsBox item={hotel} key={hotel?.id} />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
