// business units
export const GET_BUSINESS_UNIT_LIST = "/business-unit";

// hotels
export const GET_HOTEL_LIST = "/hotel";
export const GET_HOTEL = "/hotel/%s";
export const POST_HOTEL = "/hotel";
export const EDIT_HOTEL = "/hotel/%s";
export const ARCHIVE_HOTEL = "/hotel/%s";
export const RESTORE_HOTEL = "/hotel/%s/restore";

// meeting points
export const GET_MEETING_POINT_LIST = "/meeting-point";
export const GET_MEETING_POINT = "/meeting-point/%s";
export const POST_MEETING_POINT = "/meeting-point";
export const EDIT_MEETING_POINT = "/meeting-point/%s";
export const ARCHIVE_MEETING_POINT = "/meeting-point/%s";
export const RESTORE_MEETING_POINT = "/meeting-point/%s/restore";

// meetings
export const GET_MEETING_LIST = "/meeting";
export const GET_MEETINGPOINT = "/meeting/%s";
export const POST_MEETING = "/meeting";
export const EDIT_MEETING = "/meeting/%s";
export const ARCHIVE_MEETING = "/meeting/%s";
export const RESTORE_MEETING = "/meeting/%s/restore";

// coordinators
export const GET_COORDINATOR_LIST = "/coordinator";
export const GET_COORDINATOR = "/coordinator/%s";
export const POST_COORDINATOR = "/coordinator";
export const EDIT_COORDINATOR = "/coordinator/%s";
export const ARCHIVE_COORDINATOR = "/coordinator/%s";
export const RESTORE_COORDINATOR = "/coordinator/%s/restore";

// programs
export const GET_PROGRAM_LIST = "/program";
export const GET_PROGRAM = "/program/%s";
export const POST_PROGRAM = "/program";
export const EDIT_PROGRAM = "/program/%s";
export const DELETE_PROGRAM = "/program/%s";
export const DUPLICATE_PROGRAM = "/program/%s/duplicate";
export const ADD_PROGRAM_PACKET = "/program/%s/program-packet";
export const DELETE_PROGRAM_PACKET = "/program/%s/program-packet/%s";
export const ADD_INSTALLMENT_PLAN = "/program/%s/installment-plan";
export const DEFINE_PRICES_FOR_PACKET_INSTALLMENT =
  "/program/%s/program-packet/%s/program-price";
export const DETACH_INSTALLMENT_PLAN = "/program/%s/installment-plan/%s";
export const ASSIGN_PROGRAM_UPSELL = "/program/%s/upsell";
export const DETACH_PROGRAM_UPSELL = "/program/%s/upsell/%s/%s";
export const ASSIGN_PROGRAM_HOTEL = "/program/%s/hotel";
export const DETACH_PROGRAM_HOTEL = "/program/%s/hotel";
export const CREATE_PROGRAM_MEETING = "/program/%s/program-meeting";
export const UPDATE_PROGRAM_MEETING = "/program/%s/program-meeting/%s";

export const DELETE_PROGRAM_MEETING = "/program/%s/program-meeting/%s";
export const ASSIGN_COORDINATOR = "/program/%s/program-packet/%s/coordinator";
export const DETACH_COORDINATOR =
  "/program/%s/program-packet/%s/coordinator/%s";
export const ASSIGN_HEALTH_AND_SAFETY = "/program/%s/health-and-safety";
export const DETACH_HEALTH_AND_SAFETY = "/program/%s/health-and-safety/%s";

export const ADD_PROGRAM_DISCOUNT = "/program/%s/program-discount";
export const DELETE_PROGRAM_DISCOUNT = "/program/%s/program-discount/%s";
export const UPDATE_PROGRAM_DISCOUNT = "/program/%s/program-discount/%s";

export const UPDATE_PROGRAM_STATUS = "/program/%s/status";

// attachments
export const GET_ATTACHMENT_LIST = "/attachment";
export const GET_ATTACHMENT = "/attachment/%s";
export const POST_ATTACHMENT = "/attachment";
export const EDIT_ATTACHMENT = "/attachment/%s";
export const ARCHIVE_ATTACHMENT = "/attachment/%s";
export const RESTORE_ATTACHMENT = "/attachment/%s/restore";

// payment methods
export const GET_PAYMENT_METHOD_LIST = "/payment-method";
export const GET_PAYMENT_METHOD = "/payment-method/%s";

// upsells
export const GET_UPSELL_LIST = "/upsell";
export const GET_UPSELL = "/upsell/%s";
export const POST_UPSELL = "/upsell";
export const EDIT_UPSELL = "/upsell/%s";
export const ARCHIVE_UPSELL = "/upsell/%s";
export const RESTORE_UPSELL = "/upsell/%s/restore";
export const ADD_UPSELL_ATTACHMENTS = "/upsell/%s/attachment";
export const REMOVE_UPSELL_ATTACHMENT = "/upsell/%s/attachment/%s";

// Applications
export const GET_APPLICATIONS_LIST = "/application";
export const GET_APPLICATION = "/application/%s";
export const GET_APPLICATION_ACTIVITY = "/application/%s/activity";
export const GET_APPLICATION_ACTIVITY_EMAIL =
  "/application/%s/activity/email/%s";
export const POST_ENTITY_NOTE = "/entity-note";
export const DELETE_ENTITY_NOTE = "/entity-note/%s";

// customers
export const GET_CUSTOMER_LIST = "/crm/customer";
export const GET_CUSTOMER = "/crm/customer/%s";
export const GET_CUSTOMER_PARTICIPANTS = "/crm/customer/%s/participants";

// points of sell
export const GET_POINT_OF_SELL_LIST = "/point-of-sell";
export const GET_POINT_OF_SELL = "/point-of-sell/%s";

// programPacketTemplates
export const GET_PROGRAM_PACKET_TEMPLATE_LIST = "/program-packet-template";
export const GET_PROGRAM_PACKET_TEMPLATE = "/program-packet-template/%s";
export const POST_PROGRAM_PACKET_TEMPLATE = "/program-packet-template";
export const EDIT_PROGRAM_PACKET_TEMPLATE = "/program-packet-template/%s";
export const ARCHIVE_PROGRAM_PACKET_TEMPLATE = "/program-packet-template/%s";
export const RESTORE_PROGRAM_PACKET_TEMPLATE =
  "/program-packet-template/%s/restore";
export const ADD_PROGRAM_PACKET_TEMPLATE_ATTACHMENTS =
  "/program-packet-template/%s/attachment";
export const REMOVE_PROGRAM_PACKET_TEMPLATE_ATTACHMENT =
  "/program-packet-template/%s/attachment/%s";

// InstallmentPlans
export const GET_INSTALLMENT_PLAN_LIST = "/installment-plan";
export const GET_INSTALLMENT_PLAN = "/installment-plan/%s";
export const POST_INSTALLMENT_PLAN = "/installment-plan";
export const DELETE_INSTALLMENT_PLAN = "/installment-plan/%s";

// ipParticipants
export const GET_IP_PARTICIPANT_LIST = "/international-participant";
export const GET_IP_PARTICIPANT = "/international-participant/%s";
export const POST_IP_PARTICIPANT = "/international-participant";
export const EDIT_IP_PARTICIPANT = "/international-participant/%s";
export const ARCHIVE_IP_PARTICIPANT = "/international-participant/%s";
export const RESTORE_IP_PARTICIPANT = "/international-participant/%s/restore";
export const BLOCK_IP_PARTICIPANT = "/international-participant/%s";
export const POST_IP_ATTACHMENT = "/international-participant/%s/documents";
export const REMOVE_IP_ATTACHMENT =
  "/international-participant/%s/documents/%s";
export const ASSIGN_IP_TO_PROGRAM_PACKET =
  "/program-packet/%s/international-participant";
export const REMOVE_IP_FROM_PROGRAM_PACKET =
  "/program-packet/%s/international-participant/%s";
