import { APIClient } from "../api_helper";

import * as url from "./endpoints_helper";
import formatter from "sprintf-js";

const apiClient = new APIClient(process.env.REACT_APP_ACCOUNT_API_URL);
export const setAuthToken = (token) => apiClient.setAuthorization(token);

// auth
export const googleGrantAuth = (data) =>
  apiClient.create(url.GOOGLE_GRANT_AUTH, data);
export const fetchProfile = () => apiClient.get(url.GET_PROFILE);

// users
export const getUserList = () => apiClient.get(url.GET_USER_LIST);
export const postUser = (data, onSuccess) =>
  apiClient.create(url.POST_USER, data).then((r) => onSuccess && onSuccess());
export const editUser = (id, data, onSuccess) =>
  apiClient
    .put(formatter.sprintf(url.EDIT_USER, id), data)
    .then((r) => onSuccess && onSuccess());
export const deleteUser = (id, onSuccess) =>
  apiClient
    .delete(formatter.sprintf(url.DELETE_USER, id))
    .then((r) => onSuccess && onSuccess());
