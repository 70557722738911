import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import "./MeetingPointPreview.scss";
import { useState } from "react";
import { DetailsBox } from "../../DetailsBox";

export const MeetingPointPreview = ({ meetingPoint }) => {
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      open={open || "0"}
      toggle={toggle}
      className="meetingpoint-preview"
    >
      <AccordionItem>
        <AccordionHeader targetId="1">
          <span>
            <strong>{meetingPoint.city}</strong>, {meetingPoint.address}
          </span>
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <DetailsBox item={meetingPoint} key={meetingPoint?.id} />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
