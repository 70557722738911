import React, { useContext, useState } from "react";
import "./InternationalParticipantsTab.scss";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { Badge } from "Components/Common/Badges/Badges";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { AssignIpCanvas } from "../modals/AssignIpCanvas";
import { DeleteIpModal } from "../modals/DeleteIpModal";
import TableContainer from "Components/Common/TableContainerReactTable";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";

export const InternationalParticipantsTab = () => {
  const { programData } = useContext(ProgramDetailsContext);
  const { internationalParticipants } = programData;

  const processedInternationalParticipants = internationalParticipants.map(
    (packet) => {
      const data = packet.data.map((participant) => {
        return {
          ...participant,
          action: participant.id,
          programPacketId: packet.programPacketId,
        };
      });

      return {
        ...packet,
        data,
      };
    },
  );

  const [assignIpCanvasVisible, setAssignIpCanvasVisible] = useState(false);
  const [deleteIpModalVisible, setDeleteIpModalVisible] = useState(false);
  const [selectedParticipantId, setSelectedParticipantId] = useState(null);
  const [selectedProgramPacketId, setSelectedProgramPacketId] = useState(null);

  const handleDeleteClick = (participantId, programPacketId) => {
    setSelectedParticipantId(participantId);
    setSelectedProgramPacketId(programPacketId);
    setDeleteIpModalVisible(true);
  };

  const hasProgramUpdatePermission = useHasPermission("PROGRAM_UPDATE");

  const columns = [
    {
      Header: <FormattedString id="firstName" />,
      accessor: "firstName",
    },
    {
      Header: <FormattedString id="lastName" />,
      accessor: "lastName",
    },
    {
      Header: <FormattedString id="gender" />,
      accessor: "gender",
      Cell: ({ row }) => (
        <span className={`gender-column ${row.original.gender.toLowerCase()}`}>
          <i
            className={`ri-${row.original.gender === "Male" ? "men" : "women"}-line me-1`}
          ></i>
          <FormattedString id={row.original.gender} />
        </span>
      ),
    },
    {
      Header: <FormattedString id="countryOfOrigin" />,
      accessor: "countryOfOrigin",
    },
    {
      Header: <FormattedString id="dateOfBirth" />,
      accessor: "dateOfBirth",
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
    },
    {
      Header: <FormattedString id="preferredDiet" />,
      accessor: "preferredDiet",
      Cell: ({ row }) => (
        <span
          className={`preferred-diet-column ${row.original.preferredDiet.toLowerCase().replace(/\s+/g, "-")}`}
        >
          <FormattedString id={row.original.preferredDiet} />
        </span>
      ),
    },
    {
      Header: <FormattedString id="type" />,
      accessor: "type",
      Cell: ({ row }) => <Badge size="medium">{row.original.type}</Badge>,
    },
    {
      Header: (
        <div className="text-end">
          <FormattedString id="action" />
        </div>
      ),
      accessor: "action",
      disableSortBy: true,
      Cell: ({ row }) => (
        <UncontrolledDropdown direction="down" className="text-end">
          <DropdownToggle
            tag="a"
            className="btn btn-light btn-icon"
            id="dropdownMenuLink15"
            role="button"
          >
            <i className="ri-more-2-fill"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              <i className="ri-eye-fill me-2 align-middle text-muted" />
              <FormattedString id="view" />
            </DropdownItem>
            <DropdownItem>
              <i className="ri-download-2-fill me-2 align-middle text-muted" />
              <FormattedString id="download" />
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              onClick={() =>
                handleDeleteClick(row.original.id, row.original.programPacketId)
              }
              disabled={!hasProgramUpdatePermission}
            >
              <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
              <FormattedString id="delete" />
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
      style: { textAlign: "right" },
    },
  ];

  return (
    <TabPane
      tabId="international-participants"
      className="international-participants-tab"
    >
      {processedInternationalParticipants.map((packet) => (
        <Card key={packet.programPacketId}>
          <CardBody>
            <div className="d-flex align-items-center mb-4">
              <h5 className="card-title flex-grow-1 mb-0">
                {packet.programPacketName}
              </h5>
              <div className="flex-shrink-0">
                <WithPermission permissionName="PROGRAM_UPDATE">
                  <Label
                    htmlFor="formFile"
                    className="btn btn-danger"
                    onClick={() => setAssignIpCanvasVisible(true)}
                  >
                    <i className="ri-user-add-fill me-1 align-bottom"></i>
                    <FormattedString id="assignIpParticipant" />
                  </Label>
                </WithPermission>
              </div>
            </div>

            <TableContainer
              columns={columns}
              data={packet.data}
              isPagination={true}
              isGlobalFilter={true}
              isBordered={false}
              customPageSize={10}
              className="custom-header-css table align-middle table-nowrap"
              tableClassName="table-centered align-middle table-nowrap mb-0"
              theadClassName="text-muted table-light"
              SearchPlaceholder={getString(
                "international_participant_search_for",
              )}
            />
          </CardBody>
        </Card>
      ))}
      {assignIpCanvasVisible && (
        <AssignIpCanvas
          visible={assignIpCanvasVisible}
          setVisible={setAssignIpCanvasVisible}
        />
      )}
      {deleteIpModalVisible && (
        <DeleteIpModal
          visible={deleteIpModalVisible}
          setVisible={setDeleteIpModalVisible}
          itemId={selectedParticipantId}
          programPacketId={selectedProgramPacketId}
        />
      )}
    </TabPane>
  );
};
