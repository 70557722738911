import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { SET_BUSINESS_UNIT } from "store/auth/profile/actionTypes";

const ProfileDropdown = () => {
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const { user, activeBusinessUnit } = useSelector((state) => state.Profile);
  const dispatch = useDispatch();

  const handleBusinessUnitChange = (unit) => {
    dispatch({ type: SET_BUSINESS_UNIT, payload: unit });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn shadow-none">
          <span className="d-flex align-items-center">
            <i className="mdi mdi-account-circle text-muted header-profile-user flex-grow-1"></i>
            <span className="text-start ms-xl-1">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user.firstName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {activeBusinessUnit.name}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Active Business Unit:</h6>
          <DropdownItem
            onClick={() => handleBusinessUnitChange({ id: "all", name: "All" })}
            active={activeBusinessUnit.id === "all"}
          >
            {activeBusinessUnit.id === "all" ? <strong>All</strong> : "All"}
            {activeBusinessUnit.id === "all" && (
              <i className="mdi mdi-check ms-1"></i>
            )}
          </DropdownItem>
          {user.businessUnits.map((unit) => (
            <DropdownItem
              key={unit.id}
              onClick={() => handleBusinessUnitChange(unit)}
              active={unit.id === activeBusinessUnit.id}
            >
              {unit.id === activeBusinessUnit.id ? (
                <strong>{unit.name}</strong>
              ) : (
                unit.name
              )}
              {unit.id === activeBusinessUnit.id && (
                <i className="mdi mdi-check ms-1"></i>
              )}
            </DropdownItem>
          ))}
          <div className="dropdown-divider"></div>
          <DropdownItem
            href={process.env.PUBLIC_URL + "/pages-profile-settings"}
          >
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem>
          <DropdownItem href={process.env.PUBLIC_URL + "/logout"}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
