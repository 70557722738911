import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_CUSTOMERS_LIST_DATA,
  SET_CURRENT_PAGE,
} from "./actionType";

export const customersApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const customersApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getCustomersListData = (page = 1, searchTerm = "") => ({
  type: GET_CUSTOMERS_LIST_DATA,
  payload: { page, searchTerm },
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
});
