import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  useRowSelect,
} from "react-table";
import { Table, Row, Col, CardBody } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { PreloaderWrap } from "./Preloader/Preloader";
import { LinkButton } from "./LinkButton/LinkButton";
import classNames from "classnames";

function GlobalFilter({ globalFilter, setGlobalFilter, SearchPlaceholder }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 400);

  return (
    <React.Fragment>
      <CardBody>
        <form>
          <Row className="g-3">
            <Col>
              <div className="search-box me-2 mb-2 d-inline-block col-12">
                <input
                  onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                  }}
                  id="search-bar-0"
                  type="text"
                  className="form-control search /"
                  placeholder={SearchPlaceholder}
                  value={value || ""}
                />
                <i className="bx bx-search-alt search-icon"></i>
              </div>
            </Col>
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainerExternalPagination = ({
  columns,
  data,
  count,
  currentPage,
  totalPages,
  globalFilter,
  setGlobalFilter,
  onPageChange,
  isGlobalSearch,
  isGlobalFilter,
  pageSize = 10,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  loading,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (
      column.isSortedDesc ? (
        <span>&#8593;</span>
      ) : (
        <span>&#8595;</span>
      )
    ) : (
      ""
    );
  };

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 11; // 5 previous, 1 current, 5 next
    const halfMaxPagesToShow = Math.floor(maxPagesToShow / 2);

    if (totalPages <= maxPagesToShow) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - halfMaxPagesToShow);
      const endPage = Math.min(totalPages, currentPage + halfMaxPagesToShow);

      if (startPage > 1) {
        pageNumbers.push(1);
        if (startPage > 2) {
          pageNumbers.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push("...");
        }
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers.map((page, index) => (
      <li key={index} className="page-item">
        {page === "..." ? (
          <span className="page-link">...</span>
        ) : (
          <LinkButton
            className={classNames("page-link", {
              active: currentPage === page,
              disabled: loading && currentPage !== page,
            })}
            onClick={(e) => {
              e.preventDefault();
              onPageChange(page);
            }}
          >
            {page}
          </LinkButton>
        )}
      </li>
    ));
  };

  const tableContainerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (tableContainerRef.current) {
      setContainerHeight(tableContainerRef.current.clientHeight);
    }
  }, [data, loading]);

  const startIndex = (currentPage - 1) * pageSize + 1;
  const endIndex = Math.min(currentPage * pageSize, count);

  return (
    <Fragment>
      {(isGlobalSearch || isGlobalFilter) && (
        <Row className="mb-3">
          {isGlobalSearch && (
            <Col md={1}>
              <select
                className="form-select"
                value={pageSize}
                onChange={onChangeInSelect}
              >
                {[10, 20, 30, 40, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </Col>
          )}
          {isGlobalFilter && (
            <GlobalFilter
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              SearchPlaceholder={SearchPlaceholder}
            />
          )}
        </Row>
      )}

      <div
        className={divClass}
        ref={tableContainerRef}
        style={{ minHeight: loading && containerHeight }}
      >
        {loading ? (
          <PreloaderWrap height={containerHeight} />
        ) : (
          <Table hover {...getTableProps()} className={tableClass}>
            <thead className={theadClass}>
              {headerGroups.map((headerGroup) => (
                <tr
                  className={trClass}
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.id}
                      className={thClass}
                      {...column.getSortByToggleProps()}
                    >
                      {column.render("Header")}
                      {generateSortingIndicator(column)}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Fragment key={row.getRowProps().key}>
                    <tr data-testid={row.original.id}>
                      {row.cells.map((cell) => {
                        return (
                          <td key={cell.id} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>

      <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold ms-1">{startIndex}</span> -{" "}
            <span className="fw-semibold">{endIndex}</span> of{" "}
            <span className="fw-semibold">{count}</span> Results
          </div>
        </div>
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
            <li
              className={
                currentPage === 1 || loading
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <LinkButton
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange(currentPage - 1);
                }}
              >
                Previous
              </LinkButton>
            </li>
            {renderPageNumbers()}
            <li
              className={
                currentPage === totalPages || loading
                  ? "page-item disabled"
                  : "page-item"
              }
            >
              <LinkButton
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange(currentPage + 1);
                }}
              >
                Next
              </LinkButton>
            </li>
          </ul>
        </div>
      </Row>
    </Fragment>
  );
};

TableContainerExternalPagination.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  isGlobalSearch: PropTypes.bool,
  isGlobalFilter: PropTypes.bool,
  customPageSize: PropTypes.number,
  tableClass: PropTypes.string,
  theadClass: PropTypes.string,
  trClass: PropTypes.string,
  thClass: PropTypes.string,
  divClass: PropTypes.string,
  SearchPlaceholder: PropTypes.string,
  loading: PropTypes.bool,
};

export default TableContainerExternalPagination;
