import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_HOTEL_LIST_DATA,
  POST_HOTEL,
  EDIT_HOTEL,
  ARCHIVE_HOTEL,
  RESTORE_HOTEL,
} from "./actionType";

export const hotelsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const hotelsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getHotelListData = () => ({
  type: GET_HOTEL_LIST_DATA,
  payload: {},
});

export const postHotel = (data, onSuccess) => ({
  type: POST_HOTEL,
  payload: { data, onSuccess },
});
export const editHotel = (id, data, onSuccess) => ({
  type: EDIT_HOTEL,
  payload: { id, data, onSuccess },
});

export const archiveHotel = (id, onSuccess) => ({
  type: ARCHIVE_HOTEL,
  payload: { id, onSuccess },
});

export const restoreHotel = (id, onSuccess) => ({
  type: RESTORE_HOTEL,
  payload: { id, onSuccess },
});
