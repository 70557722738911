import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import FormTextArea from "Components/Entity/FormTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { FormCreatableSelect } from "Components/Entity/FormCreatableSelect";
import { additionalNoteOptions } from "models/additionalNotes";

const AdditionalNote = ({
  handleSubmitNote,
  disabled,
  initialValues = { name: "", content: "" },
}) => {
  const [nameOptions, setNameOptions] = useState(() => {
    const defaultOptions = [...additionalNoteOptions];

    if (
      initialValues.name &&
      !defaultOptions.some((option) => option.value === initialValues.name)
    ) {
      defaultOptions.push({
        value: initialValues.name,
        label: getString(initialValues.name),
      });
    }

    return defaultOptions;
  });

  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("name_selection_is_required"),
      content: Yup.string().required("note_cannot_be_empty"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleSubmitNote(values).finally(() => {
        setLoading(false);
      });
    },
  });

  const handleSelectChange = (selectedOption) => {
    formik.setFieldValue("name", selectedOption.value);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setNameOptions((prevOptions) => [...prevOptions, newOption]);
    formik.setFieldValue("name", inputValue);
  };

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormCreatableSelect
        name="name"
        disabled
        label={getString("selected_name")}
        value={nameOptions.find((r) => r.value === formik.values.name)}
        defaultValue={nameOptions.find((r) => r.value === formik.values.name)}
        onChange={handleSelectChange}
        onCreateOption={handleCreateOption}
        isMulti={false}
        options={nameOptions}
        error={formik.touched.name && formik.errors.name}
      />
      <FormTextArea
        name="content"
        value={formik.values.content}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.content}
        disabled={loading || disabled}
      />
      {formik.dirty && (
        <div className="d-flex justify-content-center mt-3 gap-5">
          <LinkButton
            color="secondary"
            type="button"
            onClick={handleCancel}
            disabled={loading}
          >
            <i className="ri-close-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="cancel" />
          </LinkButton>
          <LinkButton
            color="primary"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <>
                <i className="ri-edit-line" style={{ marginRight: "4px" }}></i>
                <FormattedString id="update" />
              </>
            )}
          </LinkButton>
        </div>
      )}
    </form>
  );
};

export default AdditionalNote;
