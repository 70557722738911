import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import { useSelector } from "react-redux";

import "./InstallmentsPreview.scss";
import { useState } from "react";
import { PlanInstallmentsView } from "Components/Common/PlanInstallmentsView/PlanInstallmentsView";
import { SimplestTable } from "Components/Common/SimplestTable/SimplestTable";
import { getString } from "Components/Common/FormattedString";

export const InstallmentsPreview = ({ installments, installmentCount }) => {
  const { pointOfSellData } = useSelector((rootState) => rootState.PointOfSell);

  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      open={open || "0"}
      toggle={toggle}
      className="installments-preview"
    >
      <AccordionItem>
        <AccordionHeader targetId="1">
          <PlanInstallmentsView
            installments={installments}
            installmentCount={installmentCount}
          />
        </AccordionHeader>
        <AccordionBody accordionId="1">
          <SimplestTable
            data={installments}
            columns={[
              {
                header: getString("dueDays"),
                render: (installment) => <strong>{installment.dueDays}</strong>,
              },
              {
                header: getString("currency"),
                render: (installment) => (
                  <strong>{installment.currency}</strong>
                ),
              },
              {
                header: getString("type"),
                render: (installment) => <strong>{installment.type}</strong>,
              },
              {
                header: getString("pointOfSell"),
                render: (installment) => {
                  const pointOfSell = pointOfSellData.filter(
                    (item) => item.id === installment.pointOfSellId,
                  )[0];

                  return (
                    <>
                      <strong>{pointOfSell?.name}</strong>,{" "}
                      <strong>{pointOfSell?.paymentMethod.name}</strong>
                    </>
                  );
                },
              },
            ]}
          />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
