import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { restoreUpsell, getUpsellListData } from "../../store/upsells/action";
import { Modal } from "reactstrap";
import ConfirmRestoreBody from "../../Components/Entity/ConfirmRestoreBody";
import { getString } from "Components/Common/FormattedString";

const RestoreUpsell = ({ itemId, toggle, visibility }) => {
  const dispatch = useDispatch();

  const restoreUpsellEntry = useCallback(() => {
    dispatch(
      restoreUpsell(itemId, () => {
        dispatch(getUpsellListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmRestoreBody
        entityName={getString("upsell_sm")}
        toggle={toggle}
        restoreAction={restoreUpsellEntry}
      />
    </Modal>
  );
};

export default RestoreUpsell;
