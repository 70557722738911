import React from "react";
import {
  Button,
  Col,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
} from "reactstrap";

import "./OffcanvasRight.scss";

const OffcanvasRight = ({
  isOpen,
  toggle,
  title,
  formId,
  validationRule,
  loading = false,
  buttonLabel,
  children,
  onSubmit,
}) => {
  return (
    <React.Fragment>
      <Offcanvas
        isOpen={isOpen}
        toggle={toggle}
        direction="end"
        className="border-bottom offcanvas-right"
      >
        <OffcanvasHeader toggle={() => toggle(false)} id="offcanvasRightLabel">
          {title}
        </OffcanvasHeader>
        <OffcanvasBody className="p-0">{children}</OffcanvasBody>
        <div className="offcanvas-footer offcanvas-right__footer">
          <Button color="light" onClick={() => toggle(false)}>
            Back
          </Button>
          <Button
            form={formId}
            color="primary"
            disabled={!validationRule || loading}
            type="submit"
            onClick={onSubmit}
          >
            {loading ? (
              <Spinner size="sm" className="me-2">
                {" "}
                Loading...{" "}
              </Spinner>
            ) : null}{" "}
            {buttonLabel}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
};

export default OffcanvasRight;
