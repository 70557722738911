// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-button {
  border: 1px solid var(--vz-primary);
  color: var(--vz-primary);
  box-shadow: none;
  padding: 2px 6px;
}
.outline-button:hover, .outline-button:active, .outline-button:is(.btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active) {
  background: var(--vz-primary);
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/OutlineButton/OutlineButton.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,wBAAA;EACA,gBAAA;EACA,gBAAA;AACF;AACE;EAGE,6BAAA;EACA,WAAA;AADJ","sourcesContent":[".outline-button {\n  border: 1px solid var(--vz-primary);\n  color: var(--vz-primary);\n  box-shadow: none;\n  padding: 2px 6px;\n\n  &:hover,\n  &:active,\n  &:is(.btn.show, .btn:first-child:active, :not(.btn-check) + .btn:active) {\n    background: var(--vz-primary);\n    color: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
