// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gender-cell {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.gender-cell__item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 20px;
}
.gender-cell__item--male {
  background-color: #007bff;
}
.gender-cell__item--female {
  background-color: #ff69b4;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/cells/GenderCell/GenderCell.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;AACF;AACE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,WAAA;AACJ;AACI;EACE,yBAAA;AACN;AAEI;EACE,yBAAA;AAAN","sourcesContent":[".gender-cell {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n\n  &__item {\n    border-radius: 4px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    width: 20px;\n\n    &--male {\n      background-color: #007bff;\n    }\n\n    &--female {\n      background-color: #ff69b4;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
