import { getString } from "Components/Common/FormattedString";
import { useSelector } from "react-redux";

export const InstallmentPlanTooltipContent = ({ installment }) => {
  const { pointOfSellData } = useSelector((rootState) => rootState.PointOfSell);
  const pointOfSell = pointOfSellData.find(
    (item) => item.id === installment.pointOfSellId,
  );

  return (
    <ul>
      <li>
        <strong>{getString("dueDays")}</strong> - {installment?.dueDays}
      </li>
      <li>
        <strong>{getString("currency")}</strong> - {installment?.currency}
      </li>
      <li>
        <strong>{getString("type")}</strong> - {getString(installment?.type)}
      </li>
      <li>
        <strong>{getString("pointOfSell")}</strong> -{" "}
        {pointOfSell?.name || getString("notSet")},{" "}
        {pointOfSell?.paymentMethod?.name || getString("notSet")}
      </li>
    </ul>
  );
};
