// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badges {
  display: flex;
  gap: 8px;
}

.badge {
  display: inline-block;
  align-items: center;
  gap: 4px;
  padding: 8px 10px;
  border-radius: 4px;
  background-color: #3897dd;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}
.badge--dark {
  background-color: #216582;
  color: #ffffff;
}
.badge--red {
  background-color: rgba(240, 101, 72, 0.1);
  color: #f06548;
}
.badge--yellow {
  background-color: rgba(247, 184, 75, 0.1);
  color: #f7b84b;
}
.badge--green {
  background-color: rgba(10, 179, 156, 0.1);
  color: #45cb85;
}
.badge--blue {
  background-color: #dff0fa;
  color: #299cdb;
}
.badge--medium {
  padding: 6px 8px;
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Badges/Badges.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;;AAEA;EACE,qBAAA;EACA,mBAAA;EACA,QAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;AACE;EACE,yBAAA;EACA,cAAA;AACJ;AAEE;EACE,yCAAA;EACA,cAAA;AAAJ;AAGE;EACE,yCAAA;EACA,cAAA;AADJ;AAIE;EACE,yCAAA;EACA,cAAA;AAFJ;AAKE;EACE,yBAAA;EACA,cAAA;AAHJ;AAME;EACE,gBAAA;EACA,eAAA;AAJJ","sourcesContent":[".badges {\n  display: flex;\n  gap: 8px;\n}\n\n.badge {\n  display: inline-block;\n  align-items: center;\n  gap: 4px;\n  padding: 8px 10px;\n  border-radius: 4px;\n  background-color: #3897dd;\n  color: #ffffff;\n  font-size: 12px;\n  font-weight: 500;\n\n  &--dark {\n    background-color: #216582;\n    color: #ffffff;\n  }\n\n  &--red {\n    background-color: rgba(240, 101, 72, 0.1);\n    color: #f06548;\n  }\n\n  &--yellow {\n    background-color: rgba(247, 184, 75, 0.1);\n    color: #f7b84b;\n  }\n\n  &--green {\n    background-color: rgba(10, 179, 156, 0.1);\n    color: #45cb85;\n  }\n\n  &--blue {\n    background-color: #dff0fa;\n    color: #299cdb;\n  }\n\n  &--medium {\n    padding: 6px 8px;\n    font-size: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
