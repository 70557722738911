// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.single-installment {
  background: linear-gradient(145deg, var(--vz-primary-color), var(--vz-secondary-color));
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border: solid #b1b1b1 1px;
  margin-bottom: 30px;
}
.single-installment__label {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--vz-text-on-primary);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/SingleInstallment/SingleInstallment.scss"],"names":[],"mappings":"AAAA;EACE,uFAAA;EAKA,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,gCAAA;EACA,yBAAA;EAQA,mBAAA;AAVF;AAIE;EACE,mBAAA;EACA,eAAA;EACA,gCAAA;EACA,2CAAA;AAFJ","sourcesContent":[".single-installment {\n  background: linear-gradient(\n    145deg,\n    var(--vz-primary-color),\n    var(--vz-secondary-color)\n  );\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  transition: box-shadow 0.3s ease;\n  border: solid #b1b1b1 1px;\n\n  &__label {\n    margin-bottom: 20px;\n    font-size: 24px;\n    color: var(--vz-text-on-primary);\n    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);\n  }\n  margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
