import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const coordinatorTypes = [
  { value: "coordinator", label: getStringSync("coordinator") },
  { value: "health_and_safety", label: getStringSync("health_and_safety") },
];

const createCoordinatorSchema = Yup.object({
  userId: Yup.string().required(getStringSync("select_user")),
  type: Yup.string().required(getStringSync("select_type")),
});

export { createCoordinatorSchema, coordinatorTypes };
