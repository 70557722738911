import React, { useContext } from "react";
import { Button, Col, Row, TabPane } from "reactstrap";
import { CustomerDetailsContext } from "../../../CustomerDetails";
import { Tile } from "Components/Common/Tile/Tile";
import { FormattedString, getString } from "Components/Common/FormattedString";

import { CustomerDetails } from "../../components/CustomerDetails/CustomerDetails";
import { BillingData } from "../../components/BillingData/BillingData";
import { CustomerApplications } from "../../components/CustomerApplications/CustomerApplications";
import FormikTextArea from "Components/Entity/FormikTextArea";

export const CustomerOverviewTab = () => {
  const { customerData } = useContext(CustomerDetailsContext);

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={9}>
          <Tile
            title={getString("recent_applications")}
            gap="14px"
            titleDropdown={
              <Button
                color="primary"
                style={{
                  backgroundColor: "#EBF6FB",
                  color: "#3FA7D6",
                  border: "none",
                }}
              >
                <FormattedString id="generate_report" />
              </Button>
            }
          >
            <CustomerApplications />
          </Tile>
        </Col>
        <Col xxl={3}>
          <Tile title={getString("customer_details")} gap="14px">
            <CustomerDetails />
          </Tile>

          <Tile
            title={getString("billing_data")}
            gap="14px"
            titleIcon={
              <i className="ri-map-pin-line align-middle text-muted"></i>
            }
          >
            <BillingData />
          </Tile>

          <Tile title={getString("customer_note")} gap="14px">
            <FormikTextArea
              name="clientComment"
              disabled
              value={customerData.customerNote}
            />
          </Tile>
        </Col>
      </Row>
    </TabPane>
  );
};
