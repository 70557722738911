import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { AttachmentsList } from "./AttachmentsList";
import AddAttachment from "./AddAttachment";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const Attachments = () => {
  document.title = getString("attachments_page_title");
  const [attachmentAddVisible, setAttachmentAddVisible] = useState(false);
  const toggleAttachmentAdd = () => {
    setAttachmentAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("attachments_list")}
                  actionBtnLabel={getString("attachment_sm")}
                  actionBtn={toggleAttachmentAdd}
                  actionBtnPermission="ATTACHMENT_CREATE"
                >
                  <AttachmentsList />
                </EntityCardList>
              </div>
            </Col>
            {attachmentAddVisible && (
              <AddAttachment
                visibility={attachmentAddVisible}
                toggle={toggleAttachmentAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Attachments;
