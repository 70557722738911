import { Nav, NavItem, NavLink } from "reactstrap";
import { ApplicationDetailsContext } from "../ApplicationDetails";
import { useContext } from "react";
import classNames from "classnames";
import { OutlineButton } from "Components/Common/OutlineButton/OutlineButton";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedString } from "Components/Common/FormattedString";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";

export const ApplicationTabSwitcher = () => {
  const { activeTab, setActiveTab, applicationData } = useContext(
    ApplicationDetailsContext,
  );

  const { id } = useParams();

  const navigate = useNavigate();

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const canEditApplication = useHasPermission("APPLICATION_UPDATE");

  return (
    <div className="application-details__nav">
      <Nav
        pills
        className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
        role="tablist"
      >
        <NavItem>
          <NavLink
            href="#overview"
            className={classNames(
              { active: activeTab === "overview" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("overview");
            }}
          >
            <i className="ri-airplay-fill d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="overview" />
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            href="#activity"
            className={classNames(
              { active: activeTab === "activity" },
              "fs-14",
            )}
            onClick={() => {
              toggleTab("activity");
            }}
          >
            <i className="ri-list-unordered d-inline-block d-md-none"></i>{" "}
            <span className="d-none d-md-inline-block">
              <FormattedString id="activityLog" />
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <div className="application-details__nav__right">
        <WithPermission permissionName="APPLICATION_UPDATE">
          <OutlineButton
            onClick={() => {
              // navigate(`/application/${id}/edit`);
            }}
          >
            <i className="ri-pencil-line"></i>
            <FormattedString id="editApplication" />
          </OutlineButton>
        </WithPermission>
      </div>
    </div>
  );
};
