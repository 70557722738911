import React, { useContext, useEffect } from "react";
import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import { handleSelectChange } from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import * as Yup from "yup";
import { getHotelListData } from "store/actions";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { assignHotelToProgram } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";

export const AssignHotelCanvas = ({ visible, setVisible }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      hotelId: programData.hotel?.id || "",
    },
    validationSchema: Yup.object({
      hotelId: Yup.string().required(getString("hotel_required")),
    }),
    onSubmit: (values) => {
      assignHotelToProgram(programData.id, {
        hotelId: values.hotelId,
      })
        .then(() => {
          toast_success(getString("hotel_assigned"));
          setVisible(false);
          refreshProgramData();
        })
        .catch((e) => toast_error(e.message));
    },
  });

  const { hotelsData, listInitialized } = useSelector(
    (rootState) => rootState.Hotels,
  );
  const hotelsOptions = hotelsData
    .filter((item) => item?.status !== "archived")
    .map((item) => {
      return {
        value: item.id,
        label: `${item.name}, ${item.address}, ${item.city}`,
      };
    });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHotelListData());
  }, []);

  return (
    listInitialized && (
      <OffcanvasRight
        isOpen={visible}
        toggle={() => setVisible(false)}
        title={getString("hotel_assign")}
        formId="assign-hotel-form"
        validationRule={true}
        buttonLabel={getString("save")}
      >
        <Card className="add-installment-plan">
          <CardBody className="card-body">
            <Form
              id="assign-hotel-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
            >
              <FormSelect
                name="hotelId"
                label={getString("hotel_select")}
                value={validation.values.hotelId}
                defaultValue={hotelsOptions.find(
                  (r) => r.value === validation.values.hotelId,
                )}
                onChange={(selectedOption) => {
                  handleSelectChange(validation, selectedOption, "hotelId");
                }}
                isMulti={false}
                options={hotelsOptions.filter(
                  (ho) => ho.value !== programData.hotel?.id,
                )}
                error={validation.errors["hotelId"]}
              />
            </Form>
          </CardBody>
        </Card>
      </OffcanvasRight>
    )
  );
};
