// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.applications-list .table > :not(caption) > * > * {
  padding: 10px 12px;
}
.applications-list thead tr {
  background: var(--vz-topbar-user-bg);
  border-top: solid 1px var(--vz-table-border-color);
  border-bottom: solid 1px var(--vz-table-border-color);
}
.applications-list thead tr th {
  font-size: 13px;
  color: var(--vz-gray);
  font-weight: 400;
  padding: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationsList.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEE;EACE,oCAAA;EACA,kDAAA;EACA,qDAAA;AAAJ;AAEI;EACE,eAAA;EACA,qBAAA;EACA,gBAAA;EACA,wBAAA;AAAN","sourcesContent":[".applications-list {\n  .table > :not(caption) > * > * {\n    padding: 10px 12px;\n  }\n  thead tr {\n    background: var(--vz-topbar-user-bg);\n    border-top: solid 1px var(--vz-table-border-color);\n    border-bottom: solid 1px var(--vz-table-border-color);\n\n    th {\n      font-size: 13px;\n      color: var(--vz-gray);\n      font-weight: 400;\n      padding: 1rem !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
