import React from "react";
import "./CustomActionButtons.scss";

import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { Link, useLocation } from "react-router-dom";

const CustomActionButtons = ({
  onEdit,
  onBlock,
  onUnblock,
  onShowDetails,
  onUploadFiles,
  itemId,
  isBlocked,
  permissionPrefix,
}) => {
  const editButtonId = `editButton-${itemId}`;
  const blockButtonId = `blockButton-${itemId}`;
  const unblockButtonId = `unblockButton-${itemId}`;
  const detailsButtonId = `detailsButton-${itemId}`;
  const uploadFilesButtonId = `uploadFilesButton-${itemId}`;

  const location = useLocation();

  return (
    <div className="custom-action-buttons">
      {onShowDetails && (
        <div className="col-auto">
          <AngloTooltip stringId="show_details">
            <Link
              id={itemId && detailsButtonId}
              type="button"
              className="btn btn-sm btn-soft-primary btn-icon"
              to={`${location.pathname}/${itemId}${location.search}`}
              onClick={onShowDetails}
            >
              <i className="ri-eye-line"></i>
            </Link>
          </AngloTooltip>
        </div>
      )}
      {onEdit && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_UPDATE`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="edit">
              <button
                id={itemId && editButtonId}
                type="button"
                className="btn btn-sm btn-soft-warning btn-icon"
                onClick={onEdit}
              >
                <i className="ri-edit-2-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
      {onUploadFiles && (
        <WithPermission
          permissionName={
            permissionPrefix && `${permissionPrefix}_UPLOAD_FILES`
          }
        >
          <div className="col-auto">
            <AngloTooltip stringId="upload_file">
              <button
                id={itemId && uploadFilesButtonId}
                type="button"
                className="btn btn-sm btn-soft-info btn-icon"
                onClick={onUploadFiles}
              >
                <i className="ri-upload-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
      {onBlock && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_BLOCK`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="block">
              <button
                id={itemId && blockButtonId}
                type="button"
                className="btn btn-sm btn-soft-danger btn-icon"
                onClick={onBlock}
              >
                <i className="ri-lock-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
      {onUnblock && (
        <WithPermission
          permissionName={permissionPrefix && `${permissionPrefix}_UNBLOCK`}
        >
          <div className="col-auto">
            <AngloTooltip stringId="unblock">
              <button
                id={itemId && unblockButtonId}
                type="button"
                className="btn btn-sm btn-soft-success btn-icon"
                onClick={onUnblock}
              >
                <i className="ri-lock-unlock-line"></i>
              </button>
            </AngloTooltip>
          </div>
        </WithPermission>
      )}
    </div>
  );
};

export default CustomActionButtons;
