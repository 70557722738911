import logoBg from "assets/images/logo.svg";
import { Col, Row } from "reactstrap";
import { CustomerDetailsContext } from "../CustomerDetails";
import { useContext } from "react";
import { formatDate, splitNumbers } from "helpers/utils";
import { FormattedString } from "Components/Common/FormattedString";

export const CustomerHeader = () => {
  const { customerData } = useContext(CustomerDetailsContext);

  return (
    <div className="customer-details__header">
      <div className="customer-details__header__foreground">
        <div className="customer-details__header__background">
          <img
            src={logoBg}
            alt=""
            className="customer-details__header__background__image"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4">
        <Row className="g-4">
          <div className="col-auto d-flex align-items-center">
            <div className="customer-details__header__avatar">
              <i className="customer-details__header__thumb ri-user-line"></i>
            </div>
          </div>

          <Col>
            <div className="p-2">
              <div className="customer-details__header__top">
                <div className="customer-details__header__top__left">
                  <h3 className="customer-details__header__top__left__name">
                    {customerData.firstName} {customerData.lastName}
                  </h3>
                  <p className="customer-details__header__top__left__paragraph">
                    <i className="ri-mail-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    <a
                      href={`mailto:${customerData.email}`}
                      target="_blank"
                      className="text-white"
                      rel="noopener noreferrer"
                    >
                      {customerData.email}
                    </a>
                  </p>
                  <p className="customer-details__header__top__left__paragraph">
                    <i className="ri-phone-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                    +{customerData.phonePrefix}{" "}
                    {splitNumbers(customerData.phoneNumber)}
                  </p>
                </div>
              </div>
              <Row>
                <Col>
                  <div className="text-white-50">
                    <p className="customer-details__header__top__left__paragraph">
                      <i className="ri-bookmark-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      Last program: {customerData.lastProgramName}
                    </p>
                    <div>
                      <i className="ri-calendar-2-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                      {formatDate(customerData.lastProgramStartDate)} -{" "}
                      {formatDate(customerData.lastProgramEndDate)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>

          <Col xs={12} className="col-lg-auto order-last order-lg-0">
            <Row className="text text-white-50 text-center">
              <h4 className="text-white mb-1">
                <FormattedString id={customerData.status} />
              </h4>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
