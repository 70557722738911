import React, { useEffect, useMemo, useState } from "react";

import {
  currentLocation,
  getDefaultFilterType,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProgramPacketTemplateListData } from "../../store/programPacketTemplates/action";

import TableContainer from "../../Components/Common/TableContainerReactTable";
import ArchiveProgramPacketTemplate from "./ArchiveProgramPacketTemplate";
import EditProgramPacketTemplate from "./EditProgramPacketTemplate";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EntityListArchive from "../../Components/Entity/EntityListArchive";
import RestoreProgramPacketTemplate from "./RestoreProgramPacketTemplate";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { ProgramPacketTemplateDetails } from "./ProgramPacketTemplateDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { filterListByType } from "helpers/entity/list_helper";
import { Badges } from "Components/Common/Badges/Badges";
import ProgramPacketTemplateAttachments from "./ProgramPacketTemplateAttachments";
import { AttachmentsSimplifiedCell } from "Components/Common/Cells/AttachmentsSimplifiedCell";
import { getAttachmentListData } from "store/attachments/action";

const ProgramPacketTemplatesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { programPacketTemplatesData, listInitialized } = useSelector(
    (rootState) => rootState.ProgramPacketTemplates,
  );

  useEffect(() => {
    dispatch(getAttachmentListData());
    dispatch(getProgramPacketTemplateListData());
  }, []);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [programPacketTemplateData, setProgramPacketTemplateData] = useState();
  const [filterType, setFilterType] = useState(getDefaultFilterType());
  const [filteredData, setFilteredData] = useState(
    filterListByType(programPacketTemplatesData, getDefaultFilterType()),
  );

  const toggleEditModal = (programPacketTemplate, direction = false) => {
    setProgramPacketTemplateData(programPacketTemplate);
    setEditModalVisible(direction);
  };
  const toggleAtachmentsModal = (programPacketTemplate, direction = false) => {
    setProgramPacketTemplateData(programPacketTemplate);
    setProgramPacketAttachmentsModalVisible(direction);
  };

  useEffect(() => {
    toggleFilter(filterType);
  }, [programPacketTemplatesData]);

  const toggleFilter = (type) => {
    setFilteredData(filterListByType(programPacketTemplatesData, type));
    setFilterType(type);
  };

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const toggleArchiveModal = (id) => {
    setModalItemId(id);
    setArchiveModalVisible((p) => !p);
  };

  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const toggleRestoreModal = (id) => {
    setModalItemId(id);
    setRestoreModalVisible((p) => !p);
  };

  // details modal
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const [
    programPacketAttachmentsModalVisible,
    setProgramPacketAttachmentsModalVisible,
  ] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: "name",
      },
      {
        Header: getString("label"),
        accessor: "label",
        Cell: (cellProps) => <Badges feed={[cellProps.value]} />,
      },
      {
        Header: getString("ppMinAge"),
        accessor: "ppMinAge",
      },
      {
        Header: getString("ppMaxAge"),
        accessor: "ppMaxAge",
      },
      {
        Header: getString("ipMinAge"),
        accessor: "ipMinAge",
      },
      {
        Header: getString("ipMaxAge"),
        accessor: "ipMaxAge",
      },
      {
        Header: getString("targetAudience"),
        accessor: "targetAudience",
        Cell: (cellProps) => <Badges feed={[cellProps.value]} />,
      },
      {
        Header: getString("attachments"),
        accessor: (cellProps) => (
          <AttachmentsSimplifiedCell
            toggleAtachmentsModal={toggleAtachmentsModal}
            attachments={cellProps.attachments}
            upsell={cellProps}
          />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onArchive={() => toggleArchiveModal(cellProps.id)}
            isRestorable={cellProps.status === "archived"}
            onEdit={() => toggleEditModal(cellProps, true)}
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            onRestore={() => toggleRestoreModal(cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="PROGRAM_PACKET_TEMPLATE"
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <>
      <EntityListArchive toggleFilter={toggleFilter} />
      <TableContainer
        columns={columns || []}
        data={filteredData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("program_packet_templates_search_for")}
        key={`programPacketTemplates-${filterType}`}
      />
      {editModalVisible && (
        <EditProgramPacketTemplate
          itemId={itemId}
          visibility={editModalVisible}
          toggle={toggleEditModal}
          programPacketTemplate={programPacketTemplateData}
        />
      )}
      {archiveModalVisible && (
        <ArchiveProgramPacketTemplate
          itemId={itemId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreProgramPacketTemplate
          itemId={itemId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
      {detailsModalVisible && (
        <ProgramPacketTemplateDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
      {programPacketAttachmentsModalVisible && (
        <ProgramPacketTemplateAttachments
          programPacketTemplate={programPacketTemplatesData.find(
            (p) => p.id === programPacketTemplateData.id,
          )}
          attachments={
            programPacketTemplatesData.find(
              (p) => p.id === programPacketTemplateData.id,
            ).attachments
          }
          visibility={programPacketAttachmentsModalVisible}
          toggle={() => setProgramPacketAttachmentsModalVisible((p) => !p)}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};
export { ProgramPacketTemplatesList };
