import React from "react";

import classNames from "classnames";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export const SimplestModal = ({
  visible,
  className,
  children,
  setVisible,
  onSave,
  id,
  showFooter = true,
  title,
  addBtnText = "Save",
  width,
  ...rest
}) => {
  const formattedTitle = title.toLowerCase().replace(/\s+/g, "");

  return (
    <Modal
      centered
      isOpen={visible}
      modalClassName={classNames([
        "simplest-modal",
        `simplest-modal--${formattedTitle}`,
        className,
      ])}
      toggle={() => setVisible((p) => !p)}
      style={{ width }}
      {...rest}
    >
      <ModalHeader toggle={() => setVisible((p) => !p)}>{title}</ModalHeader>
      <ModalBody style={{ width }}>{children}</ModalBody>
      {showFooter && (
        <ModalFooter className="d-flex justify-content-between">
          <Button color="light" onClick={() => setVisible(false)}>
            Cancel
          </Button>
          {onSave && (
            <Button color="primary" onClick={onSave}>
              {addBtnText}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};
