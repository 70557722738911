import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { InstallmentPlansList } from "./InstallmentPlansList";
import AddInstallmentPlan from "./AddInstallmentPlan";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const InstallmentPlans = () => {
  document.title = getString("installment_plans_page_title");
  const [installmentPlanAddVisible, setInstallmentPlanAddVisible] =
    useState(false);
  const toggleInstallmentPlanAdd = () => {
    setInstallmentPlanAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("installment_plans_list")}
                  actionBtnLabel={getString("installment_plan_sm")}
                  actionBtn={toggleInstallmentPlanAdd}
                  actionBtnPermission="INSTALLMENT_PLAN_CREATE"
                >
                  <InstallmentPlansList />
                </EntityCardList>
              </div>
            </Col>
            {installmentPlanAddVisible && (
              <AddInstallmentPlan
                visibility={installmentPlanAddVisible}
                toggle={toggleInstallmentPlanAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default InstallmentPlans;
