import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  archiveCoordinator,
  getCoordinatorListData,
} from "../../store/coordinators/action";
import ConfirmArchiveBody from "../../Components/Entity/ConfirmArchiveBody";
import { getString } from "Components/Common/FormattedString";

const ArchiveCoordinator = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const archiveCoordinatorEntry = useCallback(() => {
    dispatch(
      archiveCoordinator(itemId, () => {
        dispatch(getCoordinatorListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmArchiveBody
        entityName={getString("coordinator_sm")}
        toggle={toggle}
        archiveAction={archiveCoordinatorEntry}
      />
    </Modal>
  );
};

export default ArchiveCoordinator;
