import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";

import ConfirmArchiveBody from "Components/Entity/ConfirmArchiveBody";
import { getString } from "Components/Common/FormattedString";
import { toast_error, toast_success } from "helpers/toast_helper";

import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { removeProgramPacketTemplateAttachment } from "helpers/API/core-service/cs_backend_helper";

const ArchiveAttachment = ({
  programPacketTemplateId,
  attachmentId,
  visibility,
  toggle,
}) => {
  const dispatch = useDispatch();

  const archiveAttachmentEntry = useCallback(() => {
    removeProgramPacketTemplateAttachment(programPacketTemplateId, attachmentId)
      .then(() => {
        dispatch(getProgramPacketTemplateListData());
        toast_success(getString("attachment_removed"));
        toggle();
      })
      .catch((error) => {
        toast_error(error.message);
      });
  }, [dispatch, programPacketTemplateId, attachmentId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmArchiveBody
        entityName={getString("attachment")}
        toggle={toggle}
        archiveAction={archiveAttachmentEntry}
      />
    </Modal>
  );
};

export default ArchiveAttachment;
