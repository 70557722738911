// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --av-bold-color: #495057;
  --av-text-color: #212529;
  --av-border-color: #e9ebec;
}

[data-bs-theme=dark] {
  --av-bold-color: #adb5bd;
  --av-text-color: #e5e5e5;
  --av-border-color: #32383e;
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,wBAAA;EACA,0BAAA;AACF;;AAEA;EACE,wBAAA;EACA,wBAAA;EACA,0BAAA;AACF","sourcesContent":[":root {\n  --av-bold-color: #495057;\n  --av-text-color: #212529;\n  --av-border-color: #e9ebec;\n}\n\n[data-bs-theme=\"dark\"] {\n  --av-bold-color: #adb5bd;\n  --av-text-color: #e5e5e5;\n  --av-border-color: #32383e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
