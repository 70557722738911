import React, { useContext } from "react";
import { Modal } from "reactstrap";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";
import { getString } from "Components/Common/FormattedString";

import { ProgramDetailsContext } from "../../ProgramDetails";
import { toast_error, toast_success } from "helpers/toast_helper";
import { deleteMeetingFromProgram } from "helpers/API/core-service/cs_backend_helper";

export const DeleteMeetingModal = ({
  visible,
  setVisible,
  programMeetingId,
  groupType,
}) => {
  const toggle = () => {
    setVisible(!visible);
  };
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteMeetingEntry = () => {
    deleteMeetingFromProgram(programData.id, programMeetingId)
      .then(() => {
        toast_success(getString(`${groupType}_meeting_deleted`));
        toggle(false);
        refreshProgramData();
      })
      .catch((e) => toast_error(e.message));
  };

  return (
    <Modal
      isOpen={visible}
      toggle={toggle}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString(`${groupType}_meeting`)}
        toggle={toggle}
        deleteAction={deleteMeetingEntry}
      />
    </Modal>
  );
};
