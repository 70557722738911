import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const userRoles = [
  { value: "ROLE_SUPER_ADMIN", label: getStringSync("ROLE_SUPER_ADMIN") },
  { value: "ROLE_ADMIN", label: getStringSync("ROLE_ADMIN") },
  { value: "ROLE_SALES", label: getStringSync("ROLE_SALES") },
  { value: "ROLE_PRODUCT", label: getStringSync("ROLE_PRODUCT") },
];

const createUserSchema = Yup.object({
  firstName: Yup.string()
    .required(getStringSync("enter_user_first_name"))
    .min(4, getStringSync("invalid_length"))
    .max(32, getStringSync("invalid_length")),
  lastName: Yup.string()
    .required("Please enter user last name")
    .min(4, getStringSync("invalid_length"))
    .max(64, getStringSync("invalid_length")),
  email: Yup.string()
    .email(getStringSync("invalid_mail"))
    .required(getStringSync("mail_required"))
    .min(5, getStringSync("invalid_length"))
    .max(255, getStringSync("invalid_length")),
  role: Yup.string().required(getStringSync("select_option")),
  businessUnits: Yup.array()
    .required(getStringSync("select_at_least_one_option"))
    .min(1, getStringSync("select_at_least_one_option")),
});

export { userRoles, createUserSchema };
