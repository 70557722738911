import React from "react";
import { Col, Container, Row, Button } from "reactstrap";
import { CustomersList } from "./CustomersList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { FormattedString, getString } from "Components/Common/FormattedString";

const Customers = () => {
  document.title = getString("customers_page_title");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("customers_list")}
                  headerButtons={
                    <Button
                      color="primary"
                      style={{
                        backgroundColor: "#EBF6FB",
                        color: "#3FA7D6",
                        border: "none",
                      }}
                    >
                      <FormattedString id="generate_report" />
                    </Button>
                  }
                >
                  <CustomersList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
