// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-summary {
  background: #428bca;
  border-radius: 20px;
  padding: 21px;
  margin-bottom: 20px;
  color: white;
}
.form-summary__section__header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}
.form-summary__no-participants, .form-summary__no-discounts {
  margin-bottom: 10px;
}
.form-summary__footer {
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationOverviewTab/partials/FormSummary/FormSummary.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AACF;AAEI;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AAAN;AAIE;EAEE,mBAAA;AAHJ;AAME;EACE,aAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAJJ","sourcesContent":[".form-summary {\n  background: #428bca;\n  border-radius: 20px;\n  padding: 21px;\n  margin-bottom: 20px;\n  color: white;\n\n  &__section {\n    &__header {\n      font-size: 20px;\n      font-weight: 700;\n      margin-bottom: 10px;\n    }\n  }\n\n  &__no-participants,\n  &__no-discounts {\n    margin-bottom: 10px;\n  }\n\n  &__footer {\n    display: flex;\n    justify-content: space-between;\n    font-size: 24px;\n    font-weight: 700;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
