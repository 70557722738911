import { Link } from "react-router-dom";
import "./AttachmentLink.scss";

export const AttachmentLink = ({ href, fileName }) => {
  return (
    <Link to={href} className="attachment-link">
      <i className="ri-file-2-line attachment-link__thumb"></i>
      {fileName}
    </Link>
  );
};
