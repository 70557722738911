import React, { useContext, useEffect } from "react";

import { FormSelect } from "Components/Entity/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Form } from "reactstrap";
import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "helpers/validation_helper";
import { useFormik } from "formik";
import { getString, getStringSync } from "Components/Common/FormattedString";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { ProgramDetailsContext } from "../../ProgramDetails";
import { getMeetingPointListData } from "store/meetingPoints/action";
import FormTimeField from "Components/Entity/FormTimeField";
import FormNumberField from "Components/Entity/FormNumberField";
import * as Yup from "yup";
import { installmentPositions } from "models/installmentPositions";
import { addMeetingToProgram } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";

export const AssignMeetingCanvas = ({ visible, setVisible, groupType }) => {
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const dispatch = useDispatch();
  const MeetingPoints = useSelector((rootState) => rootState.MeetingPoints);

  const relevantMeetingList =
    groupType === "native_speakers"
      ? programData.programMeetingsNS
      : programData.programMeetingsPP;

  const relevantMeetingIDs = relevantMeetingList.map(
    (meeting) => meeting.meetingPoint.id,
  );

  const meetingPointsOptions = MeetingPoints.meetingPointsData
    .filter((item) => !relevantMeetingIDs.includes(item.id))
    .filter((item) => item?.status !== "archived")
    .map((item) => ({
      value: item.id,
      label: `${item.city}, ${item.address}`,
    }));

  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      startHour: "",
      endHour: "",
      dateModifier: 0,
      hotel: programData.hotel.id,
      meetingPoint: "",
      additionalPrice: 0,
      groupType: groupType,
      installmentPosition: "",
    },
    validationSchema: Yup.object({
      startHour: Yup.string().required(getStringSync("enter_valid_hour")),
      endHour: Yup.string().required(getStringSync("enter_valid_hour")),
      dateModifier: Yup.number(),
      hotel: Yup.string().required(getStringSync("select_hotel")),
      meetingPoint: Yup.string().required(
        getStringSync("select_meeting_point"),
      ),
      additionalPrice: Yup.number()
        .typeError(getStringSync("enter_additional_price"))
        .required(getStringSync("enter_additional_price"))
        .min(0, getStringSync("additional_price_too_small"))
        .test(
          "is-decimal-or-integer",
          getString("price_must_be_integer_or_proper_float"),
          (value) => /^\d+(\.\d)?$/.test(value),
        ),
      groupType: Yup.string().required(getStringSync("select_group_type")),
      installmentPosition: Yup.string().required(
        getStringSync("select_installment_position"),
      ),
    }),
    onSubmit: (values) => {
      addMeetingToProgram(programData.id, values)
        .then((r) => {
          toast_success(getString(`${groupType}_meeting_created`));
          refreshProgramData();
          setVisible(false);
        })
        .catch((e) => {
          toast_error(e.message);
          setValidationErrors(validation, e.data.fields);
        });
    },
  });

  useEffect(() => {
    dispatch(getMeetingPointListData());
  }, []);

  return (
    <OffcanvasRight
      isOpen={visible}
      toggle={() => setVisible(!visible)}
      title={getString(`add_${groupType}_meeting_point`)}
      formId={"add-meeting"}
      validationRule={validation.isValid}
      buttonLabel={getString("add")}
    >
      <Form
        id="add-meeting"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Card>
          <CardBody className="card-body">
            <FormTimeField
              label={getString("startHour")}
              name="startHour"
              value={validation.values.startHour}
              onChange={(e) => handleInputChange(validation, e, "startHour")}
              error={validation.errors.startHour}
            />
            <FormTimeField
              label={getString("endHour")}
              name="endHour"
              value={validation.values.endHour}
              onChange={(e) => handleInputChange(validation, e, "endHour")}
              error={validation.errors.endHour}
            />
            <FormNumberField
              label={getString("date_modifier")}
              name="dateModifier"
              value={validation.values.dateModifier}
              onChange={(e) => handleInputChange(validation, e, "dateModifier")}
              error={validation.errors.dateModifier}
              touched={validation.touched.dateModifier}
            />
            <FormSelect
              name="meetingPoint"
              value={validation.values.meetingPoint}
              onChange={(selectedOption) =>
                handleSelectChange(validation, selectedOption, "meetingPoint")
              }
              isMulti={false}
              options={meetingPointsOptions}
              error={validation.errors["meetingPoint"]}
            />
            <FormNumberField
              label={getString("additional_price")}
              name="additionalPrice"
              value={validation.values.additionalPrice}
              onChange={(e) =>
                handleInputChange(validation, e, "additionalPrice")
              }
              error={validation.errors.additionalPrice}
              touched={validation.touched.additionalPrice}
            />
            <FormSelect
              name="installmentPosition"
              label={getString("installmentPosition")}
              value={validation.values.installmentPosition}
              onChange={(selectedOption) =>
                handleSelectChange(
                  validation,
                  selectedOption,
                  "installmentPosition",
                )
              }
              isMulti={false}
              options={installmentPositions}
              error={validation.errors["installmentPosition"]}
            />
          </CardBody>
        </Card>
      </Form>
    </OffcanvasRight>
  );
};
