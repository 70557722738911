import { Badge, Row } from "reactstrap";

export const renderBadges = (items) => {
  return items.map((item, index) => (
    <Badge color="info" key={index} className="m-1 p-2">
      {item}
    </Badge>
  ));
};

export const filterListByType = (listData, type) => {
  return listData.filter((entry) => entry.status === type);
};
