import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

const installmentPositions = [
  { value: "first", label: getStringSync("first") },
  { value: "last", label: getStringSync("last") },
];

const createInstallmentPositionSchema = Yup.object({
  userId: Yup.string().required(getStringSync("select_user")),
  position: Yup.string().required(getStringSync("select_position")),
});

export { createInstallmentPositionSchema, installmentPositions };
