import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormTextArea from "Components/Entity/FormTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { FormCreatableSelect } from "Components/Entity/FormCreatableSelect";
import { additionalNoteOptions } from "models/additionalNotes";

const NewNoteForm = ({ handleSubmitNote, toggle }) => {
  const [nameOptions, setNameOptions] = useState([...additionalNoteOptions]);

  const formik = useFormik({
    initialValues: {
      name: "",
      content: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required(getString("name_selection_is_required")),
      content: Yup.string().required(getString("note_cannot_be_empty")),
    }),
    onSubmit: (values) => {
      handleSubmitNote(values);
      formik.resetForm();
      toggle();
    },
  });

  const handleSelectChange = (selectedOption) => {
    formik.setFieldValue("name", selectedOption.value);
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setNameOptions((prevOptions) => [...prevOptions, newOption]);
    formik.setFieldValue("name", inputValue);
  };

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormCreatableSelect
        name="name"
        label={getString("selected_name")}
        value={nameOptions.find((r) => r.value === formik.values.name)}
        defaultValue={nameOptions.find((r) => r.value === formik.values.name)}
        onChange={handleSelectChange}
        onCreateOption={handleCreateOption}
        isMulti={false}
        options={nameOptions}
        error={formik.touched.name && formik.errors.name}
      />
      <FormTextArea
        name="content"
        value={formik.values.content}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.content}
      />
      {formik.dirty && (
        <div className="d-flex justify-content-center mt-3 gap-5">
          <LinkButton color="secondary" type="button" onClick={handleCancel}>
            <i className="ri-close-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="cancel" />
          </LinkButton>
          <LinkButton color="primary" onClick={() => formik.handleSubmit()}>
            <i className="ri-add-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="create" />
          </LinkButton>
        </div>
      )}
    </form>
  );
};

export default NewNoteForm;
