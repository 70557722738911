import { Badges } from "Components/Common/Badges/Badges";
import { FilePathPreview } from "../fields/FilePathPreview";
import { MapUrlCell } from "../../Cells/MapUrlCell";
import { HotelPreview } from "../fields/HotelPreview/HotelPreview";
import { MeetingPointPreview } from "../fields/MeetingPointPreview/MeetingPointPreview";
import { UpsellAttachmentsList } from "pages/Upsells/UpsellAttachmentsList/UpsellAttachmentsList";
import { isObjectEmpty } from "helpers/utils";
import { PaymentMethodPreview } from "../fields/PaymentMethodPreview/PaymentMethodPreview";
import { InstallmentsPreview } from "../fields/InstallmentsPreview/InstallmentsPreview";
import { DetailsBox } from "../DetailsBox";
import { format } from "date-fns";
import { IpParticipantsAttachmentsList } from "pages/IpParticipants/components/IpParticipantsAttachmentsList/IpParticipantsAttachmentsList";

export const renderDetailsItem = (item) => {
  if (isObjectEmpty(item.value)) return "-";
  switch (item.key) {
    case "dateOfBirth":
      return format(new Date(item.value), "dd-MM-yyyy");
    case "meetingPoint":
      return <MeetingPointPreview meetingPoint={item.value} />;
    case "businessUnits":
      return <Badges feed={item.value.map((unit) => unit.name)} />;
    case "businessUnit":
      return <Badges feed={[item.value].map((unit) => unit.name)} />;
    case "filePath":
      return <FilePathPreview filePath={item.value} />;
    case "mapUrl":
      return <MapUrlCell mapUrl={item.value} />;
    case "type":
      return <Badges feed={[item.value]} />;
    case "roles":
      return (
        <Badges
          feed={item.value
            .map((role) => role.substring(5))
            .filter((role) => role !== "CRM")}
          variant="dark"
        />
      );
    case "hotel":
      return <HotelPreview hotel={item.value} />;
    case "paymentMethod":
      return <PaymentMethodPreview paymentMethod={item.value} />;
    case "groupType":
      return <Badges feed={[item.value]} />;
    case "documents":
      return (
        <IpParticipantsAttachmentsList
          participant={item}
          attachments={item.value}
        />
      );
    case "attachments":
      return <UpsellAttachmentsList upsell={{}} attachments={item.value} />;
    case "installments":
      return (
        <InstallmentsPreview
          installments={item.value}
          installmentCount={item.value.length}
        />
      );
    case "programMeeting":
    case "installmentPlan":
    case "payerDetails":
      return <DetailsBox item={item.value} key={item.value?.id} />;
    case "applicationParticipants":
      return item.value.map((i) => <DetailsBox item={i} key={i.id} />);
    default:
      if (
        typeof item.value === "object" &&
        item.value !== null &&
        !Array.isArray(item.value)
      ) {
        return JSON.stringify(item.value);
      } else {
        return item.value;
      }
  }
};
