import { faker } from "@faker-js/faker";

export const mockPaymentMethod = {
  id: faker.datatype.uuid(),
  name: faker.company.companyName(),
  provider: faker.company.companyName(),
  businessUnit: {
    id: faker.datatype.uuid(),
    name: faker.address.country(),
    email: faker.internet.email(),
    website: faker.internet.url(),
    currency: faker.finance.currencyCode(),
  },
};

export const mockPaymentMethod2 = {
  id: faker.datatype.uuid(),
  name: faker.company.companyName(),
  provider: faker.company.companyName(),
  businessUnit: {
    id: faker.datatype.uuid(),
    name: faker.address.country(),
    email: faker.internet.email(),
    website: faker.internet.url(),
    currency: faker.finance.currencyCode(),
  },
};
