import React from "react";
import { Col, Container, Row } from "reactstrap";
import { PaymentMethodsList } from "./PaymentMethodsList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const PaymentMethods = () => {
  document.title = getString("payment_methods_page_title");

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList title={getString("payment_methods_list")}>
                  <PaymentMethodsList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PaymentMethods;
