import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import { archiveHotel, getHotelListData } from "../../store/hotels/action";
import ConfirmArchiveBody from "../../Components/Entity/ConfirmArchiveBody";
import { getString } from "Components/Common/FormattedString";

const ArchiveHotel = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const archiveHotelEntry = useCallback(() => {
    dispatch(
      archiveHotel(itemId, () => {
        dispatch(getHotelListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmArchiveBody
        entityName={getString("hotel")}
        toggle={toggle}
        archiveAction={archiveHotelEntry}
      />
    </Modal>
  );
};

export default ArchiveHotel;
