import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getHotelListData } from "../../store/hotels/action";

import TableContainer from "../../Components/Common/TableContainerReactTable";
import { filterListByType } from "../../helpers/entity/list_helper";
import ArchiveHotel from "./ArchiveHotel";
import EditHotel from "./EditHotel";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EntityListArchive from "../../Components/Entity/EntityListArchive";
import RestoreHotel from "./RestoreHotel";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getDefaultFilterType,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { HotelDetails } from "./HotelDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { MapUrlCell } from "Components/Common/Cells/MapUrlCell";

const HotelsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hotelsData, listInitialized } = useSelector(
    (rootState) => rootState.Hotels,
  );

  // get hotels list
  useEffect(() => {
    dispatch(getHotelListData());
  }, []);

  // edit hotel modal
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [hotelData, setHotelData] = useState();
  const [filterType, setFilterType] = useState(getDefaultFilterType());
  const [filteredData, setFilteredData] = useState(
    filterListByType(hotelsData, getDefaultFilterType()),
  );

  const toggleEditModal = (hotel, direction = false) => {
    setHotelData(hotel);
    setEditModalVisible(direction);
  };

  /* DATA FILTRATION */
  useEffect(() => {
    toggleFilter(filterType);
  }, [hotelsData]);

  const toggleFilter = (type) => {
    setFilteredData(filterListByType(hotelsData, type));
    setFilterType(type);
  };

  // archive hotel modal
  const [itemId, setModalItemId] = useState(getItemUuid());
  const [archiveModalVisible, setArchiveModalVisible] = useState(false);
  const toggleArchiveModal = (id) => {
    setModalItemId(id);
    setArchiveModalVisible((p) => !p);
  };

  // restore hotel modal
  const [restoreModalVisible, setRestoreModalVisible] = useState(false);
  const toggleRestoreModal = (id) => {
    setModalItemId(id);
    setRestoreModalVisible((p) => !p);
  };

  // hotel details modal
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: "name",
      },
      {
        Header: getString("address"),
        accessor: (cellProps) =>
          `${cellProps.address}, ${cellProps.zipCode} ${cellProps.city}`,
        Cell: (cellProps) => (
          <span>
            {cellProps.row.original.address}, {cellProps.row.original.zipCode}{" "}
            {cellProps.row.original.city}
          </span>
        ),
      },
      {
        Header: getString("mapUrl"),
        accessor: (cellProps) => <MapUrlCell mapUrl={cellProps.mapUrl} />,
      },
      {
        Header: getString("contactInfo"),
        accessor: "contactInfo",
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onEdit={() => toggleEditModal(cellProps, true)}
            onArchive={() => toggleArchiveModal(cellProps.id)}
            isRestorable={cellProps.status === "archived"}
            onRestore={() => toggleRestoreModal(cellProps.id)}
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="HOTEL"
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <>
      <EntityListArchive toggleFilter={toggleFilter} />
      <TableContainer
        columns={columns || []}
        data={filteredData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("hotels_search_for")}
        key={`hotels-${filterType}`}
      />
      {editModalVisible && (
        <EditHotel
          hotel={hotelData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
        />
      )}
      {archiveModalVisible && (
        <ArchiveHotel
          itemId={itemId}
          visibility={archiveModalVisible}
          toggle={toggleArchiveModal}
        />
      )}
      {restoreModalVisible && (
        <RestoreHotel
          itemId={itemId}
          visibility={restoreModalVisible}
          toggle={toggleRestoreModal}
        />
      )}
      {detailsModalVisible && (
        <HotelDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};
export { HotelsList };
