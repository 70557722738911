import classNames from "classnames";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./UpsellEquation.scss";

export const UpsellEquation = ({ upsell, capacity, centered }) => {
  const totalUpsell = upsell.notPaid + upsell.partlyPaid + upsell.fullyPaid;
  return (
    <div
      className={classNames([
        "upsell-equation",
        centered && "upsell-equation--centered",
      ])}
    >
      <AngloTooltip text="Not Paid">
        <span className="upsell-equation__val upsell-equation__val--notPaid">
          {upsell.notPaid}
        </span>
      </AngloTooltip>
      <span className="upsell-equation__sign">+</span>

      <AngloTooltip text="Partly Paid">
        <span className="upsell-equation__val upsell-equation__val--partlyPaid">
          {upsell.partlyPaid}
        </span>
      </AngloTooltip>
      <span className="upsell-equation__sign">+</span>

      <AngloTooltip text="Fully paid">
        <span className="upsell-equation__val upsell-equation__val--fullyPaid">
          {upsell.fullyPaid}
        </span>
      </AngloTooltip>
      <span className="upsell-equation__sign">=</span>

      <AngloTooltip text="Sum">
        <span className="upsell-equation__val upsell-equation__val--sum">
          {totalUpsell}
        </span>
      </AngloTooltip>
      <span className="upsell-equation__sign">/</span>

      <AngloTooltip text="Upsell capacity">
        <span className="upsell-equation__val upsell-equation__val--capacity">
          {capacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
