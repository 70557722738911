import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const HotelDetails = ({ itemId, toggle, visible }) => {
  const { hotelsData } = useSelector((rootState) => rootState.Hotels);

  const item = hotelsData.find((hotel) => hotel.id === itemId);

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="hotel-details-modal"
      title={getString("hotel_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
