import { useContext } from "react";
import "./MeetingPointsView.scss";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { SingleMeetingPointGroup } from "./SingleMeetingPointGroup/SingleMeetingPointGroup";

export const MeetingPointsView = () => {
  const { programData } = useContext(ProgramDetailsContext);

  return (
    <div className="meeting-points-view">
      <SingleMeetingPointGroup
        meetingPoints={programData.programMeetingsPP}
        groupType="participants"
      />
      <SingleMeetingPointGroup
        meetingPoints={programData.programMeetingsNS}
        groupType="native_speakers"
      />
    </div>
  );
};
