import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getPaymentMethodListData } from "../../store/paymentMethods/action";

import TableContainer from "../../Components/Common/TableContainerReactTable";

import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";

import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { PaymentMethodDetails } from "./PaymentMethodDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { Badges } from "Components/Common/Badges/Badges";
import { getBusinessUnitListData } from "store/actions";

const PaymentMethodsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { paymentMethodsData, listInitialized } = useSelector(
    (rootState) => rootState.PaymentMethods,
  );

  // get paymentMethods list
  useEffect(() => {
    dispatch(getBusinessUnitListData());
    dispatch(getPaymentMethodListData());
  }, []);

  const [itemId, setModalItemId] = useState(getItemUuid());

  // paymentMethod details modal
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("name"),
        accessor: "name",
      },
      {
        Header: getString("provider"),
        accessor: "provider",
      },
      {
        Header: getString("business_unit"),
        disableFilters: true,
        filterable: false,
        accessor: (cellProps) =>
          [cellProps.businessUnit].map((unit) => unit.name).join(", "),
        Cell: (cellProps) => (
          <Badges
            feed={[cellProps.row.original.businessUnit].map(
              (unit) => unit.name,
            )}
          />
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
            itemId={cellProps.id}
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <>
      <TableContainer
        columns={columns || []}
        data={paymentMethodsData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("payment_methods_search_for")}
      />
      {detailsModalVisible && (
        <PaymentMethodDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};
export { PaymentMethodsList };
