import { useContext } from "react";
import "./FormSummary.scss";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import ParticipantShort from "./components/ParticipantShort/ParticipantShort";
import ProgramShort from "./components/ProgramShort/ProgramShort";
import { splitNumbers } from "helpers/utils";
import DiscountsShort from "./components/DiscountsShort/DiscountsShort";

export const FormSummary = ({
  setUpsellsOverlayVisible,
  setEditedParticipant,
}) => {
  const { applicationData } = useContext(ApplicationDetailsContext);

  const summary = applicationData;

  const totalSummaryPrice = summary?.participants.reduce(
    (acc, participant) => acc + (participant.summaryPrice || 0),
    0,
  );

  return (
    <div className="form-summary">
      <div className="form-summary__section">
        <div className="form-summary__section__header">Program</div>
        <ProgramShort
          hotelName={summary.hotelName}
          meetingPoint={summary.meetingPoint}
          programStartDate={summary.startDate}
          programEndDate={summary.endDate}
        />
      </div>
      <div className="form-summary__section">
        <div className="form-summary__section__header">Participants</div>
        {summary?.participants.length > 0 ? (
          summary.participants.map((participant, index) => (
            <ParticipantShort
              key={index}
              index={index}
              participant={participant}
              setUpsellsOverlayVisible={setUpsellsOverlayVisible}
              setEditedParticipant={setEditedParticipant}
            />
          ))
        ) : (
          <div className="form-summary__no-participants">No participants</div>
        )}
      </div>

      <div className="form-summary__section">
        <div className="form-summary__section__header">Discounts</div>
        {summary?.discounts?.length > 0 ? (
          <DiscountsShort discounts={summary?.discounts ?? []} />
        ) : (
          <div className="form-summary__no-discounts">No discounts</div>
        )}
      </div>

      <div className="form-summary__footer">
        <div className="form-summary__footer-label">Sum:</div>
        <div className="form-summary__footer-amount">
          {totalSummaryPrice > 0
            ? `${splitNumbers(totalSummaryPrice)} zł`
            : "N/A"}
        </div>
      </div>
    </div>
  );
};
