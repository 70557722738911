import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const CoordinatorDetails = ({ itemId, toggle, visible }) => {
  const { coordinatorsData } = useSelector(
    (rootState) => rootState.Coordinators,
  );

  const item = coordinatorsData.find(
    (coordinator) => coordinator.id === itemId,
  );

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="coordinator-details-modal"
      title={getString("coordinator_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
