// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html .form-select {
  padding: 0;
  border: none;
}
html .form-select.is-invalid .form-select__control {
  border-color: var(--vz-form-invalid-border-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/FormSelect.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,YAAA;AACF;AACI;EACE,iDAAA;AACN","sourcesContent":["html .form-select {\n  padding: 0;\n  border: none;\n  &.is-invalid {\n    .form-select__control {\n      border-color: var(--vz-form-invalid-border-color);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
