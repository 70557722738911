// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-form-feedback {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--vz-form-invalid-color);
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/CustomFormFeedback/CustomFormFeedback.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,kBAAA;EACA,mCAAA;AACF","sourcesContent":[".custom-form-feedback {\n  margin-top: 0.25rem;\n  font-size: 0.875em;\n  color: var(--vz-form-invalid-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
