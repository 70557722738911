import React, { useEffect, useMemo, useState, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersListData } from "../../store/customers/action";
import TableContainerExternalPagination from "Components/Common/TableContainerExternalPagination";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { getBusinessUnitListData } from "store/actions";
import { format } from "date-fns";
import "./CustomersList.scss";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { splitNumbers } from "helpers/utils";

const CustomersList = () => {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = searchParams.get("page") || "1";
  const initialSearchTerm = searchParams.get("searchTerm") || "";
  const [globalFilter, setGlobalFilter] = useState(initialSearchTerm);

  const isInitialRender = useRef(true);

  const {
    customersData,
    listInitialized,
    count,
    currentPage,
    totalPages,
    pageSize,
    loading,
  } = useSelector((rootState) => rootState.Customers);

  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }

    const params = {};
    if (globalFilter) {
      params.searchTerm = globalFilter;
    }
    if (currentPage > 1) {
      params.page = currentPage.toString();
    }
    setSearchParams(params);
  }, [currentPage, globalFilter]);

  useEffect(() => {
    if (isInitialRender.current) {
      return;
    }

    dispatch(getCustomersListData(1, globalFilter));
  }, [globalFilter]);

  useEffect(() => {
    dispatch(getBusinessUnitListData());
    dispatch(getCustomersListData(pageNumber, globalFilter));

    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: getString("customer_name"),
        accessor: (row) => (
          <div className="cell-customer-name">
            <Link to={`/customer/${row.id}`}>
              {row.firstName} {row.lastName}
            </Link>
          </div>
        ),
      },
      {
        Header: getString("phone_number"),
        accessor: (d) => (
          <div className="cell-phone-number">
            +{d.phonePrefix} {splitNumbers(d.phoneNumber)}
          </div>
        ),
      },
      {
        Header: getString("email"),
        accessor: (d) => (
          <Link to={`mailto:${d.email}`} target="_blank">
            {d.email}
          </Link>
        ),
      },
      {
        Header: getString("ap_count"),
        accessor: (d) => (
          <div className="cell-ap-count">{d.applicationsCount}</div>
        ),
      },
      {
        Header: getString("pp_count"),
        accessor: (d) => (
          <div className="cell-pp-count">{d.participantsCount}</div>
        ),
      },
      {
        Header: getString("last_program"),
        accessor: (d) => (
          <div className="cell-last-program">
            <div>{d.lastProgramName}</div>
            <div>
              {format(new Date(d.lastProgramStartDate), "yyyy-MM-dd")} -{" "}
              {format(new Date(d.lastProgramEndDate), "yyyy-MM-dd")}
            </div>
          </div>
        ),
      },
      {
        Header: getString("note"),
        accessor: (d) => (
          <div className="cell-note">
            {d.customerNote ? d.customerNote : "-"}
          </div>
        ),
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            customShowDetailsLink={`/customer/${cellProps.id}`}
            itemId={cellProps.id}
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <div className="customers-list">
      <TableContainerExternalPagination
        columns={columns || []}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        loading={loading}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        onPageChange={(page) => {
          dispatch(getCustomersListData(page, globalFilter));
        }}
        data={customersData || []}
        pageSize={pageSize}
        isBordered={false}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("customers_search_for")}
      />
    </div>
  ) : (
    <PreloaderWrap />
  );
};

export { CustomersList };
