import React from "react";
import "./CustomIdCell.scss";
import { Link } from "react-router-dom";

const CustomIdCell = ({ customId, to }) => {
  return (
    <Link to={to} className="custom-id-cell">
      #{customId}
    </Link>
  );
};

export default CustomIdCell;
