import React from "react";
import { ModalBody, Button, Spinner } from "reactstrap";
import confirm_img from "../../assets/images/widget-img.png";
import { FormattedString } from "Components/Common/FormattedString";

const ConfirmDuplicateBody = ({
  entityName,
  toggle,
  duplicateAction,
  isDuplicating,
}) => {
  return (
    <ModalBody className="text-center p-5">
      <div className="">
        <img
          src={confirm_img}
          alt=""
          height={250}
          className="error-basic-img move-animation"
        />
      </div>

      <div className="mt-4">
        <h4 className="mb-3">
          <FormattedString id="duplicate_modal_title" /> {entityName}?
        </h4>
        <p className="text-muted mb-4">
          <FormattedString id="duplicate_modal_warning" />
        </p>
        <div className="hstack gap-4 justify-content-center">
          <Button
            color="success"
            onClick={() => toggle(false)}
            disabled={isDuplicating}
          >
            <FormattedString id="cancel" />
          </Button>
          <Button
            color="primary"
            onClick={duplicateAction}
            disabled={isDuplicating}
          >
            {isDuplicating ? (
              <Spinner size="sm" />
            ) : (
              <FormattedString id="duplicate" />
            )}
          </Button>
        </div>
      </div>
    </ModalBody>
  );
};

export default ConfirmDuplicateBody;
