// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.program-progress .animated-progress {
  margin-top: 12px;
}
.program-progress__equation {
  margin-bottom: 0;
  font-family: "Inter", sans-serif;
  align-items: center;
  display: flex;
  gap: 4px;
}
.program-progress__equation__val--total {
  color: var(--vz-body-color);
  font-weight: 500;
}
.program-progress__equation__val--available-slots {
  color: #9599ad;
}
.program-progress .ri-check-fill {
  color: var(--vz-success);
  border-radius: 50%;
  background-color: var(--vz-success-bg-subtle);
  padding: 5px;
  margin-right: 4px;
}
.program-progress .ri-close-fill {
  color: var(--vz-danger);
  border-radius: 50%;
  background: var(--vz-danger-bg-subtle);
  padding: 5px;
  margin-right: 4px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/ProgramProgress/ProgramProgress.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,gCAAA;EACA,mBAAA;EACA,aAAA;EACA,QAAA;AADJ;AAIM;EACE,2BAAA;EACA,gBAAA;AAFR;AAIM;EACE,cAAA;AAFR;AAOE;EACE,wBAAA;EACA,kBAAA;EACA,6CAAA;EACA,YAAA;EACA,iBAAA;AALJ;AAOE;EACE,uBAAA;EACA,kBAAA;EACA,sCAAA;EACA,YAAA;EACA,iBAAA;AALJ","sourcesContent":[".program-progress {\n  .animated-progress {\n    margin-top: 12px;\n  }\n\n  &__equation {\n    margin-bottom: 0;\n    font-family: \"Inter\", sans-serif;\n    align-items: center;\n    display: flex;\n    gap: 4px;\n\n    &__val {\n      &--total {\n        color: var(--vz-body-color);\n        font-weight: 500;\n      }\n      &--available-slots {\n        color: #9599ad;\n      }\n    }\n  }\n\n  .ri-check-fill {\n    color: var(--vz-success);\n    border-radius: 50%;\n    background-color: var(--vz-success-bg-subtle);\n    padding: 5px;\n    margin-right: 4px;\n  }\n  .ri-close-fill {\n    color: var(--vz-danger);\n    border-radius: 50%;\n    background: var(--vz-danger-bg-subtle);\n    padding: 5px;\n    margin-right: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
