import React from "react";
import "./ParticipantAgeCell.scss";

const ParticipantAgeCell = ({ ages }) => {
  return (
    <div className="participant-age-cell">
      {ages && ages.length > 0 ? (
        ages.map((age, index) => (
          <div key={index} className="participant-age-cell__item">
            {age}
          </div>
        ))
      ) : (
        <div className="participant-age-cell__item">-</div>
      )}
    </div>
  );
};

export default ParticipantAgeCell;
