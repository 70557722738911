import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toast_success = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
  });
};

export const toast_error = (message, time = 3000) => {
  toast.error(message, {
    position: "top-right",
    autoClose: time,
  });
};

export const toast_info = (message, time = 3000) => {
  toast.info(message, {
    position: "top-right",
    autoClose: time,
  });
};
