import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Login Redux States
import { FETCH_PROFILE } from "./actionTypes";
import { profileError, profileSuccess } from "./actions";
import { fetchProfile } from "../../../helpers/API/account-service/as_backend_helper";
import { isEmpty } from "lodash";
import { toast_error } from "helpers/toast_helper";
import { logoutUser } from "../login/saga";

function* fetchUserProfile() {
  try {
    const response = yield call(fetchProfile);

    if (!isEmpty(response)) {
      localStorage.setItem("userData", JSON.stringify(response));
      yield put(profileSuccess(FETCH_PROFILE, response));
    } else {
      yield put(profileError(FETCH_PROFILE, response));
    }
  } catch (error) {
    toast_error(error.message);
    yield call(logoutUser);
    yield put(profileError(FETCH_PROFILE, error));
  }
}

export function* watchFetchUserProfile() {
  yield takeEvery(FETCH_PROFILE, fetchUserProfile);
}
function* profileSaga() {
  yield all([fork(watchFetchUserProfile)]);
}
export default profileSaga;
