import React, { useEffect } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getAttachmentListData,
  editAttachment,
} from "../../store/attachments/action";
import { editAttachmentSchema } from "../../models/attachments";
import {
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import { getString } from "Components/Common/FormattedString";

const EditAttachment = ({ attachment, toggle, visibility }) => {
  const dispatch = useDispatch();

  const { loading, formValidationErrors } = useSelector(
    (state) => state.Attachments,
  );
  const { user } = useSelector((rootState) => rootState.Profile);
  const businessUnitOptions = [{ value: "all", label: "All" }].concat(
    (user.businessUnits || []).map((item) => ({
      value: item.id,
      label: item.name,
    })),
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: attachment?.name || "",
      businessUnitId: attachment?.businessUnitId || "all",
    },
    validationSchema: editAttachmentSchema,
    onSubmit: (values) => {
      dispatch(
        editAttachment(
          attachment.id,
          {
            name: values.name,
            businessUnitId:
              values.businessUnitId === "all"
                ? undefined
                : values.businessUnitId,
          },
          () => {
            dispatch(getAttachmentListData());
            toggle(null, false);
          },
        ),
      );
    },
  });

  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    setValidationErrors(validation, formValidationErrors);
  }, [formValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={"Edit " + attachment?.name}
        formId={"edit-attachment"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-attachment"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormSelect
                name="businessUnitId"
                label={getString("business_unit")}
                defaultValue={businessUnitOptions.find(
                  (b) => b.value === validation.values.businessUnitId,
                )}
                value={validation.values.businessUnitId}
                onChange={(selectedOption) =>
                  handleSelectChange(
                    validation,
                    selectedOption,
                    "businessUnitId",
                  )
                }
                isMulti={false}
                options={businessUnitOptions}
                error={validation.errors["businessUnitId"]}
              />
              <FormTextField
                label={getString("file_name")}
                name={"name"}
                value={validation.values["name"]}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors["name"]}
                touched={validation.touched["name"]}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditAttachment;
