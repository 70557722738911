// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachment-link {
  display: flex;
  gap: 4px;
  align-items: center;
}
.attachment-link__thumb {
  font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/AttachmentLink/AttachmentLink.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;EACA,mBAAA;AACF;AACE;EACE,eAAA;AACJ","sourcesContent":[".attachment-link {\n  display: flex;\n  gap: 4px;\n  align-items: center;\n\n  &__thumb {\n    font-size: 18px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
