import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import { toast_error, toast_success } from "../../helpers/toast_helper";

// Crypto Redux States
import {
  GET_MEETING_POINT_LIST_DATA,
  POST_MEETING_POINT,
  EDIT_MEETING_POINT,
  ARCHIVE_MEETING_POINT,
  RESTORE_MEETING_POINT,
} from "./actionType";
import { meetingPointsApiSuccess, meetingPointsApiError } from "./action";

// Include Both Helper File with needed methods
import {
  getMeetingPointList,
  postMeetingPoint,
  editMeetingPoint,
  archiveMeetingPoint,
  restoreMeetingPoint,
} from "../../helpers/API/core-service/cs_backend_helper";

function* getMeetingPointListData({ payload: data }) {
  try {
    let response;
    response = yield call(getMeetingPointList, data);
    yield put(meetingPointsApiSuccess(GET_MEETING_POINT_LIST_DATA, response));
  } catch (error) {
    yield put(meetingPointsApiError(GET_MEETING_POINT_LIST_DATA, error));
  }
}

function* createMeetingPoint({ payload: meetingPoint }) {
  try {
    const response = yield call(
      postMeetingPoint,
      meetingPoint.data,
      meetingPoint.onSuccess,
    );
    yield put(meetingPointsApiSuccess(POST_MEETING_POINT, response));
    toast_success("Meeting point added successfully");
  } catch (error) {
    yield put(meetingPointsApiError(POST_MEETING_POINT, error));
    toast_error(error.data.message);
  }
}

function* editMeetingPointEntry({ payload: meetingPoint }) {
  try {
    const response = yield call(
      editMeetingPoint,
      meetingPoint.id,
      meetingPoint.data,
      meetingPoint.onSuccess,
    );
    yield put(meetingPointsApiSuccess(EDIT_MEETING_POINT, response));
    toast_success("Meeting point edited successfully");
  } catch (error) {
    yield put(meetingPointsApiError(EDIT_MEETING_POINT, error));
    toast_error(error.data.message);
  }
}

function* archiveMeetingPointEntry({ payload: meetingPoint }) {
  try {
    const response = yield call(
      archiveMeetingPoint,
      meetingPoint.id,
      meetingPoint.onSuccess,
    );
    yield put(meetingPointsApiSuccess(ARCHIVE_MEETING_POINT, response));
    toast_success("Meeting point removed");
  } catch (error) {
    yield put(meetingPointsApiError(ARCHIVE_MEETING_POINT, error));
    toast_error(error.data.message);
  }
}

function* restoreMeetingPointEntry({ payload: meetingPoint }) {
  try {
    const response = yield call(
      restoreMeetingPoint,
      meetingPoint.id,
      meetingPoint.onSuccess,
    );
    yield put(meetingPointsApiSuccess(RESTORE_MEETING_POINT, response));
    toast_success("Meeting point restored");
  } catch (error) {
    yield put(meetingPointsApiError(RESTORE_MEETING_POINT, error));
    toast_error(error.data.message);
  }
}

export function* watchGetMeetingPointListData() {
  yield takeEvery(GET_MEETING_POINT_LIST_DATA, getMeetingPointListData);
}

export function* watchPostMeetingPoint() {
  yield takeEvery(POST_MEETING_POINT, createMeetingPoint);
}
export function* watchEditMeetingPoint() {
  yield takeEvery(EDIT_MEETING_POINT, editMeetingPointEntry);
}
export function* watchArchiveMeetingPoint() {
  yield takeEvery(ARCHIVE_MEETING_POINT, archiveMeetingPointEntry);
}
export function* watchRestoreMeetingPoint() {
  yield takeEvery(RESTORE_MEETING_POINT, restoreMeetingPointEntry);
}

function* meetingPointsSaga() {
  yield all([
    fork(watchGetMeetingPointListData),
    fork(watchPostMeetingPoint),
    fork(watchEditMeetingPoint),
    fork(watchArchiveMeetingPoint),
    fork(watchRestoreMeetingPoint),
  ]);
}

export default meetingPointsSaga;
