import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { UpsellsList } from "./UpsellsList";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";
import AddUpsell from "./AddUpsell";

const Upsells = () => {
  document.title = getString("upsells_page_title");
  const [upsellAddVisible, setUpsellAddVisible] = useState(false);
  const toggleUpsellAdd = () => {
    setUpsellAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("upsells_list")}
                  actionBtnLabel={getString("upsell_sm")}
                  actionBtn={toggleUpsellAdd}
                  actionBtnPermission="UPSELL_CREATE"
                >
                  <UpsellsList />
                </EntityCardList>
              </div>
            </Col>
          </Row>
          {upsellAddVisible && (
            <AddUpsell visibility={upsellAddVisible} toggle={toggleUpsellAdd} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Upsells;
