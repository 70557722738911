import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Spinner } from "reactstrap";
import FormTextArea from "Components/Entity/FormTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { FormattedString } from "Components/Common/FormattedString";

const ParticipantNote = ({
  handleSubmitNote,
  initialValues = { content: "" },
  disabled = false,
}) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      content: Yup.string().required("Note cannot be empty"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      handleSubmitNote(values).finally(() => {
        setLoading(false);
      });
    },
  });

  const handleCancel = () => {
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <FormTextArea
        name="content"
        value={formik.values.content}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.content}
        disabled={loading || disabled}
      />
      {formik.dirty && (
        <div className="d-flex justify-content-center mt-3 gap-5">
          <LinkButton
            color="secondary"
            type="button"
            onClick={handleCancel}
            disabled={loading || disabled}
          >
            <i className="ri-close-line" style={{ marginRight: "4px" }}></i>
            <FormattedString id="cancel" />
          </LinkButton>
          <LinkButton
            color="primary"
            type="submit"
            onClick={formik.handleSubmit}
            disabled={loading || disabled}
          >
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <>
                <i className="ri-edit-line" style={{ marginRight: "4px" }}></i>
                <FormattedString id="update" />
              </>
            )}
          </LinkButton>
        </div>
      )}
    </form>
  );
};

export default ParticipantNote;
