import React from "react";
import { AngloTooltip } from "../AngloTooltip/AngloTooltip";
import "./ParticipantEquation.scss";
import { getString } from "../FormattedString";

const colorMap = {
  af_participants_data: "#1f77b4",
  af_summary: "#ff7f0e",
  af_billing_data: "#2ca02c",
  af_participant_upsells: "#d62728",
};

export const ParticipantEquation = ({ summary }) => {
  const { quantity, occupancyFraction, totalCapacity } = summary;
  const totalSum = quantity.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="participant-equation">
      {totalSum > 0 && (
        <>
          {quantity.map((item, index) => (
            <React.Fragment key={index}>
              <AngloTooltip text={getString(item.status)}>
                <span
                  className={`participant-equation__val participant-equation__val--${item.status}`}
                  style={{ color: colorMap[item.status] || "defaultColor" }}
                >
                  {item.count}
                </span>
              </AngloTooltip>
              {index < quantity.length - 1 && (
                <span className="participant-equation__sign">+</span>
              )}
            </React.Fragment>
          ))}
          <span className="participant-equation__sign">=</span>
        </>
      )}
      <AngloTooltip text={getString("total_sum")}>
        <span className="participant-equation__val participant-equation__val--sum">
          {totalSum}
        </span>
      </AngloTooltip>
      <span className="participant-equation__sign participant-equation__sign--bright">
        /
      </span>
      <AngloTooltip text={getString("total_capacity")}>
        <span
          className={`participant-equation__val participant-equation__val--total`}
          style={{ color: colorMap.totalCapacity || "defaultColor" }}
        >
          {totalCapacity}
        </span>
      </AngloTooltip>
    </div>
  );
};
