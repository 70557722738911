// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attachments-simplified-cell--disabled {
  pointer-events: none;
  color: #b3b3b3;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Cells/AttachmentsSimplifiedCell.scss"],"names":[],"mappings":"AACE;EACE,oBAAA;EACA,cAAA;EACA,mBAAA;AAAJ","sourcesContent":[".attachments-simplified-cell {\n  &--disabled {\n    pointer-events: none;\n    color: #b3b3b3;\n    cursor: not-allowed;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
