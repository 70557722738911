import * as Yup from "yup";
import { getString } from "../Components/Common/FormattedString";

const priceSchema = Yup.object({
  // installmentId: Yup.string().required(),
  price: Yup.number()
    .required()
    .positive()
    .test(
      "is-decimal-or-integer",
      getString("price_must_be_integer_or_proper_float"),
      (value) => /^\d+(\.\d)?$/.test(value),
    ),
});

const pricesForPacketInstallmentSchema = Yup.object({
  // installmentPlanId: Yup.string().required(),
  prices: Yup.array().of(priceSchema).required(),
});

export { pricesForPacketInstallmentSchema };
