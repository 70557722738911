import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_PAYMENT_METHOD_LIST_DATA,
} from "./actionType";

export const paymentMethodsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const paymentMethodsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getPaymentMethodListData = () => ({
  type: GET_PAYMENT_METHOD_LIST_DATA,
  payload: {},
});
