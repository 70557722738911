// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simplest-modal--mailpreview .preloader-wrap {
  height: 100%;
}
.simplest-modal--mailpreview .mail-preview-modal__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%;
}
.simplest-modal--mailpreview .modal-body {
  height: calc(80vh - 50px);
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationActivityTab/partials/MailPreviewModal.scss"],"names":[],"mappings":"AACE;EACE,YAAA;AAAJ;AAGI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;AADN;AAIE;EACE,yBAAA;AAFJ","sourcesContent":[".simplest-modal--mailpreview {\n  .preloader-wrap {\n    height: 100%;\n  }\n  .mail-preview-modal {\n    &__placeholder {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      padding: 20px;\n      height: 100%;\n    }\n  }\n  .modal-body {\n    height: calc(80vh - 50px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
