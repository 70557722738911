import React from "react";
import "./ParticipantNameCell.scss";

const ParticipantNameCell = ({ names }) => {
  return (
    <div className="participant-name-cell">
      {names && names.length > 0 ? (
        names.map((name, index) => (
          <div key={index} className="participant-name-cell__item">
            {name}
          </div>
        ))
      ) : (
        <div className="participant-name-cell__item">-</div>
      )}
    </div>
  );
};

export default ParticipantNameCell;
