import React, { useContext } from "react";
import { Modal } from "reactstrap";

import { getString } from "Components/Common/FormattedString";
import { ProgramDetailsContext } from "../../ProgramDetails";
import {
  detachCoordinatorFromProgram,
  detachHealthAndSafetyFromProgram,
} from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import ConfirmDetachBody from "Components/Entity/ConfirmDetachBody";

export const DeleteCoordinatorModal = ({ item, visible, setVisible }) => {
  const toggle = () => {
    setVisible(!visible);
  };
  const { programData, refreshProgramData } = useContext(ProgramDetailsContext);

  const deleteCoordinatorEntry = () => {
    switch (item.type) {
      case "coordinator":
        detachCoordinatorFromProgram(programData.id, item.packetId, item.id)
          .then(() => {
            toast_success(getString("coordinator_detached"));
            toggle(false);
            refreshProgramData();
          })
          .catch((e) => toast_error(e.message));
        break;
      case "health_and_safety":
        detachHealthAndSafetyFromProgram(programData.id, item.id)
          .then(() => {
            toast_success(getString("health_and_safety_detached"));
            toggle(false);
            refreshProgramData();
          })
          .catch((e) => toast_error(e.message));
        break;
      default:
        console.error("Invalid item type");
    }
  };

  return (
    <Modal
      isOpen={visible}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDetachBody
        entityName={getString(`${item.type}_sm`)}
        toggle={toggle}
        deleteAction={() => deleteCoordinatorEntry()}
      />
    </Modal>
  );
};
