import classNames from "classnames";
import "./Tile.scss";
import { Card, CardBody } from "reactstrap";
import { CustomFormFeedback } from "../CustomFormFeedback/CustomFormFeedback";

export const Tile = ({
  title,
  paddingDisabled = false,
  titleIcon,
  titleDropdown,
  gap = "4px",
  children,
  error,
}) => {
  const formattedTitle = title.toLowerCase().replace(/\s+/g, "");
  return (
    <>
      <Card
        className={classNames([
          "tile",
          paddingDisabled && "tile--padding-disabled",
          `tile--${formattedTitle}`,
          error && "tile--error",
        ])}
      >
        <CardBody style={{ gap }}>
          <div className="tile__row">
            <div className="tile__row__left">
              {titleIcon}
              <div className="card-title">{title}</div>
            </div>
            {titleDropdown}
          </div>

          <div className="card-content">{children}</div>
        </CardBody>
      </Card>
      {error && (
        <CustomFormFeedback className="tile__error-message">
          {error}
        </CustomFormFeedback>
      )}
    </>
  );
};
