const Navdata = () => {
  const click = (e) => {
    [...e.target.parentNode.classList, ...e.target.classList].includes(
      "active",
    ) && e.preventDefault();
  };

  const menuItems = [
    {
      id: "menu",
      isHeader: true,
    },
    {
      id: "pp-applications",
      icon: "las la-file",
      permission: "PP_APPLICATION_READ",
      subItems: [
        {
          id: "applications",
          link: "/pp-applications",
          parentId: "pp-applications",
          click,
          permission: "PP_APPLICATION_READ",
        },
        {
          id: "customers",
          link: "/customers",
          parentId: "pp-applications",
          click,
          permission: "CUSTOMER_READ",
        },
      ],
    },
    {
      id: "ip-applications",
      icon: "las la-file-alt",
      subItems: [
        {
          id: "ip-participants",
          link: "/ip-participants",
          parentId: "ip-applications",
          click,
          permission: "IP_PARTICIPANT_READ",
        },
      ],
    },
    {
      id: "coordinators",
      permission: "COORDINATOR_READ",
      icon: "las la-user-tie",
      link: "/coordinators",
      click,
    },
    {
      id: "product-management",
      icon: "las la-box",
      subItems: [
        {
          id: "programs",
          link: "/programs",
          permission: "PROGRAM_READ",
          click,
          parentId: "product-management",
        },
        {
          id: "hotels",
          link: "/venues",
          permission: "HOTEL_READ",
          click,
          parentId: "product-management",
        },
        {
          id: "program-packet-templates",
          link: "/program-packet-templates",
          parentId: "product-management",
          click,
          permission: "PROGRAM_PACKET_TEMPLATE_READ",
        },
        {
          id: "meeting-points",
          link: "/meeting-points",
          click,
          parentId: "product-management",
          permission: "MEETING_POINT_READ",
        },
        {
          id: "upsells",
          link: "/upsells",
          click,
          parentId: "product-management",
          permission: "UPSELL_READ",
        },
      ],
    },
    {
      id: "settings",
      icon: "las la-cog",
      subItems: [
        {
          id: "users",
          link: "/users",
          parentId: "settings",
          click,
          permission: "USER_READ",
        },
        {
          id: "payments",
          isChildItem: true,
          click: (e) => e.preventDefault(),
          childItems: [
            {
              id: "payment-methods",
              link: "/payment-methods",
              parentId: "payments",
              click,
              permission: "PAYMENT_METHOD_READ",
            },
            {
              id: "point-of-sell",
              link: "/points-of-sell",
              parentId: "payments",
              click,
              permission: "POINT_OF_SELL_READ",
            },
            {
              id: "installment-plans",
              parentId: "payments",
              link: "/installment-plans",
              click,
              permission: "INSTALLMENT_PLAN_READ",
            },
          ],
        },
        {
          id: "attachments",
          link: "/attachments",
          parentId: "settings",
          click,
          permission: "ATTACHMENT_READ",
        },
      ],
    },
  ];
  return menuItems;
};
export default Navdata;
