import "./ProgramPacketTemplateAttachmentsList.scss";

import { SingleAttachment } from "./partials/SingleAttachment/SingleAttachment";
import { AttachmentAdder } from "./partials/AttachmentAdder/AttachmentAdder";

export const ProgramPacketTemplateAttachmentsList = ({
  attachments,
  programPacketTemplate,
  selectedAttachments,
  setSelectedAttachments,
}) => {
  return (
    <div className="attachments-list">
      {attachments.map((a, i) => (
        <SingleAttachment
          key={i}
          programPacketTemplate={programPacketTemplate}
          attachment={a}
          removable={setSelectedAttachments ? true : false}
        />
      ))}
      {setSelectedAttachments && (
        <AttachmentAdder
          activeAttachments={attachments}
          selectedAttachments={selectedAttachments}
          setSelectedAttachments={setSelectedAttachments}
        />
      )}
    </div>
  );
};
