import React, { useCallback } from "react";
import { deleteUser, getUserListData } from "../../store/users/action";
import { useDispatch } from "react-redux";

import { Modal } from "reactstrap";
import { getString } from "Components/Common/FormattedString";
import ConfirmDeleteBody from "Components/Entity/ConfirmDeleteBody";

const DeleteUser = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();

  const deleteUserEntry = useCallback(() => {
    dispatch(
      deleteUser(itemId, () => {
        dispatch(getUserListData());
        toggle();
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmDeleteBody
        entityName={getString("user_sm")}
        toggle={toggle}
        deleteAction={deleteUserEntry}
      />
    </Modal>
  );
};

export default DeleteUser;
