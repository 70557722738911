import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const IpParticipantDetails = ({ itemId, toggle, visible }) => {
  const { ipParticipantsData } = useSelector(
    (rootState) => rootState.IpParticipants,
  );

  const item = ipParticipantsData.find(
    (ipParticipant) => ipParticipant.id === itemId,
  );

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="ipParticipant-details-modal"
      title={getString("ip_participant_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
