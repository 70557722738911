import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import { toast_info } from "helpers/toast_helper";
import { Link } from "react-router-dom";

export const MapUrlCell = ({ mapUrl }) => {
  return (
    <div className="d-flex gap-2 align-items-center">
      <AngloTooltip stringId="copy_to_clipboard">
        <button
          type="button"
          className="btn btn-sm btn-secondary btn-icon"
          onClick={() => {
            navigator.clipboard.writeText(mapUrl);
            toast_info("Path copied to clipboard");
          }}
        >
          <i className="ri-file-copy-line"></i>
        </button>
      </AngloTooltip>
      <AngloTooltip stringId="open_in_new_tab">
        <Link to={mapUrl} target="_blank">
          <button type="button" className="btn btn-sm btn-secondary btn-icon">
            <i className="ri-external-link-line"></i>
          </button>
        </Link>
      </AngloTooltip>
    </div>
  );
};
