import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

import {
  handleFileChange,
  handleInputChange,
  handleSelectChange,
  setValidationErrors,
} from "helpers/validation_helper";
import OffcanvasRight from "Components/Entity/OffcanvasRight";
import { getString, getStringSync } from "Components/Common/FormattedString";
import { FormFilePicker } from "Components/Entity/FormFilePicker";
import { convertFileToBase64 } from "helpers/files_helper";
import FormTextField from "Components/Entity/FormTextField";
import { FormSelect } from "Components/Entity/FormSelect";
import {
  getIpParticipantListData,
  postIpAttachment,
} from "store/ipParticipants/action";
import { ipDocumentTypeOptions } from "models/ipParticipants";

const AddIpParticipantAttachment = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      file: null,
      description: "",
      type: "",
    },
    validationSchema: Yup.object({
      file: Yup.string().required(getStringSync("upload_file")),
      description: Yup.string()
        .min(4, "Minimum 4 characters required")
        .required(getStringSync("description_required")),
      type: Yup.string().required(getStringSync("type_required")),
    }),
    onSubmit: (values) => {
      setLoading(true);
      convertFileToBase64(values.file)
        .then((base64) => {
          dispatch(
            postIpAttachment(
              itemId,
              {
                fileBase64: base64,
                description: values.description,
                type: values.type,
              },
              () => {
                // refresh ip list
                dispatch(getIpParticipantListData());
                setLoading(false);
                toggle(false);
              },
            ),
          );
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    },
  });

  // Form validation (api)
  useEffect(() => {
    if (validation.errors == null) {
      return;
    }
    setValidationErrors(validation, validation.errors);
  }, [validation.errors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("attachments_add_new_attachment")}
        formId={"add-attachment"}
        validationRule={validation.isValid}
        buttonLabel={getString("add")}
        loading={loading}
      >
        <Form
          id="add-attachment"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("description")}
                name={"description"}
                value={validation.values["description"]}
                onChange={(e) =>
                  handleInputChange(validation, e, "description")
                }
                error={validation.errors["description"]}
                touched={validation.touched["description"]}
              />
              <FormSelect
                label={getString("type")}
                name={"type"}
                value={validation.values.type}
                defaultValue={ipDocumentTypeOptions.find(
                  (r) => r.value === validation.values.type,
                )}
                onChange={(selectedOption) =>
                  handleSelectChange(validation, selectedOption, "type")
                }
                isMulti={false}
                options={ipDocumentTypeOptions}
                error={validation.errors["type"]}
              />
              <FormFilePicker
                name="file"
                label={getString("upload_file")}
                error={validation.errors["file"]}
                onFileChange={(e) => {
                  handleFileChange(validation, e, "file");
                }}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default AddIpParticipantAttachment;
