import { FormattedString, getString } from "Components/Common/FormattedString";
import { Tile } from "Components/Common/Tile/Tile";
import React, { useContext, useState } from "react";
import { Col, Row, TabPane } from "reactstrap";
import { ApplicationDetailsContext } from "../../../ApplicationDetails";
import { FormSummary } from "./partials/FormSummary/FormSummary";
import FormikTextArea from "Components/Entity/FormikTextArea";
import { LinkButton } from "Components/Common/LinkButton/LinkButton";
import { Payments } from "../../components/Payments/Payments";
import { BillingData } from "../../components/BillingData/BillingData";
import { ApplicationUtms } from "../../components/ApplicationUtms/ApplicationUtms";
import { CustomerDetails } from "../../components/CustomerDetails/CustomerDetails";
import NewNoteForm from "./partials/NewNoteForm";
import { addEntityNote } from "helpers/API/core-service/cs_backend_helper";
import { toast_error, toast_success } from "helpers/toast_helper";
import AdditionalNote from "./partials/AdditionalNote";
import ParticipantNote from "./ParticipantNote/ParticipantNote";
import { DeleteEntityNoteModal } from "./partials/DeleteEntityNoteModal";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import { useHasPermission } from "Components/Hooks/useHasPermission";

export const ApplicationOverviewTab = () => {
  const { applicationData, refreshApplicationData } = useContext(
    ApplicationDetailsContext,
  );
  const [showNewNoteForm, setShowNewNoteForm] = useState(false);
  const [deleteEntityNoteModalVisible, setDeleteEntityNoteModalVisible] =
    useState(false);
  const [entityNoteToDelete, setEntityNoteToDelete] = useState(null);

  const handleAddNewNote = () => {
    setShowNewNoteForm(true);
  };

  const handleSubmitNote = (entityId, type, content, name) => {
    return addEntityNote(entityId, type, content, name)
      .then(() => {
        toast_success(getString("comment_added"));
        refreshApplicationData();
      })
      .catch((e) => {
        toast_error(e.message);
        throw e;
      });
  };

  const handleDeleteNote = (noteId) => {
    setEntityNoteToDelete(noteId);
    setDeleteEntityNoteModalVisible(true);
  };

  const entityNotes = applicationData.entityNotes;

  const applicationNote = entityNotes.find(
    (note) => note.type === "application",
  );

  const clientNote = entityNotes.find((note) => note.type === "customer");
  const additionalNotes = entityNotes.filter(
    (note) => note.type === "application_additional",
  );

  const canEditApplication = useHasPermission("APPLICATION_UPDATE");

  return (
    <TabPane tabId="overview">
      <Row>
        <Col xxl={3}>
          <FormSummary />
        </Col>
        <Col xxl={6}>
          <Tile title={getString("payments")} gap="14px">
            <Payments />
          </Tile>

          <Tile
            title={getString("application_note")}
            gap="14px"
            titleDropdown={
              applicationNote &&
              canEditApplication && (
                <button
                  type="button"
                  className="btn btn-sm btn-soft-danger btn-icon"
                  onClick={() => handleDeleteNote(applicationNote.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              )
            }
          >
            <FormikTextArea
              name="applicationNote"
              value={applicationNote ? applicationNote.content : ""}
              key={applicationNote?.content || "applicationNote"}
              disabled={!canEditApplication}
              handleSubmit={(value) =>
                handleSubmitNote(applicationData.id, "application", value)
              }
            />
          </Tile>

          <Tile
            title={getString("client_note")}
            gap="14px"
            titleDropdown={
              clientNote &&
              canEditApplication && (
                <button
                  type="button"
                  className="btn btn-sm btn-soft-danger btn-icon"
                  onClick={() => handleDeleteNote(clientNote.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </button>
              )
            }
          >
            <FormikTextArea
              name="clientNote"
              value={clientNote ? clientNote.content : ""}
              disabled={!canEditApplication}
              handleSubmit={(value) =>
                handleSubmitNote(
                  applicationData.payerDetails.customerId,
                  "customer",
                  value,
                )
              }
            />
          </Tile>

          {applicationData.participants.map((participant, index) => {
            const additionalNote = applicationData.entityNotes.find(
              (note) => note.entityId === participant.participantId,
            );

            return additionalNote ? (
              <Tile
                key={index}
                title={`${participant.firstName} ${participant.lastName} ${getString("note")}`}
                gap="14px"
                titleDropdown={
                  canEditApplication && (
                    <button
                      type="button"
                      className="btn btn-sm btn-soft-danger btn-icon"
                      onClick={() => handleDeleteNote(additionalNote.id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </button>
                  )
                }
              >
                <ParticipantNote
                  initialValues={additionalNote}
                  key={additionalNote?.content || "additionalNote"}
                  disabled={!canEditApplication}
                  handleSubmitNote={(value) =>
                    handleSubmitNote(
                      additionalNote.entityId,
                      "participant",
                      value.content,
                    )
                  }
                />
              </Tile>
            ) : (
              <Tile
                key={index}
                title={`${participant.firstName} ${participant.lastName} ${getString("note")}`}
                gap="14px"
              >
                <FormikTextArea
                  name={`participantNote_${index}`}
                  value={participant.note}
                  disabled={!canEditApplication}
                  handleSubmit={(value) =>
                    handleSubmitNote(
                      participant.participantId,
                      "participant",
                      value,
                    )
                  }
                />
              </Tile>
            );
          })}

          <Tile title={getString("client_comment")} gap="14px">
            <FormikTextArea
              name="clientComment"
              disabled
              value={applicationData.clientComment}
              handleSubmit={(value) =>
                handleSubmitNote(applicationData.id, "clientComment", value)
              }
            />
          </Tile>

          {additionalNotes.map((note, index) => (
            <Tile
              title={getString(note.name) + " " + getString("note")}
              gap="14px"
              key={note.content + note.id || index}
              titleDropdown={
                canEditApplication && (
                  <button
                    type="button"
                    className="btn btn-sm btn-soft-danger btn-icon"
                    onClick={() => handleDeleteNote(note.id)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </button>
                )
              }
            >
              <AdditionalNote
                initialValues={note}
                disabled={!canEditApplication}
                handleSubmitNote={(values) =>
                  handleSubmitNote(
                    applicationData.id,
                    "application_additional",
                    values.content,
                    values.name,
                  )
                }
              />
            </Tile>
          ))}

          {!showNewNoteForm && (
            <WithPermission permissionName="APPLICATION_UPDATE">
              <LinkButton onClick={handleAddNewNote}>
                <i className="ri-add-line" style={{ marginRight: "4px" }}></i>
                <FormattedString id="add_new_note" />
              </LinkButton>
            </WithPermission>
          )}

          {showNewNoteForm && (
            <Tile title={getString("new_note")} gap="14px">
              <NewNoteForm
                handleSubmitNote={(values) =>
                  handleSubmitNote(
                    applicationData.id,
                    "application_additional",
                    values.content,
                    values.name,
                  )
                }
                toggle={() => setShowNewNoteForm(false)}
              />
            </Tile>
          )}
        </Col>
        <Col xxl={3}>
          <Tile title={getString("customer_details")} gap="14px">
            <CustomerDetails />
          </Tile>

          <Tile
            title={getString("billing_data")}
            gap="14px"
            titleIcon={
              <i className="ri-map-pin-line align-middle text-muted"></i>
            }
          >
            <BillingData />
          </Tile>

          <Tile title={getString("utm_parameters")} gap="14px">
            <ApplicationUtms />
          </Tile>
        </Col>
      </Row>

      {deleteEntityNoteModalVisible && (
        <DeleteEntityNoteModal
          noteId={entityNoteToDelete}
          visibility={true}
          toggle={() => setDeleteEntityNoteModalVisible(false)}
        />
      )}
    </TabPane>
  );
};
