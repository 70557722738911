import React from "react";
import { useSelector } from "react-redux";
import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const InstallmentPlanDetails = ({ itemId, toggle, visible }) => {
  const { installmentPlansData } = useSelector(
    (rootState) => rootState.InstallmentPlans,
  );
  const item = installmentPlansData.find(
    (installmentPlan) => installmentPlan.id === itemId,
  );

  return (
    item && (
      <SimplestModal
        visible={visible}
        className="modal-lg"
        setVisible={() => toggle(false)}
        id="installmentPlan-details-modal"
        title={getString("installment_plan_details")}
        showFooter={false}
      >
        <DetailsBox item={item} key={item?.id} />
      </SimplestModal>
    )
  );
};
