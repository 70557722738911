import { getStringSync } from "Components/Common/FormattedString";
import * as Yup from "yup";

export const installmentTypes = [
  { value: "educational", label: getStringSync("educational") },
  { value: "touristic", label: getStringSync("touristic") },
];

const installmentSchema = Yup.object({
  dueDays: Yup.number()
    .nullable()
    .min(1, getStringSync("min_due_days_message")),
  currency: Yup.string()
    .min(3, getStringSync("min_currency_message"))
    .required(getStringSync("currency_required")),
  type: Yup.string().min(4).required(getStringSync("type_required")),
  pointOfSell: Yup.string().required(getStringSync("point_of_sell_required")),
});

const createInstallmentPlanSchema = Yup.object({
  name: Yup.string().min(4).required(getStringSync("name_required")),
  availabilityDays: Yup.number()
    .min(1, getStringSync("min_availability_days_message"))
    .required(getStringSync("availability_days_required")),
  installmentCount: Yup.number()
    .min(2, getStringSync("min_installment_count_message"))
    .max(10, getStringSync("max_installment_count_message"))
    .required(getStringSync("installment_count_required")),
  installments: Yup.array()
    .of(installmentSchema)
    .test(
      "check-due-days",
      getStringSync("due_days_required"),
      function (installments) {
        return installments.every((installment, index) => {
          if (index === 0) {
            return true;
          }
          return (
            installment.dueDays !== null && installment.dueDays !== undefined
          );
        });
      },
    ),
});

export { createInstallmentPlanSchema };
