import React, { useContext, useState } from "react";
import "./CoordinatorsView.scss";
import { Button } from "reactstrap";
import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import ActionButtons from "Components/Entity/ActionButtons/ActionButtons";
import { FormattedString } from "Components/Common/FormattedString";
import { DeleteCoordinatorModal } from "../../modals/DeleteCoordinatorModal";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";

export const CoordinatorsView = ({ onAdd, addText }) => {
  const { programData, canUpdateProgram } = useContext(ProgramDetailsContext);

  const [deleteCoordinatorModalVisible, setDeleteCoordinatorModalVisible] =
    useState(false);

  const [item, setItem] = useState(null);

  return (
    <div className="coordinators-view">
      {programData.allCoordinators.map((user) => (
        <div className="d-flex align-items-center pb-2" key={`user-${user.id}`}>
          <div className="flex-shrink-0 me-2">
            {user.type === "health_and_safety" ? (
              <i className="mdi mdi-medical-bag text-muted coordinators-view__icon"></i>
            ) : (
              <i className="mdi mdi-account-supervisor text-muted coordinators-view__icon"></i>
            )}
          </div>
          <div className="flex-grow-1">
            <div>
              <h5 className="fs-14 mb-1">
                {user.firstName + " " + user.lastName}
              </h5>
              <p className="fs-13 text-muted mb-0 d-flex gap-2">
                <FormattedString id={user.type} />
                {user.packetName && ","}
                <span style={{ color: "var(--vz-blue)", fontWeight: 500 }}>
                  {user.packetName}
                </span>
              </p>
            </div>
          </div>
          {canUpdateProgram && (
            <ActionButtons
              onDelete={() => {
                setItem(user);
                setDeleteCoordinatorModalVisible(true);
              }}
              itemId={user.id}
            />
          )}
        </div>
      ))}
      {onAdd && (
        <Button onClick={onAdd} color="info" className="w-100">
          <i className="ri-add-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
          {addText}
        </Button>
      )}
      {deleteCoordinatorModalVisible && (
        <DeleteCoordinatorModal
          item={item}
          visible={deleteCoordinatorModalVisible}
          setVisible={setDeleteCoordinatorModalVisible}
        />
      )}
    </div>
  );
};
