const permissions = {
  dashboard: ["DASHBOARD_READ"],
  attachment: [
    "ATTACHMENT_READ",
    "ATTACHMENT_CREATE",
    "ATTACHMENT_UPDATE",
    "ATTACHMENT_RESTORE",
    "ATTACHMENT_ARCHIVE",
  ],
  coordinator: [
    "COORDINATOR_READ",
    "COORDINATOR_CREATE",
    "COORDINATOR_UPDATE",
    "COORDINATOR_RESTORE",
    "COORDINATOR_ARCHIVE",
  ],
  hotel: [
    "HOTEL_READ",
    "HOTEL_CREATE",
    "HOTEL_UPDATE",
    "HOTEL_RESTORE",
    "HOTEL_ARCHIVE",
  ],
  installmentPlan: [
    "INSTALLMENT_PLAN_READ",
    "INSTALLMENT_PLAN_CREATE",
    "INSTALLMENT_PLAN_UPDATE",
    "INSTALLMENT_PLAN_DELETE",
  ],
  meeting: [
    "MEETING_READ",
    "MEETING_CREATE",
    "MEETING_UPDATE",
    "MEETING_RESTORE",
    "MEETING_ARCHIVE",
  ],
  meetingPoint: [
    "MEETING_POINT_READ",
    "MEETING_POINT_CREATE",
    "MEETING_POINT_UPDATE",
    "MEETING_POINT_RESTORE",
    "MEETING_POINT_ARCHIVE",
  ],
  paymentMethod: ["PAYMENT_METHOD_READ"],
  pointOfSell: ["POINT_OF_SELL_READ"],
  programPacketTemplate: [
    "PROGRAM_PACKET_TEMPLATE_READ",
    "PROGRAM_PACKET_TEMPLATE_CREATE",
    "PROGRAM_PACKET_TEMPLATE_UPDATE",
    "PROGRAM_PACKET_TEMPLATE_RESTORE",
    "PROGRAM_PACKET_TEMPLATE_ARCHIVE",
  ],
  program: [
    "PROGRAM_READ",
    "PROGRAM_CREATE",
    "PROGRAM_DUPLICATE",
    "PROGRAM_UPDATE",
    "PROGRAM_ARCHIVE",
    "PROGRAM_RESTORE",
    "PROGRAM_DELETE",
  ],
  upsell: [
    "UPSELL_READ",
    "UPSELL_CREATE",
    "UPSELL_UPDATE",
    "UPSELL_RESTORE",
    "UPSELL_ARCHIVE",
    "UPSELL_ATTACHMENTS",
  ],
  application: [
    "PP_APPLICATION_READ",
    "PP_APPLICATION_CREATE",
    "PP_APPLICATION_UPDATE",
    "PP_APPLICATION_RESTORE",
    "PP_APPLICATION_ARCHIVE",
  ],
  participants: [
    "IP_PARTICIPANT_READ",
    "IP_PARTICIPANT_CREATE",
    "IP_PARTICIPANT_UPDATE",
    "IP_PARTICIPANT_DELETE",
    "IP_PARTICIPANT_BLOCK",
    "IP_PARTICIPANT_ADD_DOCUMENT",
    "IP_PARTICIPANT_UPLOAD_FILES",
  ],
  user: [
    "USER_READ",
    "USER_CREATE",
    "USER_UPDATE",
    "USER_DELETE",
    "LAST_LOGIN_READ",
  ],
  businessUnit: ["BUSINESS_UNIT_READ"],
  programDiscount: ["PROGRAM_DISCOUNT_READ"],
  programMeeting: ["PROGRAM_MEETING_READ"],
  programPacket: ["PROGRAM_PACKET_READ"],
  programPrice: ["PROGRAM_PRICE_READ"],
};

export const rolePermissions = {
  ROLE_SUPER_ADMIN: "*",
  ROLE_ADMIN: [
    ...permissions.dashboard,
    ...permissions.user,
    ...permissions.application,
    ...permissions.attachment,
    ...permissions.businessUnit,
    ...permissions.coordinator,
    ...permissions.hotel,
    ...permissions.installmentPlan,
    ...permissions.participants,
    ...permissions.meetingPoint,
    ...permissions.paymentMethod,
    ...permissions.pointOfSell,
    ...permissions.program,
    ...permissions.programDiscount,
    ...permissions.programMeeting,
    ...permissions.programPacket,
    ...permissions.programPacketTemplate,
    ...permissions.programPrice,
    ...permissions.upsell,
    "ROLE_MANAGER_MANAGEMENT",
    "ROLE_ADMIN_MANAGEMENT",
  ],
  ROLE_MANAGER: [
    ...permissions.dashboard,
    ...permissions.application,
    "ATTACHMENT_READ",
    "BUSINESS_UNIT_READ",
    ...permissions.coordinator,
    ...permissions.hotel,
    "INSTALLMENT_PLAN_READ",
    ...permissions.participants,
    ...permissions.meetingPoint,
    "PAYMENT_METHOD_READ",
    "POINT_OF_SELL_READ",
    ...permissions.program,
    ...permissions.programDiscount,
    ...permissions.programMeeting,
    ...permissions.programPacket,
    ...permissions.programPacketTemplate,
    ...permissions.upsell,
    "USER_CREATE",
    "USER_READ",
    "USER_UPDATE",
    "CUSTOMER_READ",
  ],
  ROLE_PRODUCT_TEAM: [
    ...permissions.dashboard,
    "PP_APPLICATION_READ",
    ...permissions.coordinator,
    ...permissions.hotel,
    "IP_PARTICIPANT_READ",
    ...permissions.meetingPoint,
    ...permissions.program,
    ...permissions.programMeeting,
    ...permissions.programPacket,
    ...permissions.programPacketTemplate,
    ...permissions.upsell,
  ],
  ROLE_SALES_TEAM: [
    ...permissions.dashboard,
    ...permissions.application,
    "PROGRAM_READ",
  ],
  ROLE_IP_SALES_TEAM: [...permissions.dashboard, "PROGRAM_READ"],
  ROLE_MARKETING_TEAM: [
    ...permissions.dashboard,
    "PP_APPLICATION_READ",
    "PROGRAM_READ",
    "COORDINATOR_READ",
  ],
  ROLE_ACCOUNTANT_TEAM: [
    ...permissions.dashboard,
    ...permissions.application,
    "PROGRAM_READ",
    "COORDINATOR_READ",
  ],
};
