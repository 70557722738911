import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_ATTACHMENT_LIST_DATA,
  POST_ATTACHMENT,
  EDIT_ATTACHMENT,
  ARCHIVE_ATTACHMENT,
  RESTORE_ATTACHMENT,
} from "./actionType";

export const attachmentsApiSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const attachmentsApiError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const getAttachmentListData = () => ({
  type: GET_ATTACHMENT_LIST_DATA,
  payload: {},
});

export const postAttachment = (data, onSuccess) => ({
  type: POST_ATTACHMENT,
  payload: { data, onSuccess },
});

export const editAttachment = (id, data, onSuccess) => ({
  type: EDIT_ATTACHMENT,
  payload: { id, data, onSuccess },
});

export const archiveAttachment = (id, onSuccess) => ({
  type: ARCHIVE_ATTACHMENT,
  payload: { id, onSuccess },
});

export const restoreAttachment = (id, onSuccess) => ({
  type: RESTORE_ATTACHMENT,
  payload: { id, onSuccess },
});
