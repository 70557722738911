import { FormattedString } from "Components/Common/FormattedString";
import { WithPermission } from "Components/Common/WithPermission/WithPermission";
import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Table,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";

export const CoordinatorsTab = () => {
  return (
    <TabPane tabId="coordinators">
      <Card>
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <h5 className="card-title flex-grow-1 mb-0">
              <FormattedString id="documents" />
            </h5>
            <div className="flex-shrink-0">
              <WithPermission permissionName="PROGRAM_UPDATE">
                <Input
                  className="form-control d-none"
                  type="file"
                  id="formFile"
                />
                <Label htmlFor="formFile" className="btn btn-danger">
                  <i className="ri-upload-2-fill me-1 align-bottom"></i>{" "}
                  <FormattedString id="upload_file" />
                </Label>
              </WithPermission>
            </div>
          </div>
          <Row>
            <Col lg={12}>
              <div className="table-responsive">
                <Table className="table-borderless align-middle mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">
                        <FormattedString id="file_name" />
                      </th>
                      <th scope="col">
                        <FormattedString id="type" />
                      </th>
                      <th scope="col">
                        <FormattedString id="size" />
                      </th>
                      <th scope="col">
                        <FormattedString id="upload_date" />
                      </th>
                      <th scope="col">
                        <FormattedString id="action" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[].map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm">
                              <div
                                className={`avatar-title bg-${item.iconBackgroundClass}-subtle text-${item.iconBackgroundClass} rounded fs-20`}
                              >
                                <i className={item.icon}></i>
                              </div>
                            </div>
                            <div className="ms-3 flex-grow-1">
                              <h6 className="fs-15 mb-0">
                                <Link to="#">{item.fileName}</Link>
                              </h6>
                            </div>
                          </div>
                        </td>
                        <td>{item.fileType}</td>
                        <td>{item.fileSize}</td>
                        <td>{item.updatedDate}</td>
                        <td>
                          <UncontrolledDropdown direction="start">
                            <DropdownToggle
                              tag="a"
                              className="btn btn-light btn-icon"
                              id="dropdownMenuLink15"
                              role="button"
                            >
                              <i className="ri-equalizer-fill"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem>
                                <i className="ri-eye-fill me-2 align-middle text-muted" />
                                <FormattedString id="view" />
                              </DropdownItem>
                              <DropdownItem>
                                <i className="ri-download-2-fill me-2 align-middle text-muted" />
                                <FormattedString id="download" />
                              </DropdownItem>
                              <DropdownItem divider />
                              <DropdownItem>
                                <i className="ri-delete-bin-5-line me-2 align-middle text-muted" />
                                <FormattedString id="delete" />
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <div className="text-center mt-3">
                <Link to="#" className="text-success ">
                  <i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i>
                  <FormattedString id="load_more" />{" "}
                </Link>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </TabPane>
  );
};
