// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-installment-plan__input-wrap {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.add-installment-plan__input-wrap .btn-primary {
  height: 38px;
  margin-top: 29px;
  flex-shrink: 0;
}
.add-installment-plan__input-wrap [data-testid=select-installmentPlanId] {
  width: 100%;
}
.add-installment-plan .simplest-table th,
.add-installment-plan .simplest-table td {
  font-size: 11px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Programs/ProgramDetails/partials/modals/AddInstallmentPlanCanvas.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,QAAA;AAAJ;AAEI;EACE,YAAA;EACA,gBAAA;EACA,cAAA;AAAN;AAGI;EACE,WAAA;AADN;AAKE;;EAEE,eAAA;AAHJ","sourcesContent":[".add-installment-plan {\n  &__input-wrap {\n    display: flex;\n    justify-content: space-between;\n    gap: 8px;\n\n    .btn-primary {\n      height: 38px;\n      margin-top: 29px;\n      flex-shrink: 0;\n    }\n\n    [data-testid=\"select-installmentPlanId\"] {\n      width: 100%;\n    }\n  }\n\n  .simplest-table th,\n  .simplest-table td {\n    font-size: 11px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
