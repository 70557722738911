import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_APPLICATIONS_LIST_DATA,
  SET_CURRENT_PAGE,
} from "./actionType";

export const INIT_STATE = {
  applicationsData: [],
  formValidationErrors: {},
  error: null,
  loading: false,
  listInitialized: false,
  count: 0,
  currentPage: 1,
  totalPages: 0,
  pageSize: 10,
};

const Applications = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_APPLICATIONS_LIST_DATA:
      return {
        ...state,
        loading: true,
      };

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_APPLICATIONS_LIST_DATA:
          return {
            ...state,
            applicationsData: action.payload.data.data,
            count: action.payload.data.count,
            currentPage: action.payload.data.currentPage,
            totalPages: action.payload.data.totalPages,
            pageSize: action.payload.data.pageSize,
            listInitialized: true,
            loading: false,
          };

        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_APPLICATIONS_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };
        default:
          return state;
      }

    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    default:
      return state;
  }
};

export default Applications;
