// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.preloader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 290px);
}
.preloader-wrap--h100 {
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/Preloader/Preloader.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,2BAAA;AAAJ;AAEI;EACE,aAAA;AAAN","sourcesContent":[".preloader {\n  &-wrap {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: calc(100vh - 290px);\n\n    &--h100 {\n      height: 100vh;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
