import React from "react";

import { useSelector } from "react-redux";

import { getString } from "Components/Common/FormattedString";
import { SimplestModal } from "Components/Common/SimplestModal/SimplestModal";
import { DetailsBox } from "Components/Common/DetailsBox/DetailsBox";

export const ProgramPacketTemplateDetails = ({ itemId, toggle, visible }) => {
  const { programPacketTemplatesData } = useSelector(
    (rootState) => rootState.ProgramPacketTemplates,
  );
  const item = programPacketTemplatesData.find(
    (programPacketTemplate) => programPacketTemplate.id === itemId,
  );

  return (
    <SimplestModal
      visible={visible}
      setVisible={() => toggle(false)}
      id="programPacketTemplate-details-modal"
      title={getString("program_packet_template_details")}
      showFooter={false}
    >
      <DetailsBox item={item} key={item?.id} />
    </SimplestModal>
  );
};
