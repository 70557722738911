import React from "react";
import "./InternationalParticipantsTable.scss";

import { getString } from "Components/Common/FormattedString";
import { Progress } from "reactstrap";
import { InternationalParticipantEquation } from "Components/Common/InternationalParticipantEquation/InternationalParticipantEquation";

export const InternationalParticipantsTable = ({ data }) => {
  const columns = [
    {
      header: getString("packet"),
      render: (participant) => getString(participant.type),
    },
    {
      header: getString("participants"),
      render: (participant) => (
        <span
          className={`international-participants-table__type--${participant.type}`}
        >
          {participant.count}
        </span>
      ),
    },
    {
      header: getString("progress"),
      style: { width: "25%" },
      render: () => <></>,
    },
  ];

  return (
    <table className="international-participants-table table table-hover table-nowrap align-middle mb-0">
      <thead className="table-light">
        <tr className="text-muted">
          {columns.map((column, index) => (
            <th key={index} scope="col" style={column.style}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((packet, index) => (
          <React.Fragment key={`${packet.programPacketId}-${index}`}>
            <tr className="international-participants-table__row international-participants-table__row--group-header">
              <td>
                <div className="d-flex align-items-center gap-2">
                  <span className="fw-medium">{packet.programPacketName}</span>
                </div>
              </td>
              <td>
                <InternationalParticipantEquation summary={packet.summary} />
              </td>
              <td>
                <Progress
                  value={packet.summary.occupancyFraction}
                  color="danger"
                  className="animated-progress custom-progress progress-label"
                >
                  <div className="label">
                    {packet.summary.occupancyFraction}%
                  </div>
                </Progress>
              </td>
            </tr>
            {packet.summary.quantity.map((item, index) => (
              <tr
                className="international-participants-table__row"
                key={item?.id || index}
              >
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{column.render(item)}</td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};
