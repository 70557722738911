import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import "./PaymentMethodPreview.scss";
import { useState } from "react";
import { DetailsBox } from "../../DetailsBox";

export const PaymentMethodPreview = ({ paymentMethod }) => {
  const [open, setOpen] = useState();
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <Accordion
      open={open || "0"}
      toggle={toggle}
      className="payment-method-preview"
    >
      <AccordionItem>
        <AccordionHeader targetId="1">{paymentMethod.name}</AccordionHeader>
        <AccordionBody accordionId="1">
          <DetailsBox item={paymentMethod} key={paymentMethod?.id} />
        </AccordionBody>
      </AccordionItem>
    </Accordion>
  );
};
