import "./IpParticipantsAttachmentsList.scss";
import { SingleAttachment } from "./partials/SingleAttachment/SingleAttachment";

export const IpParticipantsAttachmentsList = ({ attachments, participant }) => {
  return (
    <div className="ip-participants-attachments-list">
      {attachments.map((a, i) => (
        <div key={i} className="attachment-item d-flex align-items-center">
          <span className="attachment-index">{i + 1}. </span>
          <span className="attachment-description">{a.description}</span>
          <SingleAttachment
            internationalParticipant={participant}
            attachment={a}
          />
        </div>
      ))}
    </div>
  );
};
