import { AngloTooltip } from "Components/Common/AngloTooltip/AngloTooltip";
import classNames from "classnames";
import { InstallmentPlanTooltipContent } from "./InstallmentPlanTooltipContent";

export const SingleInstallmentPlanDot = ({ installment, enabled, index }) => {
  return (
    <AngloTooltip
      text={<InstallmentPlanTooltipContent installment={installment} />}
      className="plan-installments-tooltip"
      enabled={enabled}
    >
      <div
        className={classNames([
          "plan-installments-view__dot",
          enabled && "plan-installments-view__dot--active",
        ])}
      >
        {index}
      </div>
    </AngloTooltip>
  );
};
