// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offcanvas-right__footer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  box-shadow: 0 -2px 3px rgba(56, 65, 74, 0.1);
  border: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color);
}
.offcanvas-right__footer button {
  padding: 8px 34px;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Entity/OffcanvasRight.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,4CAAA;EACA,4EAAA;AAAJ;AAEI;EACE,iBAAA;EACA,eAAA;AAAN","sourcesContent":[".offcanvas-right {\n  &__footer {\n    display: flex;\n    justify-content: space-between;\n    padding: 1rem 2rem;\n    box-shadow: 0 -2px 3px rgba(56, 65, 74, 0.1);\n    border: var(--vz-border-width) var(--vz-border-style) var(--vz-border-color);\n\n    button {\n      padding: 8px 34px;\n      font-size: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
