import React, { useState } from "react";

import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import { Card, CardBody } from "reactstrap";

import { toast_error, toast_success } from "helpers/toast_helper";

import { useDispatch } from "react-redux";
import { addProgramPacketTemplateAttachments } from "helpers/API/core-service/cs_backend_helper";
import { getProgramPacketTemplateListData } from "store/programPacketTemplates/action";
import { ProgramPacketTemplateAttachmentsList } from "./ProgramPacketTemplateAttachmentsList/ProgramPacketTemplateAttachmentsList";

const ProgramPacketTemplateAttachments = ({
  attachments,
  programPacketTemplate,
  toggle,
  visibility,
}) => {
  const dispatch = useDispatch();

  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const addAttachments = () => {
    const data = {
      attachments: selectedAttachments.map((attachment) => attachment.value),
    };

    addProgramPacketTemplateAttachments(programPacketTemplate.id, data)
      .then(() => {
        dispatch(getProgramPacketTemplateListData());
        toast_success(getString("attachments_added"));
        toggle();
      })
      .catch((error) => {
        toast_error(error.message);
      });
  };

  return (
    <OffcanvasRight
      isOpen={visibility}
      toggle={toggle}
      title={programPacketTemplate?.name + " " + getString("attachments_sm")}
      formId={"edit-programPacketTemplate"}
      buttonLabel={getString("save")}
      validationRule={selectedAttachments.every(
        (attachment) => attachment.value !== undefined,
      )}
      onSubmit={(e) => {
        e.preventDefault();
        addAttachments();
      }}
    >
      <Card>
        <CardBody className="card-body">
          <ProgramPacketTemplateAttachmentsList
            attachments={attachments}
            selectedAttachments={selectedAttachments}
            setSelectedAttachments={setSelectedAttachments}
            programPacketTemplate={programPacketTemplate}
          />
        </CardBody>
      </Card>
    </OffcanvasRight>
  );
};

export default ProgramPacketTemplateAttachments;
