export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA =
  "GET_PROGRAM_PACKET_TEMPLATE_LIST_DATA";
export const POST_PROGRAM_PACKET_TEMPLATE = "POST_PROGRAM_PACKET_TEMPLATE";
export const EDIT_PROGRAM_PACKET_TEMPLATE = "EDIT_PROGRAM_PACKET_TEMPLATE";
export const ARCHIVE_PROGRAM_PACKET_TEMPLATE =
  "ARCHIVE_PROGRAM_PACKET_TEMPLATE";
export const RESTORE_PROGRAM_PACKET_TEMPLATE =
  "RESTORE_PROGRAM_PACKET_TEMPLATE";
