import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import { useDispatch, useSelector } from "react-redux";
import { getUserListData } from "../../store/users/action";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";
import { Badges } from "../../Components/Common/Badges/Badges";
import { FormattedString, getString } from "Components/Common/FormattedString";
import {
  currentLocation,
  getItemUuid,
  getLocation,
  isValidUuid,
  parentLocation,
} from "helpers/utils";
import { useNavigate } from "react-router-dom";
import { UserDetails } from "./UserDetails";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import { format } from "date-fns";
import { useHasPermission } from "Components/Hooks/useHasPermission";

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);

  const { usersData, listInitialized } = useSelector(
    (rootState) => rootState.Users,
  );

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [userData, setUserData] = useState();
  const toggleEditModal = (user, direction = false) => {
    setUserData(user);
    setEditModalVisible(direction);
  };

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [itemId, setModalItemId] = useState(getItemUuid());
  const toggleDeleteModal = (id) => {
    id && setModalItemId(id);
    setDeleteModalVisible((p) => !p);
  };

  // details modal
  const [detailsModalVisible, setDetailsModalVisible] = useState(
    isValidUuid(getLocation(3)) || isValidUuid(getLocation(2)),
  );

  const toggleDetailsModal = (state, id) => {
    setDetailsModalVisible(state);
    state === false
      ? navigate(parentLocation())
      : navigate(`${currentLocation()}/${id}`);
    setModalItemId(id);
  };

  const hasLastLoginPermission = useHasPermission("LAST_LOGIN_READ");
  const hasEditManagerPermission = useHasPermission("ROLE_MANAGER_MANAGEMENT");
  const hasEditSuperAdminPermission = useHasPermission(
    "ROLE_SUPER_ADMIN_MANAGEMENT",
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: getString("name"),
          accessor: (cellProps) =>
            `${cellProps.firstName} ${cellProps.lastName}`,
          Cell: (cellProps) => (
            <span>
              {cellProps.row.original.firstName}{" "}
              {cellProps.row.original.lastName}
            </span>
          ),
        },
        {
          Header: getString("email"),
          accessor: "email",
          disableFilters: true,
          filterable: false,
        },
        {
          Header: getString("role"),
          accessor: (cellProps) => cellProps.roles.join(", "),
          Cell: (cellProps) => (
            <Badges
              feed={cellProps.row.original.roles
                .map((role) => role.substring(5))
                .filter((role) => role !== "CRM")}
              variant="dark"
            />
          ),
        },
        {
          Header: getString("business_unit"),
          disableFilters: true,
          filterable: false,
          accessor: (cellProps) =>
            cellProps.businessUnits.map((unit) => unit.name).join(", "),
          Cell: (cellProps) => (
            <Badges
              feed={cellProps.row.original.businessUnits.map(
                (unit) => unit.name,
              )}
            />
          ),
        },
        hasLastLoginPermission && {
          Header: getString("last_login"),
          accessor: "lastLogin",
          Cell: (cellProps) => {
            const lastLogin = cellProps.row.original?.lastLogin;
            return (
              <span>
                {lastLogin
                  ? format(new Date(lastLogin), "dd.MM.yyyy HH:mm:ss")
                  : "N/A"}
              </span>
            );
          },
        },
        {
          id: "Actions",
          Header: () => (
            <div
              style={{
                textAlign: "right",
              }}
            >
              <FormattedString id="actions" />
            </div>
          ),
          disableSortBy: true,
          accessor: (cellProps) => (
            <ActionButtons
              onEdit={() => toggleEditModal(cellProps, true)}
              onDelete={() => toggleDeleteModal(cellProps.id)}
              onShowDetails={() => toggleDetailsModal(true, cellProps.id)}
              itemId={cellProps.id}
              isEditDisabled={
                userId === cellProps.id ||
                (cellProps.roles.includes("ROLE_MANAGER") &&
                  !hasEditManagerPermission) ||
                (cellProps.roles.includes("ROLE_SUPER_ADMIN") &&
                  !hasEditSuperAdminPermission)
              }
              isDeleteDisabled={
                userId === cellProps.id ||
                (cellProps.roles.includes("ROLE_MANAGER") &&
                  !hasEditManagerPermission) ||
                (cellProps.roles.includes("ROLE_SUPER_ADMIN") &&
                  !hasEditSuperAdminPermission)
              }
              permissionPrefix="USER"
            />
          ),
        },
      ].filter(Boolean),
    [userId, hasLastLoginPermission],
  );

  useEffect(() => {
    dispatch(getUserListData());
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      const user = JSON.parse(storedUserData);
      setUserId(user.id);
    }
  }, []);

  return listInitialized ? (
    <>
      <TableContainer
        columns={columns || []}
        data={usersData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("users_search_for")}
      />
      {editModalVisible && (
        <EditUser
          user={userData}
          visibility={editModalVisible}
          toggle={toggleEditModal}
        />
      )}
      {deleteModalVisible && (
        <DeleteUser
          itemId={itemId}
          visibility={deleteModalVisible}
          toggle={toggleDeleteModal}
        />
      )}
      {detailsModalVisible && (
        <UserDetails
          itemId={itemId}
          toggle={toggleDetailsModal}
          visible={detailsModalVisible}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};
export { UsersList };
