import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProgramListData } from "../../store/programs/action";
import TableContainer from "../../Components/Common/TableContainerReactTable";
import ActionButtons from "../../Components/Entity/ActionButtons/ActionButtons";
import { FormattedString, getString } from "Components/Common/FormattedString";
import { currentLocation, getItemUuid, parentLocation } from "helpers/utils";
import { PreloaderWrap } from "Components/Common/Preloader/Preloader";
import FormattedDate from "Components/Common/FormattedDate/FormattedDate";
import { Badge, GenericBadge } from "Components/Common/Badges/Badges";
import DeleteProgram from "./DeleteProgram";
import DuplicateProgram from "./DuplicateProgram";

const ProgramsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { programsData, listInitialized } = useSelector(
    (rootState) => rootState.Programs,
  );

  useEffect(() => {
    dispatch(getProgramListData());
  }, [dispatch]);

  const [itemId, setModalItemId] = useState(getItemUuid());
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);

  const toggleDeleteModal = (id) => {
    setModalItemId(id);
    setDeleteModalVisible((p) => !p);
  };

  const toggleDuplicateModal = (id) => {
    setModalItemId(id);
    setDuplicateModalVisible((p) => !p);
  };

  const columns = useMemo(
    () => [
      {
        Header: getString("customId"),
        accessor: "customId",
        Cell: ({ row, value }) => (
          <Link to={`/program/${row.original.id}`}>#{value}</Link>
        ),
      },
      {
        Header: getString("name"),
        accessor: "name",
        Cell: (cellProps) => (
          <Link to={`/program/${cellProps.row.original.id}`}>
            {cellProps.value} #{cellProps.row.original.season}
          </Link>
        ),
      },
      {
        Header: getString("status"),
        accessor: "status",
        Cell: ({ value }) => <Badge>{value}</Badge>,
      },
      {
        Header: getString("startDate"),
        accessor: "startDate",
        Cell: ({ value }) => (
          <GenericBadge>
            <FormattedDate dateString={value} />
          </GenericBadge>
        ),
      },
      {
        Header: getString("endDate"),
        accessor: "endDate",
        Cell: ({ value }) => (
          <GenericBadge>
            <FormattedDate dateString={value} />
          </GenericBadge>
        ),
      },
      {
        Header: getString("hotel"),
        accessor: "hotel.name",
        Cell: ({ value }) => <span>{value ? value : "N/A"}</span>,
      },
      {
        id: "Actions",
        Header: () => (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <FormattedString id="actions" />
          </div>
        ),
        disableSortBy: true,
        accessor: (cellProps) => (
          <ActionButtons
            onDelete={() => toggleDeleteModal(cellProps.id)}
            customShowDetailsLink={`/program/${cellProps.id}`}
            onDuplicate={() => toggleDuplicateModal(cellProps.id)}
            itemId={cellProps.id}
            permissionPrefix="PROGRAM"
          />
        ),
      },
    ],
    [],
  );

  return listInitialized ? (
    <>
      <TableContainer
        columns={columns || []}
        data={programsData || []}
        isPagination={true}
        isGlobalFilter={true}
        isBordered={false}
        customPageSize={10}
        className="custom-header-css table align-middle table-nowrap"
        tableClassName="table-centered align-middle table-nowrap mb-0"
        theadClassName="text-muted table-light"
        SearchPlaceholder={getString("programs_search_for")}
      />
      {deleteModalVisible && (
        <DeleteProgram
          itemId={itemId}
          visibility={deleteModalVisible}
          toggle={toggleDeleteModal}
        />
      )}

      {duplicateModalVisible && (
        <DuplicateProgram
          itemId={itemId}
          visibility={duplicateModalVisible}
          toggle={toggleDuplicateModal}
          onSuccess={(r) => {
            dispatch(getProgramListData());
            if (r.programId) {
              navigate(`/program/${r.programId}/edit`);
            }
          }}
        />
      )}
    </>
  ) : (
    <PreloaderWrap />
  );
};

export { ProgramsList };
