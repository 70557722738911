import { useEffect, useMemo, useRef, useState } from "react";
import { getLoggedInUser } from "../../helpers/API/api_helper";
import { useSelector } from "react-redux";

const useProfile = () => {
  const userProfileSession = getLoggedInUser();
  var token = userProfileSession && userProfileSession["token"];
  const [loading, setLoading] = useState(!userProfileSession);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null,
  );

  useEffect(() => {
    const userProfileSession = getLoggedInUser();
    var token = userProfileSession && userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(!token);
  }, []);

  return { userProfile, loading, token };
};

const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

const useMemoizedSelector = (selectorFn) => {
  const memoizedSelector = useMemo(selectorFn, []);
  return useSelector(memoizedSelector);
};

const useLocalStorage = (key, initialValue) => {
  const readValue = () => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };
  const [storedValue, setStoredValue] = useState(readValue);
  const setValue = (value) => {
    if (typeof window == "undefined") {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`,
      );
    }
    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      setStoredValue(newValue);
      window.localStorage.setItem(key, JSON.stringify(newValue));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };
  return [storedValue, setValue];
};

export { useProfile, useIsMount, useMemoizedSelector, useLocalStorage };
