// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link-button {
  cursor: pointer;
  color: rgb(56, 151, 221);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.link-button--disabled {
  cursor: not-allowed;
  color: rgb(150, 150, 150);
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/LinkButton/LinkButton.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,wBAAA;EACA,qBAAA;EACA,aAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;EACA,qBAAA;AACJ","sourcesContent":[".link-button {\n  cursor: pointer;\n  color: rgb(56, 151, 221);\n  text-decoration: none;\n  display: flex;\n  align-items: center;\n\n  &--disabled {\n    cursor: not-allowed;\n    color: rgb(150, 150, 150);\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
