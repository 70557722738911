import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  GET_IP_PARTICIPANT_LIST_DATA,
  POST_IP_PARTICIPANT,
  EDIT_IP_PARTICIPANT,
  DELETE_IP_PARTICIPANT,
  GET_BUSINESS_UNIT_LIST_DATA,
  BLOCK_IP_PARTICIPANT,
  POST_IP_ATTACHMENT,
} from "./actionType";

export const INIT_STATE = {
  businessUnitsData: [],
  ipParticipantsData: [],
  error: null,
  loading: false,
  formValidationErrors: {},
  isIpParticipantAdded: false,
  isIpParticipantEdited: false,
  isIpParticipantDeleted: false,
  isIpAttachmentPosted: false,
  listInitialized: false,
};

const IpParticipants = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_IP_PARTICIPANT_LIST_DATA:
    case POST_IP_PARTICIPANT:
    case DELETE_IP_PARTICIPANT:
    case BLOCK_IP_PARTICIPANT:
    case POST_IP_ATTACHMENT:
      return {
        ...state,
        loading: true,
      };
    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_BUSINESS_UNIT_LIST_DATA:
          return {
            ...state,
            businessUnitsData: action.payload.data,
            loading: false,
          };
        case GET_IP_PARTICIPANT_LIST_DATA:
          return {
            ...state,
            ipParticipantsData: action.payload.data,
            loading: false,
            isIpParticipantAdded: false,
            isIpParticipantEdited: false,
            isIpParticipantDeleted: false,
            isIpAttachmentPosted: false,
            listInitialized: true,
          };
        case POST_IP_PARTICIPANT:
          return {
            ...state,
            loading: false,
            isIpParticipantAdded: true,
          };
        case EDIT_IP_PARTICIPANT:
          return {
            ...state,
            loading: false,
            isIpParticipantEdited: true,
          };
        case DELETE_IP_PARTICIPANT:
          return {
            ...state,
            loading: false,
            isIpParticipantDeleted: true,
          };
        case BLOCK_IP_PARTICIPANT:
          return {
            ...state,
            loading: false,
          };
        case POST_IP_ATTACHMENT:
          return {
            ...state,
            loading: false,
            isIpAttachmentPosted: true,
          };
        default:
          return state;
      }
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case GET_BUSINESS_UNIT_LIST_DATA:
        case GET_IP_PARTICIPANT_LIST_DATA:
          return {
            ...state,
            errorMsg: action.payload.data,
            error: action.payload.error,
            loading: false,
          };
        case POST_IP_PARTICIPANT:
        case EDIT_IP_PARTICIPANT:
        case POST_IP_ATTACHMENT:
          return {
            ...state,
            error: action.payload.error,
            formValidationErrors: action.payload.error.data?.fields ?? null,
            loading: false,
          };
        case DELETE_IP_PARTICIPANT:
        case BLOCK_IP_PARTICIPANT:
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        default:
          return state;
      }
    default:
      return state;
  }
};

export default IpParticipants;
