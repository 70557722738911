import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { UsersList } from "./ListUsers";
import AddUser from "./AddUser";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const Users = () => {
  document.title = getString("users_page_title");
  const [userAddVisible, setUserAddVisible] = useState(false);
  const toggleUserAdd = () => {
    setUserAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("users_card_title")}
                  actionBtnLabel="user"
                  actionBtn={toggleUserAdd}
                  actionBtnPermission="USER_CREATE"
                >
                  <UsersList />
                </EntityCardList>
              </div>
            </Col>

            {userAddVisible && (
              <AddUser visibility={userAddVisible} toggle={toggleUserAdd} />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
