import { FormattedString } from "Components/Common/FormattedString";
import { ApplicationDetailsContext } from "pages/Applications/ApplicationDetails/ApplicationDetails";
import { useContext } from "react";

export const BillingData = () => {
  const {
    applicationData: { payerDetails },
  } = useContext(ApplicationDetailsContext);

  if (!payerDetails) {
    return (
      <div className="billing-data">
        <FormattedString id="billing_data_not_available" />
      </div>
    );
  }

  return (
    <div className="billing-data">
      <ul className="list-unstyled vstack gap-2 fs-13 mb-0">
        {payerDetails.companyName ? (
          <>
            <li className="fw-medium fs-14">
              {payerDetails.companyName || "N/A"}
            </li>
            <li>{payerDetails.taxNumber || "N/A"}</li>
          </>
        ) : (
          <>
            <li className="fw-medium fs-14">
              {payerDetails.firstName || "N/A"} {payerDetails.lastName || "N/A"}
            </li>
          </>
        )}
        <li>{payerDetails.address || "N/A"}</li>
        <li>
          {payerDetails.zipCode || "N/A"} - {payerDetails.city || "N/A"}
        </li>
      </ul>
    </div>
  );
};
