// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.application-activity-tab .application-activities {
  position: relative;
}
.application-activity-tab .application-activities__placeholder {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.application-activity-tab .application-activities::before {
  content: "";
  border-left: 2px dashed var(--vz-border-color);
  position: absolute;
  height: 100%;
  left: 15.45px;
}
.application-activity-tab .activity-title {
  font-weight: 500;
  display: flex;
  gap: 4px;
  transition: all 0.2s ease-in-out;
}
.application-activity-tab .activity-title--email {
  cursor: pointer;
}
.application-activity-tab .activity-title--email:hover {
  color: rgb(56, 151, 221);
}
.application-activity-tab__view-email {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 11px;
  margin-bottom: -2px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Applications/ApplicationDetails/partials/Tabs/ApplicationActivityTab/ApplicationActivityTab.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ;AAEI;EACE,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAN;AAGI;EACE,WAAA;EACA,8CAAA;EACA,kBAAA;EACA,YAAA;EACA,aAAA;AADN;AAKE;EACE,gBAAA;EACA,aAAA;EACA,QAAA;EACA,gCAAA;AAHJ;AAKI;EACE,eAAA;AAHN;AAIM;EACE,wBAAA;AAFR;AAOE;EACE,aAAA;EACA,QAAA;EACA,mBAAA;EACA,eAAA;EACA,mBAAA;AALJ","sourcesContent":[".application-activity-tab {\n  .application-activities {\n    position: relative;\n\n    &__placeholder {\n      min-height: 400px;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n\n    &::before {\n      content: \"\";\n      border-left: 2px dashed var(--vz-border-color);\n      position: absolute;\n      height: 100%;\n      left: 15.45px;\n    }\n  }\n\n  .activity-title {\n    font-weight: 500;\n    display: flex;\n    gap: 4px;\n    transition: all 0.2s ease-in-out;\n\n    &--email {\n      cursor: pointer;\n      &:hover {\n        color: rgb(56 151 221);\n      }\n    }\n  }\n\n  &__view-email {\n    display: flex;\n    gap: 4px;\n    align-items: center;\n    font-size: 11px;\n    margin-bottom: -2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
