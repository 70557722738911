import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { IpParticipantsList } from "./ListIpParticipants";
import AddIpParticipant from "./AddIpParticipant";
import EntityCardList from "../../Components/Entity/EntityCardList";
import { getString } from "Components/Common/FormattedString";

const IpParticipants = () => {
  document.title = getString("ip_participants_page_title");
  const [ipParticipantAddVisible, setIpParticipantAddVisible] = useState(false);
  const toggleIpParticipantAdd = () => {
    setIpParticipantAddVisible((p) => !p);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <EntityCardList
                  title={getString("ip_participants_card_title")}
                  actionBtnLabel={getString("ip_participant")}
                  actionBtn={toggleIpParticipantAdd}
                  actionBtnPermission="IP_PARTICIPANT_CREATE"
                >
                  <IpParticipantsList />
                </EntityCardList>
              </div>
            </Col>

            {ipParticipantAddVisible && (
              <AddIpParticipant
                visibility={ipParticipantAddVisible}
                toggle={toggleIpParticipantAdd}
              />
            )}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default IpParticipants;
