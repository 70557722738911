import React from "react";
import "./PlanInstallmentsView.scss";

import { SingleInstallmentPlanDot } from "./components/SingleInstallmentPlanDot";

export const PlanInstallmentsView = ({ installmentCount, installments }) => {
  return (
    <div className="plan-installments-view">
      {Array.from({ length: installmentCount }, (_, i) => (
        <SingleInstallmentPlanDot
          key={i}
          index={i + 1}
          installment={installments[i]}
          installmentCount={installmentCount}
          enabled={installmentCount >= i + 1}
        />
      ))}
    </div>
  );
};
