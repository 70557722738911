import { isObjectEmpty } from "helpers/utils";
import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  FETCH_PROFILE,
  SET_BUSINESS_UNIT,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  user: {},
  activeBusinessUnit:
    JSON.parse(localStorage.getItem("activeBusinessUnit")) || {},
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload.status,
        user: action.payload.data,
        activeBusinessUnit: isObjectEmpty(state.activeBusinessUnit)
          ? action.payload.data.businessUnits[0]
          : state.activeBusinessUnit,
      };
      localStorage.setItem(
        "activeBusinessUnit",
        JSON.stringify(state.activeBusinessUnit),
      );
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case SET_BUSINESS_UNIT:
      state = {
        ...state,
        activeBusinessUnit: action.payload,
      };
      localStorage.setItem(
        "activeBusinessUnit",
        JSON.stringify(action.payload),
      );
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
