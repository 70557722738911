// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.installments-preview .accordion-button {
  padding: 10px;
}
.installments-preview tr td:nth-child(1) {
  border: none;
  background: var(--vz-table-bg);
  text-align: left;
  padding: 10px;
}
.installments-preview tr td:nth-child(2) {
  width: unset;
}
.installments-preview tr td {
  padding: 10px;
}
.installments-preview table > tbody > tr:last-child {
  border: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Components/Common/DetailsBox/fields/InstallmentsPreview/InstallmentsPreview.scss"],"names":[],"mappings":"AACE;EACE,aAAA;AAAJ;AAGE;EACE,YAAA;EACA,8BAAA;EACA,gBAAA;EACA,aAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,mBAAA;AAJJ","sourcesContent":[".installments-preview {\n  .accordion-button {\n    padding: 10px;\n  }\n\n  tr td:nth-child(1) {\n    border: none;\n    background: var(--vz-table-bg);\n    text-align: left;\n    padding: 10px;\n  }\n\n  tr td:nth-child(2) {\n    width: unset;\n  }\n\n  tr td {\n    padding: 10px;\n  }\n\n  table > tbody > tr:last-child {\n    border: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
