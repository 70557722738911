import { getString } from "Components/Common/FormattedString";
import FormNumberField from "../FormNumberField";
import "./SingleInstallment.scss";
import {
  handleInputChange,
  handleNumberInputChange,
  handleSelectChange,
} from "helpers/validation_helper";
import FormTextField from "../FormTextField";
import { FormSelect } from "../FormSelect";
import { installmentTypes } from "models/installmentPlans";
import { useSelector } from "react-redux";

export const SingleInstallment = ({ index, validation }) => {
  const PointOfSell = useSelector((rootState) => rootState.PointOfSell);

  const pointOfSellOptions = PointOfSell.pointOfSellData.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  if (index === 0) {
    validation.values.installments[index].dueDays = null;
  }

  const validateDueDays = (value, index) => {
    if (index > 1) {
      const previousDueDays =
        validation.values.installments[index - 1]?.dueDays;
      if (value >= previousDueDays) {
        return getString("due_days_order_message");
      }
    }
    return undefined;
  };

  return (
    <div key={index} className="single-installment mb-3">
      <div className="single-installment__label">
        {getString("installment")} {index + 1}
      </div>
      {index !== 0 && (
        <FormNumberField
          label={getString("dueDays")}
          name={`installments[${index}].dueDays`}
          value={validation.values.installments[index]?.dueDays}
          onChange={(e) =>
            handleNumberInputChange(
              validation,
              e,
              `installments[${index}].dueDays`,
            )
          }
          error={
            validation.errors.installments?.[index]?.dueDays ||
            validateDueDays(
              validation.values.installments[index]?.dueDays,
              index,
            )
          }
          touched={validation.touched.installments?.[index]?.dueDays}
        />
      )}
      <FormTextField
        label={getString("currency")}
        name={`installments[${index}].currency`}
        value={validation.values.installments[index]?.currency}
        onChange={(e) =>
          handleInputChange(validation, e, `installments[${index}].currency`)
        }
        error={validation.errors.installments?.[index]?.currency}
        touched={validation.touched.installments?.[index]?.currency}
      />
      <FormSelect
        label={getString("type")}
        name={`installments[${index}].type`}
        value={validation.values.installments[index]?.type}
        onChange={(selectedOption) =>
          handleSelectChange(
            validation,
            selectedOption,
            `installments[${index}].type`,
          )
        }
        isMulti={false}
        options={installmentTypes}
        error={validation.errors.installments?.[index]?.type}
      />
      <FormSelect
        label={getString("pointOfSell")}
        name={`installments[${index}].pointOfSell`}
        value={validation.values.installments[index]?.pointOfSell}
        onChange={(selectedOption) =>
          handleSelectChange(
            validation,
            selectedOption,
            `installments[${index}].pointOfSell`,
          )
        }
        isMulti={false}
        options={pointOfSellOptions}
        error={validation.errors.installments?.[index]?.pointOfSell}
      />
    </div>
  );
};
