import React, { useContext, useState } from "react";
import "./ProgramInstallmentsView.scss";

import { ProgramDetailsContext } from "pages/Programs/ProgramDetails/ProgramDetails";
import { NewValuePlaceholder } from "Components/Common/NewValuePlaceholder/NewValuePlaceholder";
import { ProgramInstallments } from "../ProgramInstallments/ProgramInstallments";
import { AddInstallmentPlanCanvas } from "../../modals/AddInstallmentPlanCanvas";

export const ProgramInstallmentsView = ({ programPacket }) => {
  const { programData } = useContext(ProgramDetailsContext);
  const [
    defineInstallmentPricesModalVisible,
    setDefineInstallmentPricesModalVisible,
  ] = useState(false);

  return (
    <div className="program-installments-view">
      {programData.installmentPlans.length > 0 ? (
        <ProgramInstallments
          programPacket={programPacket}
          installmentPlans={programData.installmentPlans}
          programPrices={programData.programPrices}
        />
      ) : (
        <NewValuePlaceholder
          addStringId="define_installment_prices"
          onClick={() => setDefineInstallmentPricesModalVisible(true)}
        />
      )}
      {defineInstallmentPricesModalVisible && (
        <AddInstallmentPlanCanvas
          visible={defineInstallmentPricesModalVisible}
          setVisible={setDefineInstallmentPricesModalVisible}
        />
      )}
    </div>
  );
};
