import React, { useCallback } from "react";
import { Modal } from "reactstrap";
import { useDispatch } from "react-redux";
import {
  archiveProgramPacketTemplate,
  getProgramPacketTemplateListData,
} from "../../store/programPacketTemplates/action";
import ConfirmArchiveBody from "../../Components/Entity/ConfirmArchiveBody";
import { getString } from "Components/Common/FormattedString";

const ArchiveProgramPacketTemplate = ({ itemId, visibility, toggle }) => {
  const dispatch = useDispatch();
  const archiveProgramPacketTemplateEntry = useCallback(() => {
    dispatch(
      archiveProgramPacketTemplate(itemId, () => {
        dispatch(getProgramPacketTemplateListData());
        toggle(false);
      }),
    );
  }, [dispatch, itemId]);

  return (
    <Modal
      isOpen={visibility}
      toggle={() => {
        toggle();
      }}
      backdrop={"static"}
      id="staticBackdrop"
      centered
    >
      <ConfirmArchiveBody
        entityName={getString("program_packet_template_sm")}
        toggle={toggle}
        archiveAction={archiveProgramPacketTemplateEntry}
      />
    </Modal>
  );
};

export default ArchiveProgramPacketTemplate;
