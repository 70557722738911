import classNames from "classnames";
import "./Badges.scss";
import { useTranslation } from "react-i18next";

export const GenericBadge = ({ variant, size, children }) => {
  return (
    <span
      className={classNames([
        "badge",
        variant && `badge--${variant}`,
        size && `badge--${size}`,
      ])}
    >
      {children}
    </span>
  );
};

export const Badge = ({ variant, size, children }) => {
  const { t } = useTranslation();

  return (
    <span
      className={classNames([
        "badge",
        variant && `badge--${variant}`,
        size && `badge--${size}`,
      ])}
    >
      {t(children)}
    </span>
  );
};

export const Badges = ({ feed, variant }) => {
  return (
    <div className="badges">
      {feed.map((item, index) => {
        return (
          <Badge key={index} variant={variant}>
            {item}
          </Badge>
        );
      })}
    </div>
  );
};
