import React, { useEffect, useState } from "react";
import { Card, CardBody, Form } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { editProgram, getProgramListData } from "../../store/programs/action";

import {
  handleInputChange,
  setValidationErrors,
} from "../../helpers/validation_helper";
import FormTextField from "../../Components/Entity/FormTextField";
import OffcanvasRight from "../../Components/Entity/OffcanvasRight";
import { getString } from "Components/Common/FormattedString";
import * as Yup from "yup";
import FormNumberField from "Components/Entity/FormNumberField";
import FormDateField from "Components/Entity/FormDateField";
import { externalizeDate, internalizeDate } from "helpers/utils";

const EditProgram = ({ visibility, toggle, editedProgram, onSuccess }) => {
  const dispatch = useDispatch();
  const { loading, formValidationErrors } = useSelector(
    (rootState) => rootState.Programs,
  );

  // Form validation (local)
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,

    initialValues: {
      name: editedProgram?.name || "",
      season: editedProgram?.season || "",
      endDate: internalizeDate(editedProgram?.endDate) || "",
      startDate: internalizeDate(editedProgram?.startDate) || "",
      healthAndSafetyCapacity:
        editedProgram?.healthAndSafetyCoordinatorCapacity || "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(5, getString("nameMinLength", { min: 5 }))
        .required(getString("nameRequired")),
      season: Yup.string()
        .min(2, getString("seasonMinLength", { min: 2 }))
        .required(getString("seasonRequired")),
      endDate: Yup.date().required(getString("endDateRequired")).nullable(),
      startDate: Yup.date().required(getString("startDateRequired")).nullable(),
      healthAndSafetyCapacity: Yup.number()
        .required(getString("healthAndSafetyCapacityRequired"))
        .positive(getString("healthAndSafetyCapacityPositive"))
        .integer(getString("healthAndSafetyCapacityInteger")),
    }),
    onSubmit: (values) => {
      const updatedValues = {
        ...values,
      };
      updatedValues.startDate = externalizeDate(values.startDate);
      updatedValues.endDate = externalizeDate(values.endDate);

      dispatch(
        editProgram(editedProgram.id, updatedValues, () => {
          dispatch(getProgramListData());
          onSuccess && onSuccess();
          toggle(false);
        }),
      );
    },
  });

  // Form validation (api)
  const [prevValidationErrors, setPrevValidationErrors] = useState(null);
  useEffect(() => {
    if (formValidationErrors == null) {
      return;
    }
    if (prevValidationErrors !== null) {
      if (prevValidationErrors !== formValidationErrors) {
        setValidationErrors(validation, formValidationErrors);
      }
    }
    setPrevValidationErrors(formValidationErrors);
  }, [formValidationErrors, prevValidationErrors]);

  return (
    <React.Fragment>
      <OffcanvasRight
        isOpen={visibility}
        toggle={toggle}
        title={getString("programs_edit_program")}
        formId={"edit-program"}
        validationRule={validation.isValid}
        loading={loading}
        buttonLabel={getString("save")}
      >
        <Form
          id="edit-program"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Card>
            <CardBody className="card-body">
              <FormTextField
                label={getString("name")}
                name="name"
                value={validation.values.name}
                onChange={(e) => handleInputChange(validation, e, "name")}
                error={validation.errors.name}
                touched={validation.touched.name}
              />
              <FormTextField
                label={getString("season")}
                name="season"
                value={validation.values.season}
                onChange={(e) => handleInputChange(validation, e, "season")}
                error={validation.errors.season}
                touched={validation.touched.season}
              />
              <FormDateField
                label={getString("startDate")}
                name="startDate"
                value={validation.values.startDate}
                onChange={(e) => handleInputChange(validation, e, "startDate")}
                error={validation.errors.startDate}
                touched={validation.touched.startDate}
              />
              <FormDateField
                label={getString("endDate")}
                name="endDate"
                value={validation.values.endDate}
                onChange={(e) => handleInputChange(validation, e, "endDate")}
                error={validation.errors.endDate}
                touched={validation.touched.endDate}
              />
              <FormNumberField
                label={getString("healthAndSafetyCapacity")}
                name="healthAndSafetyCapacity"
                value={validation.values.healthAndSafetyCapacity}
                onChange={(e) =>
                  handleInputChange(validation, e, "healthAndSafetyCapacity")
                }
                error={validation.errors.healthAndSafetyCapacity}
                touched={validation.touched.healthAndSafetyCapacity}
              />
            </CardBody>
          </Card>
        </Form>
      </OffcanvasRight>
    </React.Fragment>
  );
};

export default EditProgram;
